import React from "react";
import { Card, Col, Container, Row } from "reactstrap";
import chatIcon from "../../../Assets/images/chatIcon.png";
import videoIcon from "../../../Assets/images/videoIcon.png";

const LiveForm0 = ({ rest, payload, error, isError, setIsError }) => {
  return (
    <Container className="assignment-selection-container">
      <h1 className="step-header text-center pb-5 mt-4">
        Types of 1-on-1 you need.
      </h1>
      <Row className="row-gap-5 gap-4 justify-content-evenly">
        {rest?.categories?.map((e) => {
          return (
            <>
              <Col md={3} xs={10}>
                <Card
                  className={`fs-3 ${payload.subCategory === e._id ? "border border-primary" : ""
                    }`}
                  onClick={() => {
                    rest.setPayload({
                      ...payload,
                      subCategory: e._id,
                      categoryId: e.categoryId,
                    });
                    setIsError(false);
                  }}
                >
                  <span className="assignment-selection-icon">
                    <img
                      src={
                        e._id === "65a17a11740c57094df25ee7"
                          ? chatIcon
                          : videoIcon
                      }
                      alt=""
                    />
                  </span>
                  {e.name}
                </Card>
              </Col>
            </>
          );
        })}
      </Row>
      {isError && error?.subCategory && (
        <div className="text-danger text-center mt-3">{error.subCategory}</div>
      )}
    </Container>
  );
};

export default LiveForm0;
