import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Form, FormFeedback, FormGroup, Input, Label } from "reactstrap";
import "./BillingAddress.scss";
import LogoHeader from "./LogoHeader";
import { loadStripe } from "@stripe/stripe-js";
import Button from "../Buttons/Button";
import { createPaymentSession } from "../../services/paymentSession";
import axios from "axios";
import { getAllCountries } from "../../services/getAllCountries";

const BillingAddress = () => {
  const navigate = useNavigate();
  const { paymentMethod, questionId } = useParams();
  const [billingData, setBillingData] = useState({
    name: "",
    line1: "",
    postal_code: "",
    city: "",
    state: "",
    country: "",
  });
  const [validations, setValidations] = useState({
    name: true,
    line1: true,
    postal_code: true,
    city: true,
    state: true,
    country: true,
  });

  const [countries, setCountries] = useState([]);

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const res = await getAllCountries();
        setCountries(res.data);
      } catch (error) {
        console.error('Error fetching countries:', error);
      }
    };
    fetchCountries();
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setBillingData({ ...billingData, [name]: value });
  };

  const handleSession = async () => {
    const { name, line1, postal_code, city, state, country } =
      billingData;

    // Validate each field
    const validName = name.trim() !== "";
    const validLine1 = line1.trim() !== "";
    const validPostalCode = postal_code.trim() !== "";
    const validCity = city.trim() !== "";
    const validState = state.trim() !== "";
    const validCountry = country.trim() !== "";

    setValidations({
      name: validName,
      line1: validLine1,
      postal_code: validPostalCode,
      city: validCity,
      state: validState,
      country: validCountry,
    });

    if (
      !(
        validName &&
        validLine1 &&
        validPostalCode &&
        validCity &&
        validState &&
        validCountry
      )
    ) {
      return;
    }

    // Proceed with API call if all fields are valid
    const apiEndPoint = process.env.REACT_APP_API;
    const type = localStorage.getItem("serviceType");
    const token = localStorage.getItem("token");

    try {
      const response = await axios.post(
        `${apiEndPoint}student/payment/getSessionData`,
        {
          name,
          line1,
          postalCode: postal_code,
          city,
          state,
          country,
          questionId,
          paymentSection: type,
          paymentMethod
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = response?.data;
      if (data.isSuccess) {
        if (data.paymentMethod === "Razorpay") {
          navigate("/razorpay-checkout", {
            state: {
              clientSecret: data.clientSecret,
            },
          });
        } else {
          navigate("/checkout", {
            state: {
              clientSecret: data.clientSecret,
            },
          });
        }
      }
    } catch (error) {
      console.error("Error occurred while fetching session data:", error);
      // Handle error as per your application's requirements
    }
  };

  return (
    <div className="billingWrapper">
      <LogoHeader />
      <div className="billingCard">
        <div className="title">Billing Address</div>
        <Form>
          <FormGroup>
            <Label for="name" className="subtitle">
              Name
            </Label>
            <Input
              id="name"
              name="name"
              type="text"
              onChange={handleChange}
              invalid={!validations.name}
            />
            {!validations.name && <FormFeedback>Name is required</FormFeedback>}
          </FormGroup>
          <FormGroup>
            <Label for="address" className="subtitle">
              Address
            </Label>
            <Input
              id="address"
              name="line1"
              type="textarea"
              onChange={handleChange}
              invalid={!validations.line1}
            />
            {!validations.line1 && (
              <FormFeedback>Address is required</FormFeedback>
            )}
          </FormGroup>
          <FormGroup>
            <Label for="country" className="subtitle">
              Country
            </Label>
            <Input
              id="country"
              name="country"
              type="select"
              onChange={handleChange}
              invalid={!validations.country}
            >
              <option disabled selected value="">-- Please select country --</option>
              {countries.map(country => (
                <option key={country.countryCode} value={country._id}>
                  {country.countryName}
                </option>
              ))}
            </Input>
            {!validations.country && (
              <FormFeedback>Country is required</FormFeedback>
            )}
          </FormGroup>
          <FormGroup>
            <Label for="city" className="subtitle">
              City
            </Label>
            <Input
              id="city"
              name="city"
              type="text"
              onChange={handleChange}
              invalid={!validations.city}
            />
            {!validations.city && <FormFeedback>City is required</FormFeedback>}
          </FormGroup>
          <FormGroup>
            <Label for="state" className="subtitle">
              State
            </Label>
            <Input
              id="state"
              name="state"
              type="text"
              onChange={handleChange}
              invalid={!validations.state}
            />
            {!validations.state && (
              <FormFeedback>State is required</FormFeedback>
            )}
          </FormGroup>
          <FormGroup>
            <Label for="postal_code" className="subtitle">
              Postal Code
            </Label>
            <Input
              id="postal_code"
              name="postal_code"
              type="text"
              onChange={handleChange}
              invalid={!validations.postal_code}
            />
            {!validations.postal_code && (
              <FormFeedback>Postal Code is required</FormFeedback>
            )}
          </FormGroup>
        </Form>
      </div>
      <div>
        <Button
          text="Next"
          varient="linear-gradient-primary"
          onClick={handleSession}
        />
      </div>
    </div>
  );
};

export default BillingAddress;
