import React, { useState } from "react";
import MainBanner from "../../../Components/MainBanner";
import AskSection from "../../../Components/AskSection";
import HelpSteps from "../../../Components/HelpSteps";
import StudentStories from "../../../Components/StudentStories";
import HelpService from "../../../Components/HelpService";
import AdvantagesOfQuestions from "../../../Components/AdvantagesOfQuestions";
import Footer from "../../../Components/Footer";
import BonusSection from "../../../Components/BonusSection";
import MatchTheFollowingImg from "../../../Assets/images/background/match-the-following.svg";
import CommonForm from "../../HomePage/Form/CommonForm";
import ScrollTop from "../../../Components/Buttons/ScrollTop";
import Navbar from "../../../Components/Navbar";
import Nav from "../../../Components/Nav";
import GetPopup from "../../Staticpages/GetPopup";
import Milestonerewards from "../../../Components/Milestonerewards";
import Universities from "../../../Components/Universities";

const MatchTheFollowing = () => {
  const subCatagoryID = "65565744b9cbef8acade1d70";
  const categoryId = "65524e5e3c005ef3b51907c6";
  const [isModelOpen, setIsModelOpen] = useState(false);

  const toggle = () => {
    setIsModelOpen(!isModelOpen);
  };

  return (
    <div>
      <Nav />
      <Navbar />
      <MainBanner id={subCatagoryID} setIsModelOpen={setIsModelOpen} />
      <AskSection />
      <AdvantagesOfQuestions
        title="match the following"
        arr={[
          "Great for users who have a lower reading level",
          "Less chance for guessing than other question types",
          "Can cover a large amount of content.",
          "Easy to read",
          "Easy to understand",
          "Easy to grade on paper",
          "Graded automatically online",
          "More engaging for users",
        ]}
        img={MatchTheFollowingImg}
      />
      <HelpService
        helpServiceTitle
        subCategoryID={subCatagoryID}
      />
      <HelpSteps subCategoryId={subCatagoryID} />
      <Milestonerewards categoryId={categoryId} />
      <BonusSection
        subCategoryId={subCatagoryID}
        onClick={() => {
          setIsModelOpen(true);
        }}
      />
      <Universities categoryId={categoryId} />
      <StudentStories />
      <Footer />
      {isModelOpen ? (
        <CommonForm
          isOpen={isModelOpen}
          toggle={toggle}
          typeData={{
            serviceType: "HomeworkHelp",
            type: "mcqHelp",
            totalSteps: 3,
          }}
          subCategory={subCatagoryID}
        />
      ) : (
        <></>
      )}
      <ScrollTop />
      <GetPopup />
    </div>
  );
};

export default MatchTheFollowing;
