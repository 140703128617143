function padZero(num) {
  return num < 10 ? `0${num}` : num;
}
function format(date, formatString) {
  // Format year
  formatString = formatString.replace(/YYYY/g, date.getFullYear().toString());
  formatString = formatString.replace(/MM/g, padZero(date.getMonth() + 1));
  formatString = formatString.replace(/DD/g, padZero(date.getDate()));
  formatString = formatString.replace(/hh/g, padZero(date.getHours()));
  formatString = formatString.replace(/mm/g, padZero(date.getMinutes()));
  formatString = formatString.replace(/ss/g, padZero(date.getSeconds()));

  return formatString;
}

export function minuteDifference(stamp) {
  const createdAt = new Date(stamp);
  const currentTime = new Date();

  // Calculate the difference in milliseconds
  const timeDifferenceMs = currentTime - createdAt;

  // Convert milliseconds to minutes and hours
  const minutesDifference = Math.floor(timeDifferenceMs / (1000 * 60));
  const hoursDifference = Math.floor(minutesDifference / 60);
  return `${hoursDifference} h ${minutesDifference % 60} m ago`;
}

export function getTimeRemaining(timestamp, returnStamp = false) {
  // Get current date and time
  var now = new Date().getTime();
  var stamp = new Date(timestamp).getTime();

  // Calculate the difference between current time and the provided timestamp
  var difference = stamp - now;

  // Calculate hours, minutes, and seconds remaining
  var hours = Math.floor(
    (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  var minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
  var seconds = Math.floor((difference % (1000 * 60)) / 1000);

  // Return an object with hours, minutes, and seconds
  // console.log(hours, minutes, seconds);
  if (returnStamp) {
    return (
      <span>
        {hours < 10 ? "0" + hours : hours}:
        {minutes < 10 ? "0" + minutes : minutes}:
        {seconds < 10 ? "0" + seconds : seconds}
      </span>
    );
  } else {
    return {
      hours: hours,
      minutes: minutes,
      seconds: seconds,
    };
  }
}

export function stopWatch(stamp, returnStamp) {
  const interval = setInterval(() => {
    const response = getTimeRemaining(stamp);
    if (
      response.hours === 0 &&
      response.minutes === 0 &&
      response.seconds === 0
    ) {
      if (returnStamp) {
        clearInterval(interval); // Stop the interval when time is up
        return (
          <span>
            {response.hours < 10 ? "0" + response.hours : response.hours}:
            {response.minutes < 10 ? "0" + response.minutes : response.minutes}:
            {response.seconds < 10 ? "0" + response.seconds : response.seconds}
          </span>
        );
      }
    }
  }, 3000);
}

export default format;
