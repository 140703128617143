import axiosInstance from "../axiosInterceptor";

export async function verifyOTP(body) {
  try {
    const response = await axiosInstance.post(`student/verifyOTP`, body);
    const data = response?.data;
    return data;
  } catch (error) {
    console.log(error.response);
    return error.response?.data;
  }
}
