import React from "react";
import { Nav, NavItem } from "reactstrap";

import s1 from "../../Assets/images/dashboard-sidebar-img-1.png";

const DashboardSidebarRight = () => {
  return (
    <>
      <Nav
        className="me-auto align-items-center justify-content-end py-4 w-100"
        navbar
        vertical
      >
        <NavItem className="w-100 d-flex justify-content-center border-bottom py-3">
          <img src={s1} alt="" style={{ width: "80%" }} />
        </NavItem>
      </Nav>
    </>
  );
};

export default DashboardSidebarRight;
