// ** Utils Imports ** //
import formatDate from './formatDate';

function getDaysInMonth(
    month = new Date().getMonth(),
    year = new Date().getFullYear(),
) {
    const days = [];

    const lastDay = new Date(year, month + 1, 0).getDate();
    const allDates = Array.from(
        { length: lastDay },
        (_, index) => new Date(year, month, index + 1),
    );

    allDates.forEach((date) => {
        days.push(formatDate(new Date(date)));
    });

    return days;
}

export default getDaysInMonth;
