import React, { useEffect, useState } from "react";
import {
  Container,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import calenderImage from "../../../Assets/icons/calender.svg";
import clockImage from "../../../Assets/icons/clock.svg";
import vector from "../../../Assets/icons/Vector.png";
import DateInput from "../../../Components/datePicker/multiDatePicker/dateInput";
import TimezoneSelect from "react-timezone-select";
import "../liveSession.scss";
import Button from "../../../Components/Buttons/Button";
import axiosInstance from "../../../axiosInterceptor";

const formatDate = (date) => {
  const dateObj = new Date(date);
  const options = { month: "short", day: "2-digit", year: "numeric" };
  const formattedDate = dateObj.toLocaleDateString("en-US", options);
  return formattedDate;
};

const LiveForm2 = ({ payload, handleChange, formData, error, isError }) => {
  const [selectedTimezone, setSelectedTimezone] = useState(
    payload.timeZone || Intl.DateTimeFormat().resolvedOptions().timeZone
  );
  const [nestedModal, setNestedModal] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  const handleOpenModal = () => {
    setNestedModal(true);
    setShowPopup(false);
  };
  const toggleNested = () => {
    setNestedModal(!nestedModal);
  };

  useEffect(() => {
    handleChange({
      target: {
        name: "timeZone",
        value: selectedTimezone,
      },
    });
  }, [selectedTimezone]);

  const [setting, setSetting] = useState([]);

  const flatdiscontent = async () => {
    try {
      const res = await axiosInstance.get(`/admin/setting/getSetting`);
      setSetting(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    flatdiscontent();
  }, []);

  return (
    <Container className="select-your-subject-container">
      <h1 className="step-header text-center">Date & Time</h1>
      <Row className="justify-content-center">
        <div className="col-md-8">
          <div className="row justify-content-between my-3">
            <div className="col-md-6 mb-4">
              <DateInput
                name="date"
                variant="secondary"
                type="single"
                value={payload.date}
                setShowPopup={setShowPopup}
                showPopup={showPopup}
                onChange={(e) => {
                  handleChange({
                    target: {
                      name: "date",
                      value: e.date,
                    },
                  });
                }}
                className="border-0 rounded-3 w-100 px-3 py-1"
              />
              <div className="date-time-container">
                <span>Select Date</span>
                <span>
                  <img src={calenderImage} alt="calender" />
                </span>
                <span>
                  <b>{formatDate(payload.date)}</b>
                </span>
              </div>
            </div>
            <div className="col-md-6" onClick={handleOpenModal}>
              <Modal isOpen={nestedModal} toggle={toggleNested} centered>
                <ModalHeader className="justify-content-center">
                  Enter Time
                </ModalHeader>
                <ModalBody>
                  <div>
                    <TimezoneSelect
                      name="timeZone"
                      value={selectedTimezone}
                      onChange={setSelectedTimezone}
                    />
                  </div>
                  <div className="d-flex p-5 justify-content-center gap-5 align-item-center">
                    <FormGroup style={{ marginBottom: 0 }}>
                      <Input
                        type="number"
                        name="hours"
                        min={1}
                        max={12}
                        value={payload.hours}
                        onChange={handleChange}
                        className="timeSelection"
                      >
                      </Input>
                    </FormGroup>
                    <div className="mb-3 d-flex align-item-center fs-3">
                      <span className="d-flex align-items-center">:</span>
                    </div>
                    <FormGroup>
                      <Input
                        type="number"
                        name="minutes"
                        min={1}
                        max={60}
                        value={payload.minutes}
                        onChange={handleChange}
                        className="timeSelection"
                      >
                      </Input>
                    </FormGroup>
                  </div>
                  <div className="d-flex justify-content-center">
                    <div
                      className={`aMpM  ${payload.meradian === "AM" ? " active" : ""
                        }`}
                      onClick={() =>
                        handleChange({
                          target: {
                            name: "meradian",
                            value: "AM",
                          },
                        })
                      }
                    >
                      AM
                    </div>
                    <div
                      className={`aMpM  ${payload.meradian === "PM" ? " active" : ""
                        }`}
                      onClick={() =>
                        handleChange({
                          target: {
                            name: "meradian",
                            value: "PM",
                          },
                        })
                      }
                    >
                      PM
                    </div>
                  </div>
                  <div className="mt-5 d-flex justify-content-center">
                    <Button
                      varient="outline-secondaty"
                      text="Ok"
                      style={{ padding: "0.5rem 5rem" }}
                      onClick={toggleNested}
                    />
                  </div>
                </ModalBody>
              </Modal>
              <div className="date-time-container">
                <span>Select Time</span>
                <span>
                  <img src={clockImage} alt="calender" />
                </span>
                <span>
                  <b>
                    {payload.hours.length === 1
                      ? payload.hours.padStart(2, "0")
                      : payload.hours}
                    :{" "}
                    {payload.minutes.length === 1
                      ? payload.minutes.padStart(2, "0")
                      : payload.minutes}{" "}
                    {payload.meradian}
                  </b>
                </span>
              </div>
            </div>
          </div>
        </div>
      </Row>
      <h1 className="step-header text-center pt-0">Set Your Duration</h1>
      <div className="row my-1  align-items-center justify-content-center">
        <div className="col-md-9">
          <div className="row align-items-center justify-content-center">
            <div className="col-md-12 p-2 d-flex justify-content-center align-items-center">
              <span className="estimatedPriceLabel">
                <img src={vector} className="w-10" alt="calender" />
              </span>{" "}
              <span className="estimatedPrice px-3">
                <Input
                  className="w-100 form-control p-3 mt-2 fs-4 shadow border-light"
                  type="number"
                  name="durationMintues"
                  min={1}
                  max={2000}
                  value={payload.durationMintues || ""}
                  onChange={handleChange}
                />
              </span>
              <span className="fs-3 mt-3 ">Minutes</span>
            </div>
          </div>
        </div>
      </div>
      {isError && error.durationMintues && (
        <div className="col-md-12 text-center">
          {" "}
          <span className="text-danger">{error.durationMintues}</span>
        </div>
      )}
      <div className="row my-3  align-items-center justify-content-center">
        <div className="col-md-9 border-1 border">
          <div className="row align-items-center justify-content-center">
            <div className="col-md-12 p-3 d-flex justify-content-center">
              <span className="estimatedPriceLabel mx-2">Estimated Price:</span>
              <span className="estimatedPrice">
                ${formData.studentPrice ? formData.studentPrice?.toFixed(2) : 0}
              </span>
            </div>
          </div>
        </div>
        <div className="discount mt-4">
          <h2 className="text-center"> {setting?.promoCodeLabel}</h2>
        </div>
      </div>
    </Container>
  );
};

export default LiveForm2;
