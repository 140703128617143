import React, { useEffect, useRef } from "react";
import { Container, FormGroup, Input, Row } from "reactstrap";
import { IoCloseSharp } from "react-icons/io5";
import Select from "react-select";

const LiveForm1 = ({
  payload,
  handleChange,
  formData,
  rest,
  error,
  isError,
  setIsError,
}) => {
  const questionRef = useRef(null);
  const referenceRef = useRef(null);

  useEffect(() => {
    questionRef.current.addEventListener("dragover", handleQuestionDragOver);
    questionRef.current.addEventListener("drop", handleQuestionDrop);

    return () => {
      questionRef.current?.removeEventListener(
        "dragover",
        handleQuestionDragOver
      );
      questionRef.current?.removeEventListener("drop", handleQuestionDrop);
    };
  }, []);

  const handleQuestionDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleQuestionDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { files } = e.dataTransfer;

    if (files && files.length) {
      // onUpload(files);
      const event = {
        target: {
          files: files,
          name: "questionPDF",
        },
      };
      handleChange(event);
    }
  };

  useEffect(() => {
    if (payload?.haveReferenceFile === "Yes") {
      referenceRef.current.addEventListener(
        "dragover",
        handleReferenceDragOver
      );
      referenceRef.current.addEventListener("drop", handleReferenceDrop);
    }

    return () => {
      referenceRef.current?.removeEventListener(
        "dragover",
        handleReferenceDragOver
      );
      referenceRef.current?.removeEventListener("drop", handleReferenceDrop);
    };
  }, [payload?.haveReferenceFile]);

  const handleReferenceDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleReferenceDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { files } = e.dataTransfer;

    if (files && files.length) {
      // onUpload(files);
      const event = {
        target: {
          files: files,
          name: "referencePDF",
        },
      };
      handleChange(event);
    }
  };

  const subjectOptions = formData?.subject.map((data) => ({
    value: data._id,
    label: data.questionSubject,
  }));

  return (
    <Container>
      <h1 className="step-header text-center">
        Place Your Reference File
        {rest?.categories?.find((x) => x?._id === rest?.selectedAssignment)
          ?.name ?? ""}
      </h1>
      <div className="row py-3">
        <div className="col-md-6 mb-4">
          <div className="place-your-writing-container">
            <label
              htmlFor="uploadQuestionPDF"
              className="place-your-writing-label"
            >
              <div className="upload-file-container" ref={questionRef}>
                {/* {!payload.questionPDF ? ( */}
                <span>
                  Drop your File or <span className="text-primary">Browse</span>
                </span>
                {/* ) : (
              <span className="text-primary"></span>
            )} */}
              </div>
              <input
                type="file"
                hidden
                multiple
                id="uploadQuestionPDF"
                name="questionPDF"
                onChange={handleChange}
                accept=".pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.txt,.csv,.rtf,.html,.htm,.xml,.json,.js,.css,.java,.py,.cpp,.cs,.rb,.php,.swift,.pl,.sh,.bat,.ps1,.vbs,.asm,.c,.h,.cpp,.hpp,.cs,.java,.jar,.py,.rb,.pl,.php,.shtml,.asp,.aspx,.jsp,.aspx,.jsp,.cfm,.actionscript,.sgf,.wasm,.unity,.gmx,.gmz,.love,.ase,.aseprite,.atlas,.png,.jpg,.jpeg"
              />
            </label>
          </div>
        </div>
        <div className="col-md-6">
          <div className="place-your-writing-container">
            <textarea
              name="question"
              id="typeYourQuestion"
              style={{ borderRadius: "inherit" }}
              placeholder="Type your questions here"
              value={payload?.question}
              onChange={handleChange}
            ></textarea>
          </div>
        </div>
        {isError && error.questionPDF !== "" && (
          <span className="text-danger">{error.questionPDF}</span>
        )}
        <div className="w-auto path-contents">
          {payload?.questionPDF &&
            payload?.questionPDF?.map((file, index) => (
              <div className="row reference-section mt-3 rounded ">
                <div>
                  <div className="text-primary fs-5 border auto-width mx-2">
                    <span className="mx-2">{file.name}</span>
                    <button
                      className="btnClose ms-1 "
                      onClick={() =>
                        rest?.handleRemoveFile(index, "questionPDF")
                      }
                      style={{ zIndex: 1 }}
                      type="button"
                    >
                      <IoCloseSharp className="text-center mb-1" />
                    </button>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
      <div className="row py-2">
        <div className="col-md-12 mb-2">
          <h5 className="text-center">Please send your Zip File in google drive link and share the link question text !</h5>
        </div>
      </div>
      <div className="row mb-2 border border-1 reference-section">
        <div className="col-md-6 p-0 fs-3 text-center d-flex flex-column gap-2">
          <b>Have Reference File :</b>
          <span className="d-flex gap-3 justify-content-center align-items-center">
            <Input
              type="radio"
              onChange={handleChange}
              name="haveReferenceFile"
              value={"Yes"}
              checked={payload?.haveReferenceFile === "Yes"}
            />
            Yes
            <Input
              type="radio"
              onChange={handleChange}
              name="haveReferenceFile"
              value={"No"}
              checked={payload?.haveReferenceFile === "No"}
            />
            No
          </span>
        </div>
        <div
          className="col-md-6 p-0 py-4"
          style={{
            opacity: payload?.haveReferenceFile
              ? payload?.haveReferenceFile === "No"
                ? 0.5
                : ""
              : 0.5,
          }}
        >
          <div className="place-your-writing-container">
            <label
              htmlFor="uploadReferencePDF"
              className="place-your-writing-label"
            >
              <div className="upload-file-container" ref={referenceRef}>
                <span>
                  Drop your File or <span className="text-primary">Browse</span>
                </span>
              </div>
              <input
                type="file"
                hidden
                multiple
                disabled={payload?.haveReferenceFile !== "Yes"}
                id="uploadReferencePDF"
                name="referencePDF"
                accept=".pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.txt,.csv,.rtf,.html,.htm,.xml,.json,.js,.css,.java,.py,.cpp,.cs,.rb,.php,.swift,.pl,.sh,.bat,.ps1,.vbs,.asm,.c,.h,.cpp,.hpp,.cs,.java,.jar,.py,.rb,.pl,.php,.shtml,.asp,.aspx,.jsp,.aspx,.jsp,.cfm,.actionscript,.sgf,.wasm,.unity,.gmx,.gmz,.love,.ase,.aseprite,.atlas,.png,.jpg,.jpeg"
                onChange={
                  payload?.haveReferenceFile
                    ? payload?.haveReferenceFile === "Yes"
                      ? handleChange
                      : () => { }
                    : () => { }
                }
              />
            </label>
          </div>
        </div>
      </div>
      {isError && error.referencePDF !== "" && (
        <span className="text-danger">{error.referencePDF}</span>
      )}
      <div className="row py-3">
        <div className="w-auto path-contents">
          {payload?.referencePDF &&
            payload?.referencePDF?.map((file, index) => (
              <div className="row reference-section mt-3 rounded">
                <div>
                  <div className="text-primary fs-5 border auto-width mx-2">
                    <span className="mx-2">{file.name}</span>
                    <button
                      className="btnClose ms-1"
                      onClick={() =>
                        rest?.handleRemoveFile(index, "referencePDF")
                      }
                      style={{ zIndex: 1 }}
                      type="button"
                    >
                      <IoCloseSharp className="text-center mb-1" />
                    </button>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
      <Row>
        <FormGroup>
          <Select
            placeholder="Select your subject"
            value={subjectOptions.find((x) => x.value === payload?.subject)}
            onChange={(event) =>
              handleChange({
                target: {
                  name: "subject",
                  value: event.value,
                },
              })
            }
            name="subject"
            className="question-select mt-5 select-subject"
            options={subjectOptions}
          />
        </FormGroup>
        {isError && error.subject !== "" && (
          <span className="text-danger">{error.subject}</span>
        )}
      </Row>
    </Container>
  );
};

export default LiveForm1;
