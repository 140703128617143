import axiosInstance from "../axiosInterceptor";

export async function checkToken() {
  const response = await axiosInstance.post(`student/checkToken`, {
    token: localStorage.getItem("token") ?? "",
  });
  const data = response?.data;
  const lastSixDigits = data?.data?.student_id.toString().slice(-6);
  localStorage.setItem("referralCode", lastSixDigits);
  if (!response?.data.isSuccess) {
    localStorage.removeItem("token");
    localStorage.removeItem("questionId");
    localStorage.removeItem("serviceType");
  }
  return data;
}
