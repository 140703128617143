import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axiosInstance from "../axiosInterceptor";
import "./Navbar.scss";
import { CategoryContext } from "../categoryContext";
import logo from "../Assets/logo/logo.svg";
import Home from "../Assets/images/home.svg";
const baseURL = process.env.REACT_APP_FILE_BASE_URL;

const Navbar = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const [isNavbarOpen, setIsNavbarOpen] = useState(false);
  const [openSubmenu, setOpenSubmenu] = useState(null);
  const [navbarMenu, setNavbarMenu] = useState([]);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const [socialMedia, setsocialMedia] = useState([]);
  const [isSearchDropdownOpen, setIsSearchDropdownOpen] = useState(false);
  const [prevSearchTerm, setPrevSearchTerm] = useState("");
  const inputRef = useRef(null);

  const fetchSocialMediaData = async () => {
    try {
      const res = await axiosInstance.get(`/admin/socialMedia/getSocialMedia`);
      setsocialMedia(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchData = async () => {
    try {
      const res = await axiosInstance.get(
        "/admin/category/getCategoryWiseSubcategory"
      );
      setNavbarMenu(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
    fetchSocialMediaData();
    const token = localStorage.getItem("token");
    if (token) {
      setIsAuthenticated(true);
    }

    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const query = params.get("query");
    setSearchTerm(query || "");
  }, [location.search]);

  useEffect(() => {
    if (isSearchDropdownOpen || searchTerm.trim() !== "") {
      inputRef.current.focus();
    }
  }, [isSearchDropdownOpen, searchTerm]);

  const toggleNavbar = () => setIsNavbarOpen(!isNavbarOpen);
  const toggleSubmenu = (index) => {
    setOpenSubmenu(openSubmenu === index ? null : index);
  };

  const { setCategoryId } = useContext(CategoryContext);

  const handleSearch = () => {
    if (searchTerm.trim() !== "") {
      if (searchTerm !== prevSearchTerm) {
        navigate(`/search?query=${searchTerm}`);
        setPrevSearchTerm(searchTerm);
      }
    }
    setIsSearchDropdownOpen(false);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  const toggleSearchDropdown = () => {
    setIsSearchDropdownOpen(!isSearchDropdownOpen);
  };

  return (
    <>
      <header
        className={`rbt-header rbt-header-10 ${
          isSticky ? "header-sticky" : ""
        }`}
      >
        <div className="rbt-header-wrapper header-space-between">
          <div className="container-fluid">
            <div className="mainbar-row rbt-navigation-center align-items-center">
              <div className="header-left rbt-header-content">
                <div className="header-info">
                  <div className="logo">
                    <Link to="/">
                      <img src={logo} alt="Vaidik Logo Images" />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="rbt-main-navigation d-none d-xl-block">
                <nav className="mainmenu-nav">
                  <ul className="mainmenu scrollable-menu">
                    {navbarMenu.length > 0 && (
                      <li>
                        <Link to="/">
                          <img
                            src={Home}
                            alt="home"
                            width={20}
                            height={20}
                            className="home-img"
                          />
                        </Link>
                      </li>
                    )}
                    {navbarMenu
                      .filter((nav) => nav.categoryName !== "Home")
                      .map((category, index) => {
                        const isOpen = openSubmenu === index;
                        return (
                          <li
                            key={category._id}
                            className={`has-dropdown has-menu-child-item ${
                              isOpen ? "open" : ""
                            }`}
                            onClick={() => toggleSubmenu(index)}
                          >
                            <Link to="#">
                              {category.categoryName}
                              <i className="feather-chevron-down" />
                            </Link>
                            <ul className="submenu">
                              {category.subcategories?.map((item) => (
                                <li key={item._id} className="has-dropdown">
                                  <Link
                                    to={item.path}
                                    onClick={() => setCategoryId(item._id)}
                                  >
                                    {item.name}
                                  </Link>
                                </li>
                              ))}
                            </ul>
                          </li>
                        );
                      })}
                    {navbarMenu.length > 0 && (
                      <>
                        <li>
                          <Link to="/reviews">Reviews</Link>
                        </li>
                        <li>
                          <Link to="/library">Library</Link>
                        </li>
                      </>
                    )}
                  </ul>
                </nav>
              </div>
              <div className="header-right">
                <ul className="quick-access">
                  <li className="access-icon">
                    <button
                      className="search-trigger-active rbt-round-btn d-md-none"
                      onClick={toggleSearchDropdown}
                    >
                      <i className="feather-search" />
                    </button>
                    <div className="rbt-search-style-1 d-none d-sm-block d-sm-none d-md-block">
                      <input
                        ref={inputRef}
                        type="text"
                        placeholder="Search.."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        onKeyDown={handleKeyPress}
                        autoFocus={
                          isSearchDropdownOpen || searchTerm.trim() !== ""
                        }
                      />
                      <button className="search-btn" onClick={handleSearch}>
                        <i className="feather-search" />
                      </button>
                    </div>
                  </li>
                  <li className="account-access rbt-user-wrapper d-none d-xl-block">
                    <Link className="rbt-btn btn-gradient btn-sm" to="/login">
                      <i className="feather-user" />
                      Sign In
                    </Link>
                  </li>
                  <li className="access-icon rbt-user-wrapper d-block d-xl-none">
                    <Link className="rbt-round-btn" to="/login">
                      <i className="feather-user" />
                    </Link>
                  </li>
                </ul>
                <div className="mobile-menu-bar d-block d-xl-none">
                  <div className="hamberger">
                    <button
                      className="hamberger-button rbt-round-btn"
                      onClick={toggleNavbar}
                    >
                      <i className="feather-menu" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={`rbt-search-dropdown ${
              isSearchDropdownOpen ? "active" : ""
            }`}
          >
            <div className="wrapper">
              <div className="row">
                <div className="col-lg-12">
                  <div className="d-flex">
                    <input
                      type="text"
                      placeholder="Search.."
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      onKeyDown={handleKeyPress}
                    />
                    <div className="submit-btn">
                      <button
                        className="rbt-btn btn-gradient btn-md text-decoration-none"
                        onClick={handleSearch}
                      >
                        Search
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div className={`popup-mobile-menu ${isNavbarOpen ? "active" : ""}`}>
        <div className="inner-wrapper">
          <div className="inner-top">
            <div className="content">
              <div className="logo">
                <Link to="/">
                  <img src={logo} alt="Logo Vaidik" />
                </Link>
              </div>
              <div className="rbt-btn-close">
                <button
                  className="close-button rbt-round-btn"
                  onClick={() => setIsNavbarOpen(false)}
                >
                  <i className="feather-x" />
                </button>
              </div>
            </div>
          </div>
          <nav className="mainmenu-nav">
            <ul className="mainmenu">
              {navbarMenu
                .filter((nav) => nav.categoryName !== "Home")
                .map((category, index) => {
                  const isOpen = openSubmenu === index;
                  return (
                    <li
                      key={category._id}
                      className={`has-dropdown has-menu-child-item ${
                        isOpen ? "open" : ""
                      }`}
                      onClick={() => toggleSubmenu(index)}
                    >
                      <Link to="#">
                        {category.categoryName}
                        {<i className="feather-chevron-down" />}
                      </Link>
                      <ul
                        className="submenu"
                        style={{ display: `${isOpen ? "block" : "none"}` }}
                      >
                        {category.subcategories?.map((item) => (
                          <li key={item._id}>
                            <Link
                              to={item.path}
                              onClick={() => setCategoryId(item._id)}
                            >
                              {item.name}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </li>
                  );
                })}
              {navbarMenu.length > 0 && (
                <>
                  <li>
                    <Link to="/reviews">Reviews</Link>
                  </li>
                  <li>
                    <Link to="/library">Library</Link>
                  </li>
                </>
              )}
            </ul>
          </nav>
          <div className="mobile-menu-bottom text-start">
            <div className="social-share-wrapper">
              <h3 className="mb-3">Find With Us</h3>
              <ul className="social-icon social-default icon-naked justify-content-start">
                {socialMedia?.map((social) => (
                  <li key={social._id}>
                    <Link className={social.name.toLowerCase()} to={social.url}>
                      <img
                        src={`${baseURL}${social.image}`}
                        alt={social.name}
                        width={35}
                      />
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
