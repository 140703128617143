import axiosInstance from "../axiosInterceptor";

export async function sendOTP(body) {
  try {
    const response = await axiosInstance.post(`student/sendOTP`, body);
    const data = response?.data;
    return data;
  } catch (error) {
    return error.response?.data;
  }
}
