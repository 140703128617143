import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "reactstrap";
import "./WhatsappSupport.scss";
import wpIcon from "./../../Assets/images/whatsapp.png";
import { getWhatsappData } from "../../services/fetchQueryData";

const WhatsappSupport = ({ questionId, studentId = "" }) => {
  const [open, setOpen] = useState("1");
  const [options, setOptions] = useState([]);
  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };

  const fetchWpData = async () => {
    const data = await getWhatsappData();
    setOptions(data.data);
  };

  useEffect(() => {
    fetchWpData();
  }, []);

  const handleWhatsappRedirect = (label, number) => {
    const phoneNumber = number;
    const formattedMessage = `StudentID:*${studentId}*%0aQuestionID:*${questionId}*%0aQuery:*${label}*`;

    const whatsappURL = `https://wa.me/${phoneNumber}?text=${formattedMessage}`;
    window.open(
      whatsappURL,
      "_blank"
    );
  };
  return (
    <Accordion
      open={open}
      toggle={toggle}
      className="accordian-Wrapper content-card"
    >
      <AccordionItem>
        <AccordionHeader targetId="1" className="heading">
          Whatsapp Chat
        </AccordionHeader>
        <AccordionBody accordionId="1">
          <div className="d-flex">
            <span className="pe-3 pb-3 greyTitle text-start">
              if you have any Query Regarding :
            </span>
          </div>
          <div className="d-flex flex-wrap gap-3">
            {options
              ?.filter((op) => op)
              ?.map((query) => {
                return (
                  <div className="whatsappCard" key={query.queryName}>
                    <span>{query.queryName}</span>
                    <span
                      className="whatsappIcon"
                      onClick={() =>
                        handleWhatsappRedirect(
                          query?.queryName,
                          query?.mobileNo
                        )
                      }
                    >
                      {<img src={wpIcon} height="24px" width="24px" />}
                    </span>
                  </div>
                );
              })}
          </div>
        </AccordionBody>
      </AccordionItem>
    </Accordion>
  );
};

export default WhatsappSupport;
