export const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];

export const monthNamesWithShortNames = [
    {
        fullMonth: 'January',
        shortMonth: 'Jan',
    },
    {
        fullMonth: 'February',
        shortMonth: 'Feb',
    },
    {
        fullMonth: 'March',
        shortMonth: 'Mar',
    },
    {
        fullMonth: 'April',
        shortMonth: 'Apr',
    },
    {
        fullMonth: 'May',
        shortMonth: 'May',
    },
    {
        fullMonth: 'June',
        shortMonth: 'Jun',
    },
    {
        fullMonth: 'July',
        shortMonth: 'Jul',
    },
    {
        fullMonth: 'August',
        shortMonth: 'Aug',
    },
    {
        fullMonth: 'September',
        shortMonth: 'Sep',
    },
    {
        fullMonth: 'October',
        shortMonth: 'Oct',
    },
    {
        fullMonth: 'November',
        shortMonth: 'Nov',
    },
    {
        fullMonth: 'December',
        shortMonth: 'Dec',
    },
];
