import React, { useState } from "react";
import MainBanner from "../../../Components/MainBanner";
import AskSection from "../../../Components/AskSection";
import HelpSteps from "../../../Components/HelpSteps";
import StudentStories from "../../../Components/StudentStories";
import HelpService from "../../../Components/HelpService";
import Footer from "../../../Components/Footer";
import BonusSection from "../../../Components/BonusSection";
import CommonForm from "../../HomePage/Form/CommonForm";
import ScrollTop from "../../../Components/Buttons/ScrollTop";
import { Link } from "react-router-dom";
import Navbar from "../../../Components/Navbar";
import Nav from "../../../Components/Nav";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import Universities from "../../../Components/Universities";
import Milestonerewards from "../../../Components/Milestonerewards";
import Page from "../../Metadata/Page";
import ComWorldWide from "../../../Components/ComWorldWide";
import ComReadMoreSection from "../../../Components/ComReadMoreSection";

const MCQService = () => {
  const subCategoryId = "6556546650f21b2f5a97f685";
  const categoryId = "65524e5e3c005ef3b51907c6";
  const [isModelOpen, setIsModelOpen] = useState(false);

  const toggle = () => {
    setIsModelOpen(!isModelOpen);
  };

  return (
    <div>
      <Page subCategoryId={subCategoryId} categoryId={categoryId} />
      <Nav />
      <Navbar />
      <div className="row mt-4 mb-4 px-4 m-0">
        <div className="col-lg-12">
          <div className="breadcrumb-inner text-start">
            <ul className="page-list">
              <li className="rbt-breadcrumb-item">
                <Link to="/">Home</Link>
              </li>
              <li>
                <div className="icon-right">
                  <MdOutlineKeyboardArrowRight />
                </div>
              </li>
              <li className="rbt-breadcrumb-item">Homework Help</li>
              <li>
                <div className="icon-right">
                  <MdOutlineKeyboardArrowRight />
                </div>
              </li>
              <li className="rbt-breadcrumb-item active">
                Easy Level Question Help
              </li>
            </ul>
          </div>
        </div>
      </div>
      <MainBanner id={subCategoryId} setIsModelOpen={setIsModelOpen} />
      <AskSection />
      <HelpService
        helpServiceDescription="Easy Level Question Help"
        subCategoryID={subCategoryId}
      />
      <HelpSteps subCategoryId={subCategoryId} />
      <Milestonerewards categoryId={categoryId} />
      <BonusSection
        subCategoryId={subCategoryId}
        onClick={() => {
          setIsModelOpen(true);
        }}
      />
      <Universities categoryId={categoryId} />
      <StudentStories />
      <ComWorldWide id={subCategoryId} />
      <ComReadMoreSection id={subCategoryId}/>
      <Footer />
      {isModelOpen ? (
        <CommonForm
          isOpen={isModelOpen}
          toggle={toggle}
          typeData={{
            serviceType: "HomeworkHelp",
            type: "mcqHelp",
            totalSteps: 3,
          }}
          subCategory={subCategoryId}
        />
      ) : (
        <></>
      )}
      <ScrollTop />
    </div>
  );
};

export default MCQService;
