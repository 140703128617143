import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getLibraryTranscriptText } from "../../services/getLibraryTranscriptText";
import "./ViewLibraryAnswer.scss";
import Renderer from "../../Components/Renderer";
import logo from "../../Assets/logo/logo.png";
import Group from "../../Assets/images/Group 616.png";
import Clock from "../../Assets/icons/clock-sm.gif";
import Layer from "../../Assets/images/Layer_x0020_1.png";
import ScrollTop from "../../Components/Buttons/ScrollTop";
import CommonForm from "../HomePage/Form/CommonForm";
import Navbar from "../../Components/Navbar";
import Nav from "../../Components/Nav";
import GetPopup from "../Staticpages/GetPopup";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";

const ViewLibraryAnswer = () => {
  const { search } = useParams();
  const [questionData, setQuestionData] = useState([]);
  const [isTranscriptVisible, setIsTranscriptVisible] = useState(false);
  const navigate = useNavigate();
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [serviceType, setServiceType] = useState("");

  const fetchData = async () => {
    const response = await getLibraryTranscriptText(search);
    if (response?.isSuccess) {
      setQuestionData(response.question);
    }
  };

  useEffect(() => {
    fetchData();
  }, [search]);

  const toggle = () => {
    setIsModelOpen(!isModelOpen);
  };

  const handlePopup = (serviceType, type, totalSteps) => {
    setIsModelOpen(true);
    setServiceType({ serviceType, type, totalSteps });
    localStorage.setItem("serviceType", serviceType);
  };

  const handlePreviousPage = () => {
    navigate(-1);
  };
  const toggleTranscriptVisibility = () => {
    setIsTranscriptVisible(!isTranscriptVisible);
  };


  return (
    <>
      <Nav />
      <Navbar />
      <div className="row mt-4 mb-4 px-4 m-0 breadcrumb-border">
        <div className="col-lg-12">
          <div className="breadcrumb-inner text-start">
            <ul className="page-list">
              <li className="rbt-breadcrumb-item">
                <Link to="/">Home</Link>
              </li>
              <li>
                <div className="icon-right">
                  <MdOutlineKeyboardArrowRight />
                </div>
              </li>
              <li className="rbt-breadcrumb-item">Library</li>
              <li>
                <div className="icon-right">
                  <MdOutlineKeyboardArrowRight />
                </div>
              </li>
              <li className="rbt-breadcrumb-item active" dangerouslySetInnerHTML={{ __html: questionData[0]?.question.slice(0, 50) + '...' }} />

            </ul>
          </div>
        </div>
      </div>
      <div className="d-md-flex">
        <div className="d-flex flex-column p-5 searchmobile h-100">
          <div
            className="back-link mb-3 text-start"
            onClick={() => handlePreviousPage()}
          >
            {`< Back to search results`}
          </div>
          <>
            {questionData[0]?.question && (
              <div className="align-items-start">
                <div className="d-flex row w-100">
                  <h3 className="fw-bold me-3 text-start">Question:</h3>
                  <div className="col-md-8 question-card text-start">
                    <Renderer content={questionData[0]?.question} />
                    <button
                      className="btn btn-primary mt-4 add-transcript"
                      onClick={toggleTranscriptVisibility}
                    >
                      {isTranscriptVisible ? "Hide Transcript" : "Show Transcript"}
                    </button>
                    {isTranscriptVisible && (
                      <Renderer content={questionData[0]?.transcriptText} className="mt-4" />
                    )}
                  </div>
                </div>
                {questionData[0]?.answer && (
                  <div className="d-flex mt-4 row w-100">
                    <h3 className="fw-bold me-3 text-start">Answer:</h3>
                    <div className="col-md-8 answer-card text-start">
                      <Renderer content={questionData[0]?.answer} />
                    </div>
                  </div>
                )}
              </div>
            )}
            {questionData[0]?.question && (
              <div className="row w-100">
                <div className="col-md-8 mt-4 search-sidebar-que">
                  <div className="main-class-que Layer">
                    <div className="row">
                      <div className="col-md-1 col-sm-3">
                        <div className="Layer">
                          <img src={Layer} alt="Layer" />
                        </div>
                      </div>
                      <div className="col-md-5 col-sm-9">
                        <p className="side-title-que">Our expert will answer within 20 minutes!</p>
                      </div>
                      <div className="col-md-5 col-sm-12 d-flex justify-content-end">
                        <div className="ass-button-que Layer-btn">
                          <button className="do-my-ass que" onClick={() => { handlePopup("HomeworkHelp", "mcqHelp", 3) }}>
                            Ask a Question
                          </button>
                        </div>
                      </div>
                      <div className="col-md-1 d-flex justify-content-end">
                        <div className="Layer">
                          <img src={Clock} alt="Clock" width="50" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        </div>
        <div className="search-width">
          <div className="mt-4 search-sidebar">
            <div className="main-class">
              <div className="logo mt-4 pt-4">
                <img src={logo} alt="" />
              </div>
              <div className="side-cont mt-4">
                <p className="side-head">
                  NEVER PAY EXTRA FOR YOUR ASSIGNMENTS
                </p>
              </div>
              <div className="side_24*7">
                <img src={Group} alt="" />
              </div>
              <div className="side-help">
                <span>
                  ARE YOU <span className="looking">LOOKING</span> FOR{" "}
                </span>
                <br />
                <span>
                  ASSIGNMENT <span className="help">HELP?</span>
                </span>
              </div>
              <div className="ass-button">
                <button className="do-my-ass" onClick={() => navigate("/")}>
                  Do my Assignment
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isModelOpen ? (
        <CommonForm
          isOpen={isModelOpen}
          toggle={toggle}
          typeData={serviceType}
          question={questionData[0]?.question}
        />
      ) : (
        <></>
      )}
      <ScrollTop />
      <GetPopup />
    </>
  );
};

export default ViewLibraryAnswer;
