import React from "react";
import { NavLink } from "react-router-dom";
import { Nav, NavItem } from "reactstrap";

import home from "../../Assets/icons/home.svg";
import history from "../../Assets/icons/history.svg";
import question from "../../Assets/icons/question.svg";
import library from "../../Assets/icons/library.svg";
import faq from "../../Assets/icons/faq.svg";
import contactUs from "../../Assets/icons/contact-us.svg";
import Icon1 from "../../Assets/images/Icon 1.svg"
import Icon2 from "../../Assets/images/Icon 2.svg"

import "./dashboard.scss";

const DashboardSidebarLeft = () => {
  return (
    <>
      <Nav
        className="me-auto align-items-center justify-content-end  w-100"
        navbar
        vertical
      >
        <NavItem className="px-3 w-100 d-flex justify-content-start ps-3 border-bottom py-3">
          <NavLink
            to="/dashboard/home"
            className={`menu nav-link d-flex align-items-center gap-4 ps-2 fs-3`}
            activeClassName="active-link"
          >
            <img src={home} alt="home" style={{ width: "20px", height: "20px" }} />{" "}
            <span className="link-text">Home</span>
          </NavLink>
        </NavItem>
        <NavItem className="px-3 w-100 d-flex justify-content-start ps-3 border-bottom py-3">
          <NavLink
            to="/dashboard/order-history"
            className={`menu nav-link d-flex align-items-center gap-4 ps-2 fs-3`}
            activeClassName="active-link"
          >
            <img
              src={history}
              alt="history"
              style={{ width: "20px", height: "20px" }}
            />{" "}
            <span className="link-text">Order History</span>
          </NavLink>
        </NavItem>
        <NavItem className="px-3 w-100 d-flex justify-content-start ps-3 border-bottom py-3">
          <NavLink
            to="/dashboard/questions"
            className={`menu nav-link d-flex align-items-center gap-4 ps-2 fs-3`}
            activeClassName="active-link"
          >
            <img
              src={question}
              alt="question"
              style={{ width: "20px", height: "20px" }}
            />{" "}
            <span className="link-text">My Questions</span>
          </NavLink>
        </NavItem>
        <NavItem className="px-3 w-100 d-flex justify-content-start ps-3 border-bottom py-3">
          <NavLink
            to="/dashboard/library"
            className={`menu nav-link d-flex align-items-center gap-4 ps-2 fs-3`}
            activeClassName="active-link"
          >
            <img
              src={library}
              alt="library"
              style={{ width: "20px", height: "20px" }}
            />{" "}
            <span className="link-text">Library</span>
          </NavLink>
        </NavItem>
        <NavItem className="px-3 w-100 d-flex justify-content-start ps-3 border-bottom py-3">
          <NavLink
            to="/dashboard/faq"
            className={`menu nav-link d-flex align-items-center gap-4 ps-2 fs-3`}
            activeClassName="active-link"
          >
            <img src={faq} alt="faq" style={{ width: "20px", height: "20px" }} />{" "}
            <span className="link-text">Faq</span>
          </NavLink>
        </NavItem>
        <NavItem className="px-3 w-100 d-flex justify-content-start ps-3 py-3">
          <NavLink
            to="/dashboard/contact-us"
            className={`menu nav-link d-flex align-items-center gap-4 ps-2 fs-3`}
            activeClassName="active-link"
          >
            <img
              src={contactUs}
              alt=""
              style={{ width: "20px", height: "20px" }}
            />{" "}
            <span className="link-text">Contact Us</span>
          </NavLink>
        </NavItem>
        <NavItem className="px-3 w-100 d-flex justify-content-start ps-3 border-bottom py-3 mt-4">
          <div
            className="nav-link d-flex align-items-center gap-4 ps-2 fs-3"
          >
            <span className="link-text">Bonus Section</span>
          </div>
        </NavItem>
        <NavItem className="px-3 w-100 d-flex justify-content-start ps-3 border-bottom py-3">
          <NavLink
            to="/dashboard/earnmoney"
            className={`menu nav-link d-flex align-items-center gap-4 ps-2 fs-3`}
            activeClassName="active-link"
          >
            <img src={Icon1} alt="Icon1" style={{ width: "20px", height: "20px" }} />{" "}
            <span className="link-text">Posting Streak</span>
          </NavLink>
        </NavItem>
        <NavItem className="px-3 w-100 d-flex justify-content-start ps-3 py-3">
          <NavLink
            to="/dashboard/referral-program"
            className={`menu nav-link d-flex align-items-center gap-4 ps-2 fs-3`}
            activeClassName="active-link"
          >
            <img
              src={Icon2}
              alt="Icon2"
              style={{ width: "20px", height: "20px" }}
            />{" "}
            <span className="link-text">Referral Program</span>
          </NavLink>
        </NavItem>
      </Nav>
    </>
  );
};

export default DashboardSidebarLeft;
