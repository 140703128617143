import React, { useEffect, useState } from "react";
import MainBanner from "../../../Components/MainBanner";
import AskSection from "../../../Components/AskSection";
import HelpSteps from "../../../Components/HelpSteps";
import BonusSection from "../../../Components/BonusSection";
import StudentStories from "../../../Components/StudentStories";
import Footer from "../../../Components/Footer";
import { getSubcategoryWiseContent } from "../../../services/getSubcategoryWiseContent";
import HelpService from "../../../Components/HelpService";
import { Link, useNavigate } from "react-router-dom";
import { getAllSubject } from "../../../services/getAllSubject";
import { getCategoryWiseSubcategory } from "../../../services/getCategoryWiseSubcategory";
import { getSubcategoryWisePrice } from "../../../services/getSubcategoryWisePrice";
import { createQuestionLivesessionHelp } from "../../../services/createQuestionLivesessionHelp";
import { getPriceLivehelp } from "../../../services/getPriceLivehelp";
import { updateLiveSessionQuestion } from "../../../services/updateLiveSessionQuestion";
import CommonForm from "../../HomePage/Form/CommonForm";
import ScrollTop from "../../../Components/Buttons/ScrollTop";
import Navbar from "../../../Components/Navbar";
import Nav from "../../../Components/Nav";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import Universities from "../../../Components/Universities";
import Milestonerewards from "../../../Components/Milestonerewards";
import Page from "../../Metadata/Page";
import ComWorldWide from "../../../Components/ComWorldWide";
import ComReadMoreSection from "../../../Components/ComReadMoreSection";


const LiveSessionChatHelp = () => {
  const subCategoryId = "65a17a11740c57094df25ee7";
  const categoryId = "65524e353c005ef3b51907c2";
  const [pageData, setPageData] = useState([]);
  const fetchData = async () => {
    await getSubcategoryWiseContent(subCategoryId)
      .then((data) => {
        setPageData(data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    fetchData();
  }, []);

  const defaultTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const defaultValue = {
    haveReferenceFile: "No",
    date: new Date(),
    subCategory: subCategoryId,
    hours:
      new Date(new Date().setHours(new Date().getHours() + 4)).getHours() %
      12 || 12,
    minutes: new Date(new Date()).getMinutes(),
    meradian: new Date(new Date()).getHours() < 12 ? "AM" : "PM",
    durationMintues: 60,
    timeZone: defaultTimezone,
  };
  const [categories, setCategories] = useState([]);
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [payload, setPayload] = useState(defaultValue);

  const [formData, setFormData] = useState({});
  const navigate = useNavigate();
  const [isQuestionSubmited, setIsQuestionSubmited] = useState(false);
  const [isError, setIsError] = useState(false);
  const toggle = () => {
    setIsModelOpen(!isModelOpen);
  };

  const fetchSubjectData = async () => {
    const response = await getAllSubject();
    setFormData((prevstate) => ({ ...prevstate, subject: response.data }));
  };

  useEffect(() => {
    fetchData();
    fetchSubjectData();
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    await getCategoryWiseSubcategory("65524e353c005ef3b51907c2")
      .then((data) => {
        setCategories(data.subCategory);
      })
      .catch((err) => console.log(err));
  };

  const fetchQuestionPricingData = async (id) => {
    const response = await getSubcategoryWisePrice(id);
    setPayload((prevstate) => ({
      ...prevstate,
      price: response?.price?.studentPrice,
    }));
  };

  const handleUploadFile = async (files, name) => {
    const uploaded = payload[name] ? [...payload[name]] : [];

    files.some((file) => {
      if (uploaded.findIndex((f) => f.name === file.name) === -1) {
        uploaded.push(file);
      }
    });
    if (uploaded.length === 0) {
      setIsError(true);
    } else {
      setIsError(false);
    }
    return uploaded;
  };

  const getLiveSessionPrice = async () => {
    const response = await getPriceLivehelp({
      durationMintues: payload.durationMintues,
    });
    if (response.isSuccess) {
      setFormData((prevstate) => ({
        ...prevstate,
        studentPrice: response?.price?.studentPrice,
      }));
    }
  };

  useEffect(() => {
    if (payload.durationMintues) {
      getLiveSessionPrice();
    }
  }, [payload.durationMintues]);

  const handlePostQuestion = async () => {
    const response = await createQuestionLivesessionHelp(payload);

    if (response.isSuccess) {
      if (localStorage.getItem("token")) {
        const updatedQuestion = await updateLiveSessionQuestion(
          response.question._id,
          {
            discountPrice: 0,
            promoPrice: 0,
            promoCode: "",
          }
        );
        if (updatedQuestion.isSuccess) {
          localStorage.removeItem("questionId");
          navigate(`/orderStatus/${response.question._id}`);
        }
      }
      setIsQuestionSubmited(true);
      localStorage.setItem("questionId", response.question._id);
    }
  };

  useEffect(() => {
    if (payload.questionType) {
      fetchQuestionPricingData(payload.questionType);
    }
  }, [payload.questionType]);

  return (
    <>
      <Page subCategoryId={subCategoryId} categoryId={categoryId} />
      <Nav />
      <Navbar />
      <div className="row mt-4 mb-4 px-4 m-0">
        <div className="col-lg-12">
          <div className="breadcrumb-inner text-start">
            <ul className="page-list">
              <li className="rbt-breadcrumb-item">
                <Link to="/">Home</Link>
              </li>
              <li>
                <div className="icon-right">
                  <MdOutlineKeyboardArrowRight />
                </div>
              </li>
              <li className="rbt-breadcrumb-item">Live Session Help</li>
              <li>
                <div className="icon-right">
                  <MdOutlineKeyboardArrowRight />
                </div>
              </li>
              <li className="rbt-breadcrumb-item active">1-on-1 Chat Help</li>
            </ul>
          </div>
        </div>
      </div>
      <MainBanner id={subCategoryId} setIsModelOpen={setIsModelOpen} />
      <AskSection
        onSignUp={() => {
          setIsModelOpen(true);
          setIsQuestionSubmited(true);
        }}
      />
      <HelpService
        helpServiceTitle
        subCategoryID={subCategoryId}
      />
      <div className="mt-4">
        <HelpSteps subCategoryId={subCategoryId} />
      </div>
      <Milestonerewards categoryId={categoryId} />
      <BonusSection
        subCategoryId={subCategoryId}
        onClick={() => {
          setIsModelOpen(true);
        }}
      />
      <Universities categoryId={categoryId} />
      <StudentStories />
      <ComWorldWide id={subCategoryId} />
      <ComReadMoreSection id={subCategoryId}/>
      <Footer />
      {isModelOpen ? (
        <CommonForm
          isOpen={isModelOpen}
          toggle={toggle}
          typeData={{
            serviceType: "LiveSessionHelp",
            type: "livesessionHelp",
            totalSteps: 4,
          }}
          subCategory={subCategoryId}
        />
      ) : (
        <></>
      )}
      <ScrollTop />
    </>
  );
};

export default LiveSessionChatHelp;
