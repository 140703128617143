import React from "react";
import { Container, FormGroup, Row } from "reactstrap";
import Select from "react-select";

const HomeWorkForm2 = ({
  payload,
  handleChange,
  selectedType,
  selectedSubject,
  formData,
  error,
  isError,
}) => {
  const subjectOptions = formData?.subject?.map((data) => ({
    value: data._id,
    label: data.questionSubject,
  }));

  const categoryOptions = formData?.subcategory?.map((data) => ({
    value: data._id,
    label: data.name,
  }));
  return (
    <Container>
      <h1 className="step-header text-center">Select Your Subject</h1>
      <Row>
        <FormGroup>
          <Select
            placeholder="Select your subject"
            value={subjectOptions?.find((x) => x.value === payload?.subject) || subjectOptions?.find((x) => x.value === selectedSubject?.value)}
            onChange={(event) =>
              handleChange({
                target: {
                  name: "subject",
                  value: event.value,
                },
              })
            }
            name="subject"
            className="question-select mt-5 select-subject"
            options={subjectOptions}
          />
          {isError && error.subject && (
            <span className="text-danger">{error.subject}</span>
          )}
        </FormGroup>
        <FormGroup>
          <Select
            placeholder="Select your question type"
            value={categoryOptions.find((x) => x.value === payload?.questionType) || categoryOptions.find((x) => x.value === selectedType?.value)}
            onChange={(event) =>
              handleChange({
                target: {
                  name: "questionType",
                  value: event.value,
                },
              })
            }
            name="questionType"
            className="question-select mt-1 select-subject"
            options={categoryOptions}
          />
          {isError && error.questionType && (
            <span className="text-danger">{error.questionType}</span>
          )}
        </FormGroup>
      </Row>
    </Container>
  );
};

export default HomeWorkForm2;
