import axiosInstance from "../axiosInterceptor";

export async function createQuestionHomeworkHelp(body) {
  const payload = {
    subjectId: body.subject,
    question: body.question,
    subCategoryId: body.questionType,
    categoryId: "65524e5e3c005ef3b51907c6",
  };
  const response = await axiosInstance.post(
    `/student/homeworkHelp/createQuestion`,
    payload
  );
  const data = response?.data;
  return data;
}
