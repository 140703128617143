import axiosInstance from "../axiosInterceptor";

export async function getAssignmentQuestionById(questionId) {
  try {
    const response = await axiosInstance.get(
      `/student/assignmentHelp/getQuestionDetailByID/${questionId}`
    );
    const data = response?.data;
    return data;
  } catch (error) {
    console.log(error);
  }
}
