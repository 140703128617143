import "bootstrap/dist/css/bootstrap.min.css";
import "./App.scss";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import HomePage from "./Pages/HomePage/HomePage";
import MCQService from "./Pages/homework-help/MCQService/index";
import TrueFalseService from "./Pages/homework-help/TrueFalseService/index";
import FillupsService from "./Pages/homework-help/FillUps";
import ShortAnswerService from "./Pages/homework-help/ShortAnswerService";
import MatchTheFollowing from "./Pages/homework-help/MatchTheFollowing";
import Definition from "./Pages/homework-help/definition";
import AssignmentWriting from "./Pages/assignment-help/AssignmentWriting";
import ProjectLabReportWriting from "./Pages/assignment-help/ProjectLabReportWriting";
import EssayWriting from "./Pages/assignment-help/EssayWriting";
import PresentationWriting from "./Pages/assignment-help/PresentationWriting";
import ArticleWriting from "./Pages/assignment-help/ArticleWriting";
import SpeechWriting from "./Pages/assignment-help/SpeechWriting";
import LiveSessionChatHelp from "./Pages/live-session-help/1-to-1-chat-help";
import LiveSessionVideoHelp from "./Pages/live-session-help/1-to-1-video-help";
import { Suspense, useEffect, useState } from "react";
import OrderHistory from "./Components/OrderStatusAndBilling/OrderHistory";
import BillingAddress from "./Components/OrderStatusAndBilling/BillingAddress";
import CheckOut from "./Components/OrderStatusAndBilling/CheckOut";
import { loadStripe } from "@stripe/stripe-js";
import Completion from "./Components/OrderStatusAndBilling/Completion";
import Dashboard from "./Pages/dashboard/Dashboard";
import DashboardHome from "./Pages/dashboard/DashboardHome";
import DashboardOrderHistory from "./Pages/dashboard/DashboardOrderHistory";
import DashboardQuestions from "./Pages/dashboard/DashboardQuestions";
import DashboardAnswer from "./Pages/dashboard/DashboardAnswer";
import Dashboardview from "./Pages/dashboard/Dashboardview";
import DashboardLibrary from "./Pages/dashboard/DashboardLibrary";
import DashboardFaq from "./Pages/dashboard/DashboardFaq";
import DashboardContactUs from "./Pages/dashboard/DashboardContactUs";
import { checkToken } from "./services/checkToken";
import { isLoggedIn } from "./hooks/useIsLoggedIn";
import OopsError from "./Components/OrderStatusAndBilling/OopsError";
import { CategoryContext } from "./categoryContext";
import Search from "./Pages/search/Search";
import Refundpolicy from "./Pages/Staticpages/Refundpolicy";
import Faq from "./Pages/Staticpages/Faq";
import Authentication from "./Pages/auth/Auth";
import Earnmoney from "./Pages/dashboard/Earnmoney";
import PersonalSetting from "./Pages/dashboard/PersonalSetting";
import Dashboardviewlive from "./Pages/dashboard/Dashboardviewlive";
import ViewLibraryAnswer from "./Pages/search/ViewLibraryAnswer";
import Libraryviewabswer from "./Pages/dashboard/Libraryviewabswer";
import Razorpay from "./Components/OrderStatusAndBilling/Razorpay";
import Reviews from "./Pages/Staticpages/Reviews";
import DoubtQ from "./Assets/logo/loader dobutq.gif";
import Comingsoon from "./Pages/Staticpages/Comingsoon";
import Register from "./Pages/auth/Register";
import Login from "./Pages/auth/Login";
import SignUp from "./Components/Popup/Register";
import SignIn from "./Components/Popup/Login";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ForgotPassword from "./Pages/auth/ForgotPassword";
import Resetpassword from "./Pages/auth/Resetpassword";
import RazorpayCompletion from "./Components/OrderStatusAndBilling/RazorpayCompletion";
import Library from "./Pages/search/Library";
import Referralprogram from "./Pages/dashboard/Referralprogram";
import NotificationPopup from "./Components/NotificationPopup";

function RequireAuth({ children }) {
  const [checkFlag, setCheckFlag] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const result = await isLoggedIn();
      setCheckFlag(result);
    };

    fetchData();
  }, []);

  if (checkFlag === null) {
    return (
      <div>
        <img
          src={DoubtQ}
          alt="DoubtQ Image"
          width={100}
          height={100}
          style={{ position: "relative", top: "300px" }}
        />
      </div>
    );
  }

  if (!checkFlag) {
    return <Navigate to="/" />;
  }
  return children;
}

function CheckRoutes({ children }) {
  const [checkFlag, setCheckFlag] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      const result = await isLoggedIn();
      setCheckFlag(result);
    };

    fetchData();
  }, []);

  if (checkFlag === null) {
    return (
      <div>
        <img
          src={DoubtQ}
          alt="DoubtQ Image"
          width={100}
          height={100}
          style={{ position: "relative", top: "300px" }}
        />
      </div>
    );
  }

  if (checkFlag) {
    return <Navigate to="/dashboard/home" />;
  }
  return children;
}

function App() {
  const [categoryId, setCategoryId] = useState("6568bbce652c801ff8766635");
  function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);

    const verifyToken = async () => await checkToken();
    verifyToken();
    return null;
  }

  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);
  const razorpayPromise = loadStripe(process.env.REACT_APP_RAZORPAY_KEY_ID);

  return (
    <>
      <div className="App">
        <CategoryContext.Provider value={{ categoryId, setCategoryId }}>
          <ScrollToTop />
          <NotificationPopup />
          <Suspense
            fallback={
              <div>
                <img
                  src={DoubtQ}
                  alt="DoubtQ Image"
                  width={100}
                  height={100}
                  style={{ position: "relative", top: "300px" }}
                />
              </div>
            }
          >
            <Routes>
              <Route
                path="/"
                element={
                  <CheckRoutes>
                    <HomePage />
                  </CheckRoutes>
                }
              />
              <Route
                path="/auth"
                element={
                  <CheckRoutes>
                    <Authentication />
                  </CheckRoutes>
                }
              />

              <Route
                path="/login"
                element={
                  <CheckRoutes>
                    <Login />
                  </CheckRoutes>
                }
              />
              <Route
                path="/register"
                element={
                  <CheckRoutes>
                    <Register />
                  </CheckRoutes>
                }
              />
              <Route
                path="/register/:referral"
                element={
                  <CheckRoutes>
                    <Register />
                  </CheckRoutes>
                }
              />
              <Route
                path="/signin"
                element={
                  <CheckRoutes>
                    <SignIn />
                  </CheckRoutes>
                }
              />
              <Route
                path="/signup"
                element={
                  <CheckRoutes>
                    <SignUp />
                  </CheckRoutes>
                }
              />
              <Route
                path="/forgot-password"
                element={
                  <CheckRoutes>
                    <ForgotPassword />
                  </CheckRoutes>
                }
              />
              <Route
                path="/reset-password"
                element={
                  <CheckRoutes>
                    <Resetpassword />
                  </CheckRoutes>
                }
              />

              <Route
                path="/easy-level-question-help"
                element={
                  <CheckRoutes>
                    <MCQService />
                  </CheckRoutes>
                }
              />
              <Route
                path="/expert-level-question-help"
                element={
                  <CheckRoutes>
                    <TrueFalseService />
                  </CheckRoutes>
                }
              />
              <Route
                path="/fill-ups"
                element={
                  <CheckRoutes>
                    <FillupsService />
                  </CheckRoutes>
                }
              />
              <Route
                path="/short-question-answer"
                element={
                  <CheckRoutes>
                    <ShortAnswerService />
                  </CheckRoutes>
                }
              />
              <Route
                path="/match-the-following"
                element={
                  <CheckRoutes>
                    <MatchTheFollowing />
                  </CheckRoutes>
                }
              />
              <Route
                path="/intermediate-level-question-help"
                element={
                  <CheckRoutes>
                    <Definition />
                  </CheckRoutes>
                }
              />
              <Route
                path="/problem-solving"
                element={
                  <CheckRoutes>
                    <AssignmentWriting />
                  </CheckRoutes>
                }
              />
              <Route
                path="/project-lab-report-writing"
                element={
                  <CheckRoutes>
                    <ProjectLabReportWriting />
                  </CheckRoutes>
                }
              />
              <Route
                path="/essay-writing"
                element={
                  <CheckRoutes>
                    <EssayWriting />
                  </CheckRoutes>
                }
              />
              <Route
                path="/presentation-writing"
                element={
                  <CheckRoutes>
                    <PresentationWriting />
                  </CheckRoutes>
                }
              />
              <Route
                path="/research-dissertation-writing"
                element={
                  <CheckRoutes>
                    <ArticleWriting />
                  </CheckRoutes>
                }
              />
              <Route
                path="/summary-writing"
                element={
                  <CheckRoutes>
                    <SpeechWriting />
                  </CheckRoutes>
                }
              />
              <Route
                path="/1-1-chat"
                element={
                  <CheckRoutes>
                    <LiveSessionChatHelp />
                  </CheckRoutes>
                }
              />
              <Route
                path="/1-1-video"
                element={
                  <CheckRoutes>
                    <LiveSessionVideoHelp />
                  </CheckRoutes>
                }
              />
              <Route
                path="/search"
                element={
                  <CheckRoutes>
                    <Search />
                  </CheckRoutes>
                }
              />
              <Route
                path="/homework-help/question-and-answer/:search"
                element={
                  <CheckRoutes>
                    <ViewLibraryAnswer />
                  </CheckRoutes>
                }
              />
              <Route
                path="/:slug"
                element={
                  <CheckRoutes>
                    <Refundpolicy />
                  </CheckRoutes>
                }
              />
              <Route
                path="/reviews"
                element={
                  <CheckRoutes>
                    <Reviews />
                  </CheckRoutes>
                }
              />
              <Route
                path="/library"
                element={
                  <CheckRoutes>
                    <Library />
                  </CheckRoutes>
                }
              />
              <Route
                path="/comingsoon"
                element={
                  <CheckRoutes>
                    <Comingsoon />
                  </CheckRoutes>
                }
              />
              <Route
                path="/faqs"
                element={
                  <CheckRoutes>
                    <Faq />
                  </CheckRoutes>
                }
              />

              <Route
                path="/orderStatus/:questionId"
                element={
                  <RequireAuth>
                    <OrderHistory />
                  </RequireAuth>
                }
              />
              <Route
                path="/billingDetails/:paymentMethod/:questionId"
                element={
                  <RequireAuth>
                    <BillingAddress />
                  </RequireAuth>
                }
              />
              <Route
                path="/checkout"
                element={
                  <RequireAuth>
                    <CheckOut stripePromise={stripePromise} />
                  </RequireAuth>
                }
              />
              <Route
                path="/razorpay-checkout"
                element={
                  <RequireAuth>
                    <Razorpay razorpayPromise={razorpayPromise} />
                  </RequireAuth>
                }
              />
              <Route
                path="dashboard"
                element={
                  <RequireAuth>
                    <Dashboard />
                  </RequireAuth>
                }
              >
                <Route path="completion" element={<Completion />} />
                <Route
                  path="razorpay-completion"
                  element={<RazorpayCompletion />}
                />
                <Route path="failed" element={<OopsError />} />
                <Route
                  path="home"
                  index
                  element={
                    <RequireAuth>
                      <DashboardHome />
                    </RequireAuth>
                  }
                />
                <Route
                  path="order-history"
                  element={
                    <RequireAuth>
                      <DashboardOrderHistory />
                    </RequireAuth>
                  }
                />
                <Route
                  path="questions"
                  element={
                    <RequireAuth>
                      <DashboardQuestions />
                    </RequireAuth>
                  }
                />
                <Route
                  path="answer"
                  element={
                    <RequireAuth>
                      <DashboardAnswer />
                    </RequireAuth>
                  }
                />
                <Route
                  path="earnmoney"
                  element={
                    <RequireAuth>
                      <Earnmoney />
                    </RequireAuth>
                  }
                />
                <Route
                  path="personal-info"
                  element={
                    <RequireAuth>
                      <PersonalSetting />
                    </RequireAuth>
                  }
                />
                <Route
                  path="referral-program"
                  element={
                    <RequireAuth>
                      <Referralprogram />
                    </RequireAuth>
                  }
                />
                <Route
                  path="viewanswer/:questionId"
                  element={
                    <RequireAuth>
                      <Dashboardview />
                    </RequireAuth>
                  }
                />
                <Route
                  path="viewanswer-live/:questionId"
                  element={
                    <RequireAuth>
                      <Dashboardviewlive />
                    </RequireAuth>
                  }
                />
                <Route
                  path="library"
                  element={
                    <RequireAuth>
                      <DashboardLibrary />
                    </RequireAuth>
                  }
                />
                <Route
                  path="library/question-and-answer/:search"
                  element={
                    <RequireAuth>
                      <Libraryviewabswer />
                    </RequireAuth>
                  }
                />
                <Route
                  path="faq"
                  element={
                    <RequireAuth>
                      <DashboardFaq />
                    </RequireAuth>
                  }
                />
                <Route
                  path="contact-us"
                  element={
                    <RequireAuth>
                      <DashboardContactUs />
                    </RequireAuth>
                  }
                />
              </Route>
            </Routes>
          </Suspense>
        </CategoryContext.Provider>
      </div>
      <ToastContainer autoClose={1000} theme="colored" />
    </>
  );
}

export default App;
