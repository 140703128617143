import axiosInstance from "../axiosInterceptor";

export async function myquestions(search, pageNo, perPage, subCategoryId, fromDate, toDate) {
    const response = await axiosInstance.post(
        `student/myQuestions`,
        {
            search,
            pageNo,
            perPage,
            subCategoryId,
            fromDate,
            toDate
        }
    );
    const data = response?.data;
    return data;
}