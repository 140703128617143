import React, { useState, useEffect, useRef, useMemo } from "react";
import { FiCalendar } from "react-icons/fi";
import DateItem from "./dateItem";
import DateInputPopup from "./dateInputPopup";

import formatDate from "../../../utils/js/formatDate";
import getDaysInMonth from "../../../utils/js/getDaysInMonth";
import { monthNamesWithShortNames } from "../../../utils/js/constraints";
import { createPortal } from "react-dom";

const getDateSlots = (currentMonth, currentYear) => {
  const dateArray = getDaysInMonth(currentMonth, currentYear);
  const slotSkipCount = new Date(dateArray[0]).getDay();

  Array.from({ length: slotSkipCount }).forEach(() => {
    dateArray.unshift(null);
  });

  return dateArray;
};

const DateInput = ({
  type,
  value,
  onChange,
  className = "",
  disabled = false,
  showPopup,
  setShowPopup,
  placeHolder = "",
}) => {
  const popupRef = useRef();
  const anchorEl = useRef(null);
  const datePickerRef = useRef();
  const [dropdownStyle, setDropdownStyle] = useState({});
  const [showSubPopup, setShowSubPopup] = useState(false);
  const [selectedDate, setSelectedDate] = useState("");
  const [currentDate, setCurrentDate] = useState(
    `${new Date().getFullYear()}-${
      new Date().getMonth() + 1
    }-${new Date().getDate()}`
  );
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth());
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [toYear, setToYear] = useState(new Date().getFullYear());
  const [toMonth, setToMonth] = useState(new Date().getMonth());
  const [toYearMonth, setToYearMonth] = useState(new Date().getFullYear());
  const [monthYearToggle, setMonthYearToggle] = useState(false);

  const calculatePosition = () => {
    const rect = popupRef?.current?.getBoundingClientRect();
    const windowHeight = window.innerHeight;
    const windowWidth = window.innerWidth;
    if (popupRef.current) {
      if (windowHeight - rect.bottom <= 0) {
        datePickerRef.current.style.top = `unset`;
        datePickerRef.current.style.bottom = `100%`;
      } else if (rect.top <= 0) {
        datePickerRef.current.style.top = `100%`;
        datePickerRef.current.style.bottom = `unset`;
      }
      if (windowWidth - rect.right <= 0) {
        datePickerRef.current.style.left = "unset";
        datePickerRef.current.style.right = "unset";
      } else if (rect.left <= 0) {
        datePickerRef.current.style.left = "unset";
        datePickerRef.current.style.right = "unset";
      }
    }
  };

  useEffect(() => {
    if (popupRef.current && showPopup) {
      const anchorRect = popupRef?.current?.getBoundingClientRect();
      setDropdownStyle({
        top: anchorEl.current ? anchorRect.bottom + window.scrollY : 0,
        left: anchorEl.current ? anchorRect.left + window.scrollX : 0,
        width: anchorEl.current ? anchorRect.width : 0,
      });
    }
  }, [popupRef, showPopup]);

  const generateClassName = () => {
    return `d-flex justify-content-between align-items-center rounded-3 bg-secondary date-input ${className}`;
  };

  const dateArray = useMemo(
    () => getDateSlots(currentMonth, currentYear),
    [currentMonth, currentYear]
  );

  useEffect(() => {
    if (!monthYearToggle) {
      if (value) {
        const dateObj = new Date(value);

        setSelectedDate(formatDate(dateObj));
        if (type === "monthYearRange") {
          setCurrentMonth(monthNamesWithShortNames[currentMonth]?.fullMonth);
          setToMonth(monthNamesWithShortNames[toMonth]?.fullMonth);
        } else {
          setCurrentMonth(dateObj.getMonth());
        }
        setCurrentYear(dateObj.getFullYear());
      } else {
        setSelectedDate("");
        if (type === "monthYearRange") {
          setCurrentMonth(
            monthNamesWithShortNames.find(
              (i, ind) => ind === new Date().getMonth()
            ).fullMonth
          );
          setToMonth(
            monthNamesWithShortNames.find(
              (i, ind) => ind === new Date().getMonth()
            ).fullMonth
          );
        } else {
          setCurrentMonth(new Date().getMonth());
        }
        setCurrentYear(new Date().getFullYear());
      }

      document.addEventListener("click", () => {
        setShowPopup(false);
        setShowSubPopup(false);
      });
    }
  }, [value]);

  useEffect(() => {
    if (type === "yearRange") {
      setMonthYearToggle(true);
      if (showPopup) {
        onChange({ date: `${currentYear} - ${toYear}` });
      }
      setSelectedDate(`${currentYear} - ${toYear}`);
    }
    if (type === "monthYearRange") {
      setMonthYearToggle(true);
      if (showPopup) {
        onChange({
          date: `${currentMonth} ${currentYear} - ${toMonth} ${toYearMonth}`,
        });
      }
      setSelectedDate(
        `${currentMonth} ${currentYear} - ${toMonth} ${toYearMonth} `
      );
    }
  }, [currentYear, toYear, toMonth, toYearMonth, currentMonth]);

  const togglePopupHandler = (e) => {
    e.stopPropagation();
    setShowPopup((currentShowPopup) => {
      return !currentShowPopup;
    });
    if (showPopup) {
      setShowSubPopup(false);
    }
  };

  const toggleSubPopupHandler = () => {
    setShowSubPopup((currentShowPopup) => {
      return !currentShowPopup;
    });
  };

  const navigateMonthHandler = (navigateBy = 1) => {
    if (type === "monthYearRange") {
      const month = monthNamesWithShortNames.findIndex(
        (i) => i.fullMonth === currentMonth
      );
      if (month + navigateBy === 12) {
        setCurrentMonth(monthNamesWithShortNames[0].fullMonth);
        setCurrentYear((currentState) => {
          return currentState + 1;
        });
      } else if (month + navigateBy === -1) {
        setCurrentMonth(monthNamesWithShortNames[11].fullMonth);
        setCurrentYear((currentState) => {
          return currentState - 1;
        });
      } else {
        setCurrentMonth(
          monthNamesWithShortNames[
            monthNamesWithShortNames.findIndex(
              (i) => i.fullMonth === currentMonth
            ) + navigateBy
          ]?.fullMonth
        );
      }
    }
    if (type !== "monthYearRange") {
      if (currentMonth + navigateBy === 12) {
        setCurrentMonth(0);
        setCurrentYear((currentState) => {
          return currentState + 1;
        });
      } else if (currentMonth + navigateBy === -1) {
        setCurrentMonth(11);
        setCurrentYear((currentState) => {
          return currentState - 1;
        });
      } else {
        if (new Date().getMonth() > currentMonth + navigateBy) {
        } else {
          setCurrentMonth((currentState) => {
            return currentState + navigateBy;
          });
        }
      }
    }
  };

  const handleToMonthNavigate = (navigateBy = 1) => {
    if (type === "monthYearRange") {
      const month = monthNamesWithShortNames.findIndex(
        (i) => i.fullMonth === toMonth
      );
      if (month + navigateBy === 12) {
        setToMonth(monthNamesWithShortNames[0].fullMonth);
        setToYearMonth((currentState) => {
          return currentState + 1;
        });
      } else if (month + navigateBy === -1) {
        setToMonth(monthNamesWithShortNames[11].fullMonth);
        setToYearMonth((currentState) => {
          return currentState - 1;
        });
      } else {
        setToMonth(
          monthNamesWithShortNames[
            monthNamesWithShortNames.findIndex((i) => i.fullMonth === toMonth) +
              navigateBy
          ]?.fullMonth
        );
      }
    }
  };

  const selectDateHandler = (date) => {
    onChange({ date: new Date(date) });
    setSelectedDate(date);
    setCurrentDate(date);
    setShowPopup(false);
    setShowSubPopup(false);
  };

  const handleClear = () => {
    setSelectedDate("");
    onChange({ date: null });
  };

  return (
    <div
      ref={popupRef}
      className="position-relative"
      role="presentation"
      onClick={(e) => e.stopPropagation()}
    >
      <div
        role="presentation"
        onClick={togglePopupHandler}
        className={generateClassName() + " dateWrapper"}
      >
        <input
          type="text"
          disabled={disabled}
          value={type === "single" ? selectedDate : value}
          className="w-100 border-0 bg-secondary fs-6"
          readOnly
          placeholder={"Select Date"}
        />
        <span>
          <FiCalendar />
        </span>
      </div>

      {type === "single" && (
        <div ref={anchorEl}>
          {showPopup &&
            !disabled &&
            createPortal(
              <div
                className="position-absolute"
                style={{ ...dropdownStyle, zIndex: 9999 }}
                onClick={(e) => e.stopPropagation()}
              >
                <DateInputPopup
                  ref={datePickerRef}
                  handleClear={handleClear}
                  setSelectedDate={setSelectedDate}
                  currentMonth={currentMonth}
                  toggleSubPopupHandler={toggleSubPopupHandler}
                  setCurrentMonth={(e) => setCurrentMonth(e.target.value)}
                  setCurrentYear={(e) => setCurrentYear(e.target.value)}
                  currentYear={currentYear}
                  navigateMonth={navigateMonthHandler}
                >
                  {dateArray.map((dateText, uniquekey = 1) => {
                    return (
                      <DateItem
                        key={uniquekey + 1}
                        dateObj={dateText ? new Date(dateText) : null}
                        selected={currentDate === dateText}
                        currentMonth={currentMonth}
                        onClick={() => selectDateHandler(dateText)}
                      />
                    );
                  })}
                  {showSubPopup && (
                    <DateInputPopup
                      currentMonth={currentMonth}
                      setCurrentMonth={setCurrentMonth}
                      setCurrentYear={setCurrentYear}
                      currentYear={currentYear}
                      showSubPopup={showSubPopup}
                      navigateMonth={navigateMonthHandler}
                      toggleSubPopupHandler={toggleSubPopupHandler}
                    />
                  )}
                </DateInputPopup>
              </div>,
              document.body
            )}
        </div>
      )}
    </div>
  );
};

export default DateInput;
