import axiosInstance from "../axiosInterceptor";

export async function getAllHelpSteps(body) {
  const response = await axiosInstance.post(
    `admin/completeHelpStep/getAllHelpStepByID`,
    body
  );
  const data = response?.data;
  return data;
}
