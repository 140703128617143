import React from "react";
import {
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap";
import stripeTrust from "./../../../Assets/images/stripeTrust.png";

const Liveform4 = ({
  payload,
  formData,
}) => {
  return (
    <Container>
      <Row className="justify-content-center align-items-center flex-column mt-5 ">
        <Card className="w-50 ">
          <CardBody>
            <CardTitle tag="h5" className="pb-2">
              <h1 className="step-header text-center pb-1">Exam Preview</h1>
            </CardTitle>
            <CardText>
              <Row className="mb-2">
                <Col className="d-flex">Subject</Col>
                <Col className="d-flex justify-content-end fw-bold">
                  {
                    formData.subject?.find((x) => x._id === payload.subject)
                      ?.questionSubject
                  }
                </Col>
              </Row>
              <Row className="mb-2">
                <Col className="d-flex">Start Date & Time</Col>
                <Col className="d-flex justify-content-end fw-bold">
                  {new Date(payload.date).toLocaleDateString("en-GB", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                  })} | {payload.hours}:{payload.minutes} {payload.meradian}
                </Col>
              </Row>
              <Row className="mb-2">
                <Col className="d-flex">Duration</Col>
                <Col className="d-flex justify-content-end fw-bold">
                  {payload.durationMintues} Minutes
                </Col>
              </Row>
              <Row className="mb-2">
                <Col className="d-flex">Time zone</Col>
                <Col className="d-flex justify-content-end fw-bold">
                  {typeof payload?.timeZone === "object"
                    ? payload.timeZone.label
                    : payload.timeZone}
                </Col>
              </Row>
              <Row className="pt-5 mt-5">
                <hr />
              </Row>
              <Row>
                <Col className="d-flex">Payable Amount</Col>
                <Col className="d-flex justify-content-end">
                  <h3>{formData.studentPrice?.toFixed(2)} USD</h3>
                </Col>
              </Row>
            </CardText>
          </CardBody>
        </Card>
        <Row className="mt-5 text-center">
          <Col sm="12" className="w-100">
            <img src={stripeTrust} className="img-fluid" alt="Responsive" />
          </Col>
        </Row>
      </Row>
    </Container>
  );
};

export default Liveform4;
