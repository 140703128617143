import axiosInstance from "../axiosInterceptor";

export async function searchQuestion(search, pageNo, perPage, subjectId) {
  const response = await axiosInstance.post(`student/searchQuestion`, {
    search: search,
    pageNo: pageNo,
    perPage: perPage,
    subjectId: subjectId,
  });
  const data = response?.data;
  return data;
}
