export const PopupTemplate = {
  TEMPLATE_COMMON: "all",
  TEMPLATE_MCQ: "mcqHelp",
  TEMPLATE_ASSIGNMENT: "assignmentHelp",
  TEMPLATE_LIVESESSION: "livesessionHelp",
};

export const ImageLink = (name) => {
  if (name) {
    const imageUrl = process.env.REACT_APP_FILE_BASE_URL
    return `${imageUrl}${name}`;
  }
};
