import React, { useState } from "react";
import { GrLinkTop } from "react-icons/gr";
import "./ScrollTop.scss";

const ScrollTop = () => {
  const [isVisible, setIsVisible] = useState(false);

  window.addEventListener("scroll", (e) => {
    window.scrollY > 150 ? setIsVisible(true) : setIsVisible(false);
  });

  return (
    <button
      className="scrollTop-button"
      style={{ display: isVisible ? "block" : "none", zIndex: '9999' }}
      onClick={() =>
        window.scroll({
          top: 0,
          left: 0,
          behavior: "smooth",
        })
      }
    >
      <GrLinkTop />
    </button>
  );
};

export default ScrollTop;
