import React from "react";
import "./AskSection.scss";
import Button from "./Buttons/Button";
import { Link } from "react-router-dom";
const AskSection = () => {
  return (
    <div className="askSection">
      <div className="askSectionWrapper">
        <div className="contentWrapper">
          <span className="bigLabel">Ask, Learn, Repeat</span>
          <span className="smallLabel">Your Ticket to Endless Knowledge</span>
        </div>
        <div className="content-seprator"></div>
        <div className="buttonWrapper">
          <Link to="/register">
            <Button text={"Sign Up"} varient="secondary" />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default AskSection;
