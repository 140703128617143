import React, { useEffect, useState } from "react";
import "./ReadMoreSection.scss";
import axiosInstance from "../axiosInterceptor";

const ReadMoreSection = () => {
  const [setting, setSetting] = useState([]);
  const [isExpanded, setIsExpanded] = useState(false);
  const [textToShow, setTextToShow] = useState("");

  const fetchData = async () => {
    try {
      const res = await axiosInstance.get(`/admin/setting/getSetting`);
      setSetting(res.data.data);
      const academicHelpText = res.data.data.academicHelp || "";
      const truncatedText = truncateText(academicHelpText, 100);
      setTextToShow(truncatedText);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const truncateText = (text, wordLimit) => {
    const words = text.split(" ");
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(" ") + "...";
    }
    return text;
  };

  const handleReadMoreToggle = () => {
    setIsExpanded(!isExpanded);
    if (!isExpanded) {
      setTextToShow(setting.academicHelp);
    } else {
      setTextToShow(truncateText(setting.academicHelp, 100));
    }
  };

  return (
    <div className="read-more-section-container">
      <div className="read-more-section-description">
        <p dangerouslySetInnerHTML={{ __html: textToShow }} />
        {setting?.academicHelp?.split(" ").length > 100 && (
          <button
            onClick={handleReadMoreToggle}
            className="rbt-btn btn-gradient btn-sm mt-4"
          >
            {isExpanded ? "Read Less" : "Read More"}
          </button>
        )}
      </div>
    </div>
  );
};

export default ReadMoreSection;
