import React from 'react';
import "./ReferralProgram.scss";
import referral from "../Assets/images/New/referral-program.png";
import Button from "./Buttons/Button";
import Layer from "../Assets/images/New/Layer.png";

const ReferralProgram = ({ onClick = () => { } }) => {
    return (
        <section className="first-section mt-4">
            <div>
                <h2 className="title">Get Rewarded With Our Referral Program</h2>
            </div>
            <div className="w-full">
                <div className="d-flex flex-column flex-md-row justify-content-center align-items-center my-5 reward-box">
                    <div className="reward-img-box mx-5">
                        <img src={referral} className="reward-img" />
                    </div>
                    <div className="d-flex align-items-center mt-4 mt-md-0">
                        <div className="mx-4">
                            <img src={Layer} className="layer-img" />
                        </div>
                        <div className="d-flex flex-column justify-content-even layout-info">
                            <div className="line-height">
                                <h5 className="title-second">Create Account</h5>
                                <p className="title-third" style={{ lineHeight: '20px', fontSize: '1.5rem' }}>Register on the platform.</p>
                            </div>
                            <div className="line-height pt-4">
                                <h5 className="title-second">Get Referral Code</h5>
                                <p className="title-third" style={{ lineHeight: '20px', fontSize: '1.5rem' }}>
                                    Obtain your referral code <br />
                                    from dashboard.
                                </p>
                            </div>
                            <div className="line-height">
                                <h5 className="title-second">Share Referral Link</h5>
                                <p className="title-third" style={{ lineHeight: '20px', fontSize: '1.5rem' }}>
                                    Share your referral code with <br />
                                    friends to earn rewards!
                                </p>
                            </div>
                            <div className="line-height">
                                <h5 className="title-second">Earn Rewards</h5>
                                <p className="title-third" style={{ lineHeight: '20px', fontSize: '1.5rem' }}>
                                    You will get rewarded for every <br />
                                    successful referral.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Button text={"See More Details"} varient="primary" onClick={onClick} />
        </section>

    );
}

export default ReferralProgram;
