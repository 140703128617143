import React from "react";
import logo from "../../Assets/logo/logo.png";
import "./LogoHeader.scss";
import { useNavigate } from "react-router-dom";
const LogoHeader = () => {
  const navigate = useNavigate();
  return (
    <div className="logo-header">
      <img src={logo} onClick={() => navigate("/dashboard/home")} />
    </div>
  );
};

export default LogoHeader;
