import axiosInstance from "../axiosInterceptor";

export async function updateAssignmentQuestion(questionId, body) {
  const response = await axiosInstance.put(
    `student/assignmentHelp/updateQuestion/${questionId}`,
    body
  );
  const data = response?.data;
  return data;
}
