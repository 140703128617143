import React from "react";
import "./CategoryCard.scss";
import Button from "../Buttons/Button";
import fallbackImg from "../../Assets/icons/cardFallback.svg";
import { ImageLink } from "../../constants";
import { useNavigate } from "react-router-dom";

const CategoryCard = ({ details, className = "" }) => {
  const navigate = useNavigate();
  const handleNavigate = (path = "/") => {
    navigate(path);
  };
  return (
    <div className={`helpCard ${className}`}>
      <div className="label">{details.name}</div>
      <div className="message">{details.description}</div>
      <div className="container">
        <img
          src={details.image?.length ? ImageLink(details.image) : fallbackImg}
        ></img>
        <Button
          text="Explore"
          varient="outlined"
          onClick={() => handleNavigate(details.path)}
        ></Button>
      </div>
    </div>
  );
};

export default CategoryCard;
