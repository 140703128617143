// ** React Imports ** //
import React from "react";

const DateItem = ({ dateObj, selected, onClick, currentMonth }) => {
  if (!dateObj) {
    return <span className="empty-date-item" />;
  }

  const displayDate = dateObj.getDate();
  let isDisabled = false;
  if (
    new Date().getMonth() === currentMonth &&
    displayDate < new Date().getDate()
  ) {
    isDisabled = true;
  }
  return (
    <button
      type="button"
      onClick={onClick}
      disabled={isDisabled}
      className={`date-item ${selected ? "bg-primary text-light" : ""}`}
    >
      <small>{displayDate}</small>
    </button>
  );
};

export default DateItem;
