import React, { lazy, Suspense } from "react";
import { PopupTemplate } from "../../constants";
import "./FormUI.scss";
import "react-quill/dist/quill.snow.css";
import HomeWorkForm1 from "../../Pages/homework-help/Form/HomeWorkForm1";
import HomeWorkForm2 from "../../Pages/homework-help/Form/HomeWorkForm2";
import HomeWorkForm3 from "../../Pages/homework-help/Form/HomeWorkForm3";
import LiveForm1 from "../../Pages/live-session-help/Form/LiveForm1";
import LiveForm2 from "../../Pages/live-session-help/Form/LiveForm2";
import AssignmentForm0 from "../../Pages/assignment-help/Form/AssignmentForm0";
import LiveForm0 from "../../Pages/live-session-help/Form/LiveForm0";
import LiveForm4 from "../../Pages/live-session-help/Form/LiveForm4";
import CommonForm from "../../Pages/HomePage/Form/CommonForm";

const AssignmentForm1 = lazy(() =>
  import("../../Pages/assignment-help/Form/AssignmentForm1")
);
const AssignmentFrom2 = lazy(() =>
  import("../../Pages/assignment-help/Form/AssignmentFrom2")
);
const AssignmentForm3 = lazy(() =>
  import("../../Pages/assignment-help/Form/AssignmentForm3")
);

const renderSwitch = (
  type,
  index,
  payload,
  handleChange,
  formData,
  rest,
  texteditorRef,
  modules,
  formats,
  handlePaste,
  error,
  isError,
  setIsError,
  question,
  selectedType,
  selectedSubject
) => {

  switch (type) {
    case PopupTemplate.TEMPLATE_COMMON:
      <CommonForm />;
    case PopupTemplate.TEMPLATE_MCQ:
      if (index === 1) {
        return (
          <HomeWorkForm1
            texteditorRef={texteditorRef}
            payload={payload}
            modules={modules}
            formats={formats}
            question={question}
            handlePaste={handlePaste}
            handleChange={handleChange}
            error={error}
            isError={isError}
            setIsError={setIsError}
          />
        );
      }
      if (index === 2) {
        return (
          <HomeWorkForm2
            texteditorRef={texteditorRef}
            payload={payload}
            modules={modules}
            formats={formats}
            question={question}
            selectedSubject={selectedSubject}
            selectedType={selectedType}
            handlePaste={handlePaste}
            handleChange={handleChange}
            formData={formData}
            error={error}
            isError={isError}
            setIsError={setIsError}
          />
        );
      }
      if (index === 3) {
        return (
          <HomeWorkForm3
            texteditorRef={texteditorRef}
            payload={payload}
            modules={modules}
            formats={formats}
            question={question}
            handlePaste={handlePaste}
            handleChange={handleChange}
            formData={formData}
            selectedSubject={selectedSubject}
            selectedType={selectedType}
          />
        );
      }
    case PopupTemplate.TEMPLATE_ASSIGNMENT:
      if (index === 1) {
        return (
          <AssignmentForm0
            payload={payload}
            rest={rest}
            error={error}
            isError={isError}
            setIsError={setIsError}
          />
        );
      }
      if (index === 2) {
        return (
          <Suspense fallback={<div>Loading ...</div>}>
            <AssignmentForm1
              texteditorRef={texteditorRef}
              payload={payload}
              modules={modules}
              formats={formats}
              handlePaste={handlePaste}
              handleChange={handleChange}
              formData={formData}
              rest={rest}
              error={error}
              isError={isError}
              setIsError={setIsError}
            />
          </Suspense>
        );
      }
      if (index === 3) {
        return (
          <Suspense fallback={<div>Loading ...</div>}>
            <AssignmentFrom2
              payload={payload}
              handleChange={handleChange}
              formData={formData}
              error={error}
              isError={isError}
              setIsError={setIsError}
            />
          </Suspense>
        );
      }
      if (index === 4) {
        return (
          <Suspense fallback={<div>Loading ...</div>}>
            <AssignmentForm3
              payload={payload}
              handleChange={handleChange}
              formData={formData}
              error={error}
              isError={isError}
              setIsError={setIsError}
            />
          </Suspense>
        );
      }
    case PopupTemplate.TEMPLATE_LIVESESSION:
      if (index === 1) {
        return (
          <LiveForm0
            payload={payload}
            handleChange={handleChange}
            formData={formData}
            rest={rest}
            error={error}
            isError={isError}
            setIsError={setIsError}
          />
        );
      }
      if (index === 2) {
        return (
          <Suspense fallback={<div>Loading ...</div>}>
            <LiveForm1
              payload={payload}
              handleChange={handleChange}
              formData={formData}
              rest={rest}
              error={error}
              isError={isError}
              setIsError={setIsError}
            />
          </Suspense>
        );
      }
      if (index === 3) {
        return (
          <Suspense fallback={<div>Loading ...</div>}>
            <LiveForm2
              payload={payload}
              handleChange={handleChange}
              formData={formData}
              rest={rest}
              error={error}
              isError={isError}
              setIsError={setIsError}
            />
          </Suspense>
        );
      }
      if (index === 4) {
        return (
          <Suspense fallback={<div>Loading ...</div>}>
            <LiveForm4
              payload={payload}
              handleChange={handleChange}
              formData={formData}
              rest={rest}
              error={error}
              isError={isError}
              setIsError={setIsError}
            />
          </Suspense>
        );
      }
    default:
      break;
  }
};
const FormUI = (
  type,
  index = 1,
  payload,
  handleChange,
  formData,
  rest,
  quillfRef,
  error,
  isError,
  setIsError,
  question1,
  selectedType,
  selectedSubject
) => {
  const handlePaste = (event) => {
    const clipboardData = event.clipboardData || window.clipboardData;
    const items = clipboardData.items;

    for (let i = 0; i < items.length; i++) {
      if (items[i].type.indexOf("image") !== -1) {
        const imageData = items[i].getAsFile();
        handleImagePaste(imageData);
        break;
      }
    }
  };

  const handleImagePaste = (imageData) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const base64Image = e.target.result;
      insertImage(base64Image);
    };
    reader.readAsDataURL(imageData);
  };

  const insertImage = (base64Image) => {
    const range = quillfRef.getEditor().getSelection();
    quillfRef
      .getEditor()
      .insertEmbed(range ? range.index : 0, "image", base64Image, "user");
  };

  const modules = {
    toolbar: {
      container: [
        [{ header: [2, 3, 4, false] }],
        ["bold", "italic", "underline", "blockquote"],
        [{ color: [] }],
        [
          { list: "ordered" },
          { list: "bullet" },
          { indent: "-1" },
          { indent: "+1" },
        ],
        ["link", "image"],
        ["clean"],
      ],
    },
    clipboard: {
      matchVisual: false,
    },
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "color",
    "clean",
  ];

  return (
    <div>
      {renderSwitch(
        type,
        index,
        payload,
        handleChange,
        formData,
        rest,
        quillfRef,
        modules,
        formats,
        handlePaste,
        error,
        isError,
        setIsError,
        question1,
        selectedType,
        selectedSubject
      )}
    </div>
  );
};

export default FormUI;
