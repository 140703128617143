import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import assDash from "../../Assets/icons/assignment-dashboard.svg";
import live from "../../Assets/icons/live.svg";
import Button from "../../Components/Buttons/Button";
import { getAllBonusOffer } from "../../services/getAllBonusOffer";
import CommonForm from "../HomePage/Form/CommonForm";
import { checkToken } from "../../services/checkToken";
import { getCategories } from "../../services/getCategories";
import { Helmet } from "react-helmet";

const DashboardHome = () => {
  const [pageData, setPageData] = useState([]);
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [serviceType, setServiceType] = useState("");

  useEffect(() => {
    checkToken();
    return () => {
      const verifyToken = async () => await checkToken();
      const response = verifyToken();
    };
  }, []);

  const toggle = () => {
    setIsModelOpen(!isModelOpen);
  };

  const fetchData = async () => {
    await getAllBonusOffer()
      .then((data) => {
        setPageData(data.data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    fetchData();
  }, []);

  const [categorieData, setCategorieData] = useState([]);
  const fetchDatacategorie = async () => {
    await getCategories()
      .then((data) => {
        setCategorieData(data.data);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    fetchDatacategorie();
  }, []);

  const handlePopup = (serviceType, type, totalSteps) => {
    setIsModelOpen(true);
    setServiceType({ serviceType, type, totalSteps });
    localStorage.setItem("serviceType", serviceType);
  };

  return (
    <>
      <Helmet>
        <title>Dashboard | Get Personalized Academic Success with DoubtQ | Online Question Help</title>
        <meta name="description" content="Welcome to the Dashboard of DoubtQ" />
        <meta property="og:title" content="Dashboard - DoubtQ" />
        <meta property="og:description" content="Explore personalized academic help on DoubtQ's Dashboard" />
      </Helmet>
      <Container className="py-5 pt-0 w-85 dashboard">
        <h1 className="fw-bolder py-5">Get Expert For...</h1>
        <Row>
          <Col className="d-flex justify-content-center mb-4">
            <div
              className="category-Card text-decoration-none"
              onClick={() => handlePopup("AssignmentHelp", "assignmentHelp", 4)}
            >
              <img src={assDash} alt="" />
              <h3 className="mt-5">{categorieData[0]?.name}</h3>
              <h3>{categorieData[0]?.description}</h3>
            </div>
          </Col>
          <Col className="d-flex justify-content-center mb-4">
            <div
              className="category-Card text-decoration-none"
              onClick={() =>
                handlePopup("LiveSessionHelp", "livesessionHelp", 4)
              }
            >
              <img src={live} alt="" />
              <h3 className="mt-5">{categorieData[2]?.name}</h3>
              <h3>{categorieData[2]?.description}</h3>
            </div>
          </Col>
        </Row>

        <Row className="pt-5 justify-content-center">
          <h1 className="fw-bolder">Ask Question from Experts</h1>
          <h3>Short and Final answers</h3>
          <Button
            className="mt-5 w-50 ask-que"
            text={"Ask Question"}
            onClick={() => handlePopup("HomeworkHelp", "mcqHelp", 3)}
          ></Button>
        </Row>
      </Container>
      {isModelOpen ? (
        <CommonForm
          isOpen={isModelOpen}
          toggle={toggle}
          typeData={serviceType}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default DashboardHome;
