import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { Modal, ModalBody } from 'reactstrap';
import Check from "../Assets/images/New/Check.png"
import pop from "../Assets/images/New/pop-img.png"
import "./NotificationPopup.scss"
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';

const NotificationPopup = () => {


    const [selectedFiles, setSelectedFiles] = useState([]);

    const handleFileChange = (event) => {
        const files = Array.from(event.target.files);
        setSelectedFiles(files);
    };

    const [showPopup, setShowPopup] = useState(false);


    useEffect(() => {
        const alreadyVisited = Cookies.get('doubtqVisited');
        if (!alreadyVisited) {
            const timer = setTimeout(() => {
                setShowPopup(true);
            }, 30000);

            Cookies.set('doubtqVisited', 'true', { expires: 365 });
            return () => clearTimeout(timer);
        }
    }, []);

    const handleClose = () => {
        setShowPopup(false);
    };

    return (
        <div>
            <Modal isOpen={showPopup} toggle={handleClose} centered size='xl' className='notification-pop'>
                <ModalBody className='notification-popup mb-0 position-relative'>
                    <button className='close-btn-pop' onClick={handleClose}>×</button>
                    <div className='row'>
                        <div className='col-md-6'>
                            <div className='pop=img'>
                                <img src={pop} alt='pop' />
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='pop-text mt-4'>
                                <h1>What are you Waiting For ? </h1>
                                <h2>Get Academic Help</h2>
                            </div>
                            <div className="row mt-4">
                                <div className="col-md-12">
                                    <div className="row py-3">
                                        <div className="col-md-6 mb-3">
                                            <div className="place-your-writing-container pop-mobile">
                                                <label htmlFor="uploadQuestionPDF" className="place-your-writing-label">
                                                    <div className="upload-file-container pop-file" style={{ background: "#f5f5f5" }}>
                                                        <span>
                                                            Drop your File or <span className="text-primary">Browse</span>
                                                        </span>
                                                    </div>
                                                    <input
                                                        type="file"
                                                        hidden
                                                        multiple
                                                        id="uploadQuestionPDF"
                                                        name="questionPDF"
                                                        accept=".pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.txt,.csv,.rtf,.html,.htm,.xml,.json,.js,.css,.java,.py,.cpp,.cs,.rb,.php,.swift,.pl,.sh,.bat,.ps1,.vbs,.asm,.c,.h,.cpp,.hpp,.cs,.java,.jar,.py,.rb,.pl,.php,.shtml,.asp,.aspx,.jsp,.cfm,.actionscript,.sgf,.wasm,.unity,.gmx,.gmz,.love,.ase,.aseprite,.atlas,.png,.jpg,.jpeg"
                                                        className="file-input"
                                                        onChange={handleFileChange}
                                                    />
                                                </label>
                                                {selectedFiles.length > 0 && (
                                                    <ul className="file-list">
                                                        {selectedFiles.map((file, index) => (
                                                            <li key={index} className="file-name" style={{ fontSize: "1.2rem" }}>
                                                                {file.name}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="place-your-writing-container pop-mobile">
                                                <textarea
                                                    name="question"
                                                    id="typeYourQuestion"
                                                    className="pop-file textarea-box"
                                                    placeholder="Type your questions here"
                                                    defaultValue=""
                                                />
                                            </div>
                                        </div>
                                        <div className="w-auto path-contents" />
                                    </div>
                                </div>
                            </div>
                            <div className='text-center'>
                                <Link to="/register">
                                    <button className='pop-btn' onClick={handleClose}>Continue</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </ModalBody>
                <div className="footer">
                    <div className='row'>
                        <div className='col-md-4'>
                            <div className="feature">
                                <img src={Check} width={40} alt="Free AI Turnitin Report" className='pop-image-check' />
                                <p>Free AI Turnitin Report</p>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className="feature">
                                <img src={Check} width={40} alt="Guaranteed 100% Refund" className='pop-image-check' />
                                <p>Guaranteed 100% Refund</p>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className="feature">
                                <img src={Check} width={40} alt="100% Verified Solutions" className='pop-image-check' />
                                <p>100% Verified Solutions</p>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default NotificationPopup;
