import React, { useEffect, useState, useRef } from "react";

import { useLocation, useNavigate } from "react-router-dom";

import {
  Container,
  Col,
  Input,
  Row,
} from "reactstrap";

import search from "../../Assets/icons/Search.svg";
import { minuteDifference } from "../../utils/js/DateUtils";
import "./dashboard-header.scss";

import logo from "../../Assets/logo/logo.svg";
import bell from "../../Assets/icons/bell.svg";
import avatar from "../../Assets/icons/avatar.svg";
import { getNotifcations } from "../../services/getNotifications";
import history from "../../Assets/icons/history.svg";

import { readNotification } from "../../services/readNotification";

const DashboardHeader = () => {
  const userEmail = localStorage.getItem("email");
  const [searchTerm, setSearchTerm] = useState("");
  const location = useLocation();
  const [isSearchDropdownOpen, setIsSearchDropdownOpen] = useState(false);
  const [prevSearchTerm, setPrevSearchTerm] = useState("");
  const inputRef = useRef(null);

  const [isOpen, setIsOpen] = useState(false);
  const [notificationData, setNotificationData] = useState([]);
  const [profileMenuOpen, setProfileMenuOpen] = useState(false);
  const [notificationMenuOpen, setNotificationMenuOpen] = useState(false);

  const navigate = useNavigate();
  const toggle = () => setIsOpen(!isOpen);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const query = params.get("query");
    setSearchTerm(query || "");
  }, [location.search]);

  useEffect(() => {
    if (isSearchDropdownOpen || searchTerm.trim() !== "") {
      inputRef.current.focus();
    }
  }, [isSearchDropdownOpen, searchTerm]);

  const handleSearch = () => {
    if (searchTerm.trim() !== "") {
      if (searchTerm !== prevSearchTerm) {
        navigate(`/dashboard/library?query=${searchTerm}`);
        setPrevSearchTerm(searchTerm);
      }
    }
    setIsSearchDropdownOpen(false);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("email");
    localStorage.removeItem("token");
    localStorage.removeItem("questionId");
    localStorage.removeItem("serviceType");
    navigate("/", { replace: true });
    window.location.reload();
  };

  const fetchNotificationData = async () => {
    const response = await getNotifcations();
    setNotificationData(response.data);
  };

  const handleMessageRead = async (id) => {
    const response = await readNotification(id);
    if (response.isSuccess) {
      fetchNotificationData();
    }
  };
  const notificationRef = useRef(null);
  const profileRef = useRef(null);

  const closeNotificationMenu = () => {
    setNotificationMenuOpen(false);
  };

  const closeProfileMenu = () => {
    setProfileMenuOpen(false);
  };

  const handleClickOutside = (event) => {
    if (
      notificationRef.current &&
      !notificationRef.current.contains(event.target)
    ) {
      closeNotificationMenu();
    }
    if (
      profileRef.current &&
      !profileRef.current.contains(event.target) &&
      !event.target.classList.contains("menu-link")
    ) {
      closeProfileMenu();
    }
  };

  const handleMenuLinkClick = () => {
    closeProfileMenu();
    closeNotificationMenu();
  };
  useEffect(() => {
    fetchNotificationData();
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <>
      <Container fluid className="top-header-dashboard">
        <div className="logo-dashboard">
          <img src={logo} alt="" width={"100%"} height={"100%"} />
        </div>
        <div className="search-container d-none d-sm-block">
          <Row>
            <Col className="position-relative">
              <Input
                ref={inputRef}
                type="text"
                placeholder="Search.."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                onKeyDown={handleKeyPress}
                autoFocus={isSearchDropdownOpen || searchTerm.trim() !== ""}
                className="py-2 search inside"
              />
              <div
                className="d-flex p-3 rounded-3 search-icon"
                onClick={handleSearch}
              >
                <img src={search} alt="" />
              </div>
            </Col>
          </Row>
        </div>
        <div className="d-flex  align-items-center justify-content-end">
          <div className="px-3 notification-button" ref={notificationRef}>
            <span
              className="menu nav-link position-releative"
              onClick={() => setNotificationMenuOpen(!notificationMenuOpen)}
            >
              <img
                src={bell}
                alt=""
                style={{ width: "35px", height: "35px" }}
              />
              {notificationData.length > 0 && (
                <span className="notification-count">
                  {notificationData.length}
                </span>
              )}
            </span>
            <div
              className={`dropDown-Content ${notificationMenuOpen ? "open" : ""
                }`}
            >
              <div className="notification">
                <span>Notification</span>
              </div>
              <div className="pb-2 ps-4">
                {!notificationData.length ? (
                  <h3 className="text-left d-flex p-3">
                    No new notification available
                  </h3>
                ) : (
                  <></>
                )}
                <div className="notification-message-wrapper">
                  {notificationData?.map((noti) => {
                    return (
                      <div className="d-flex align-items-center notification-wrapper">
                        <div className="me-2">
                          <img src={history} />
                        </div>
                        <div>
                          <div className="fw-bold">
                            {noti.categoryName} | {noti.displayId}
                          </div>
                          <div>
                            <span className="notification-status">
                              {noti.notificationStatus} |{" "}
                            </span>
                            <span className="notification-time">
                              {minuteDifference(noti.createdAt)}
                            </span>
                          </div>
                          <div className="notification-title">{noti.title}</div>
                          <div className="d-flex justify-content-end">
                            <button
                              className="read-button"
                              onClick={() => handleMessageRead(noti._id)}
                            >
                              Mark as Read
                            </button>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className="px-3 profile-button" ref={profileRef}>
            <span
              className="menu nav-link"
              onClick={() => setProfileMenuOpen(!profileMenuOpen)}
            >
              <img
                src={avatar}
                alt=""
                style={{ width: "28px", height: "28px" }}
              />
            </span>
            <div
              className={`dropDown-Content ${profileMenuOpen ? "open" : ""}`}
            >
              <div className="profile-setting">
                <p>{userEmail}</p>
              </div>
              <div className="p-4">
                <div
                  className="menu-link"
                  onClick={() => {
                    navigate("/dashboard/personal-info");
                    handleMenuLinkClick();
                  }}
                >
                  Personal Info
                </div>
                <div className="menu-link" onClick={handleLogout}>
                  Logout
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default DashboardHeader;
