import axiosInstance from "../axiosInterceptor";

export async function getLibraryAssignment(subCategoryId) {
  try {
    const response = await axiosInstance.get(`student/getLibraryAssignment/${subCategoryId}`);
    const data = response?.data;
    return data;
  } catch (error) {
    console.error("Error fetching library assignments:", error);
    return null;
  }
}
