import React from "react";
import "./Universities.scss";
import background from "../Assets/images/New/Universities.png";
import Button from "./Buttons/Button";

const Universities = ({ categoryId, onClick = () => {} }) => {

  let titleText;

  switch (categoryId) {
    case "65524e633c005ef3b51907c9":
      titleText =
        "Assignment Help by DoubtQ Trusted by Over 50,000 Students Globally from Various Universities";
      break;
    case "65524e353c005ef3b51907c2":
      titleText =
        "Live Session Help by DoubtQ Trusted by Over 50,000 Students Globally from Various Universities";
      break;
    case "65524e5e3c005ef3b51907c6":
      titleText =
        "Homework Help by DoubtQ Trusted by Over 50,000 Students Globally from Various Universities";
      break;
  }

  return (
    <div
      className="universities-container"
      style={{ backgroundImage: `url(${background})` }}
    >
      <div className="universities-steps-container text-center">
        <h2 className="universities-title">{titleText}</h2>
        <div className="universities-description">
          DoubtQ is trusted by international students. DoubtQ has helped 50,000+
          top university students. While institutions prepare students for
          success, we ease their academic path.
        </div>
      </div>
      <div className="universities-list">
        <div className="row">
          <div className="col-md-4 universitie-table">
            <div className="box">Trent University</div>
            <div className="box">Lakehead University</div>
            <div className="box">Qatar University</div>
            <div className="box">Middlesex University</div>
            <div className="box">Acadia University</div>
            <div className="box">University of Limpopo</div>
          </div>
          <div className="col-md-4 universitie-table">
            <div className="box">McGill University</div>
            <div className="box">University of Toronto</div>
            <div className="box">Khalifa University</div>
            <div className="box">University of Adelaide</div>
            <div className="box">Monash University</div>
            <div className="box">University of Venda</div>
            {/* <div className='box many-more'>
                            <Link to="/" className='many-more-btn'>
                                & Many More
                            </Link>
                        </div> */}
          </div>
          <div className="col-md-4 universitie-table">
            <div className="box">Columbia University</div>
            <div className="box">Stanford University</div>
            <div className="box">University of Jordan</div>
            <div className="box">University of Queensland</div>
            <div className="box">University of Cape Town</div>
            <div className="box">Dalhousie University</div>
          </div>
        </div>
      </div>
      <Button text={"Get Help"} varient="primary" onClick={onClick} />
    </div>
  );
};

export default Universities;
