import axiosInstance from "../axiosInterceptor";

export async function getLiveSessionQuestionById(questionId) {
  try {
    const response = await axiosInstance.get(
      `/student/liveSessionHelp/getQuestionDetailByID/${questionId}`
    );
    const data = response?.data;
    return data;
  } catch (error) {
    console.log(error);
  }
}
