import React from "react";
import {
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap";
import stripeTrust from "./../../../Assets/images/stripeTrust.png";

const HomeWorkForm3 = ({
  payload,
  formData,
  selectedSubject,
  selectedType
}) => {
  console.log("selectedSubject", selectedType, selectedSubject);
  return (
    <Container>
      <Row className="justify-content-center align-items-center flex-column mt-4">
        <Card >
          <CardBody>
            <CardTitle>
              <h3 className="step-header text-center ">Question Preview</h3>
            </CardTitle>
            <CardText>
              <Row>
                <Col className="d-flex">Subject</Col>
                <Col className="d-flex justify-content-end">
                  {
                    (formData.subject.find((x) => x._id === payload.subject)
                      ?.questionSubject) || (formData.subject.find((x) => x._id === selectedSubject?.value)
                        ?.questionSubject)
                  }
                </Col>
              </Row>
              <Row>
                <Col className="d-flex">Question Type</Col>
                <Col className="d-flex justify-content-end">
                  {
                    (formData.subcategory.find(
                      (x) => x._id === payload.questionType
                    )?.name) || formData.subcategory.find(
                      (x) => x._id === selectedType.value
                    )?.name
                  }
                </Col>
              </Row>
              <Row className=" mt-5">
                <hr />
              </Row>
              <Row>
                <Col className="d-flex">Question Price</Col>
                <Col className="d-flex justify-content-end">
                  <h3>{payload.price?.toFixed(2)} USD</h3>
                </Col>
              </Row>
            </CardText>
          </CardBody>
        </Card>
        <Row className="mt-5 text-center">
          <Col sm="12" className="w-100">
            <img src={stripeTrust} className="img-fluid" alt="Responsive" />
          </Col>
        </Row>
      </Row>
    </Container>
  );
};

export default HomeWorkForm3;
