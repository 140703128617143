import React, { useEffect, useState } from "react";
import "./HelpService.scss";
import PriceTagIcon from '../Assets/icons/pricetag.svg'
import { getSubcategoryWiseContent } from "../services/getSubcategoryWiseContent";
import { ImageLink } from "../constants";

const HelpService = ({ subCategoryID = '' }) => {
    const [pageData, setPageData] = useState([]);
    const fetchData = async () => {
        await getSubcategoryWiseContent(subCategoryID)
            .then((data) => {
                setPageData(data.multipleBenefitData);
            })
            .catch((err) => console.log(err));
    };

    useEffect(() => {
        fetchData();
    }, [subCategoryID]);
    return (
        <div className="background-shadow help-service">
            <span>
                <span className="title">{pageData?.mainTitle} </span>
            </span>
            <div className="help-service-card-container">
                <div className="help-service-container">
                    {pageData?.benefitData?.map((item, index) => {
                        return <CategoryCard key={index} details={item} />;
                    })}
                </div>
            </div>
        </div>
    );
};

const CategoryCard = ({ details }) => {
    return (
        <div className="help-card">
            <img className="help-card-icon py-2" src={ImageLink(details.image ?? PriceTagIcon)} ></img>
            <hr />
            <div className="help-card-title py-3 pt-2 m-0">{details.title}</div>
            <div className="help-card-description">{details.description}</div>
        </div>
    );
};

export default HelpService;
