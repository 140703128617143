import axiosInstance from "../axiosInterceptor";

export async function getNotifcations() {
  const response = await axiosInstance.post(`student/notifications`, {
    pageNo: 1,
    perPage: 10,
  });
  const data = response?.data;
  return data;
}
