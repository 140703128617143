import React from 'react';
import "./Comingsoon.scss"
import Coming from "../../Assets/video/Comingsoon2.mp4";

const Comingsoon = () => {
  return (
    <div className="comingsoon-container">
      <video className="comingsoon-video" autoPlay loop muted playsInline>
        <source src={Coming} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
}

export default Comingsoon;
