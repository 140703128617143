import axiosInstance from "../axiosInterceptor";

export async function getPriceLivehelp(body) {
  const response = await axiosInstance.post(
    `/admin/liveSessionPrice/getPriceByMin`,
    body
  );
  const data = response?.data;
  return data;
}
