import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Button, Card, Col, Row } from "reactstrap";
import LogoHeader from "./LogoHeader";

const razorpaykey = process.env.REACT_APP_RAZORPAY_KEY_ID;

const Razorpay = () => {
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);
  const [paymentInProgress, setPaymentInProgress] = useState(false);
  const { state } = useLocation();
  const { clientSecret = "" } = state ?? {};

  let script;

  useEffect(() => {
    const loadRazorpayScript = async () => {
      script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/checkout.js";
      script.async = true;
      script.onload = () => {
        setIsScriptLoaded(true);
      };
      document.body.appendChild(script);
    };

    loadRazorpayScript();

    return () => {
      if (script) {
        document.body.removeChild(script);
      }
    };
  }, []);

  const handlePayment = async () => {
    try {
      if (!isScriptLoaded || paymentInProgress) {
        console.error(
          "Razorpay script is not loaded or payment is in progress"
        );
        return;
      }
      setPaymentInProgress(true);
      const options = {
        key: razorpaykey,
        amount: clientSecret.amount,
        currency: clientSecret.currency,
        name: clientSecret.notes.billingFullname,
        description: clientSecret.notes.description,
        order_id: clientSecret.id,
        handler: async function (response) {
          const paymentId = response.razorpay_payment_id;
          const redirectURL =
            localStorage.getItem("serviceType") === "HomeworkHelp"
              ? `${window.location.origin}/dashboard/questions`
              : `${window.location.origin}/dashboard/razorpay-completion?paymentId=${paymentId}`;
          window.location.href = redirectURL;
        },
        prefill: {
          name: clientSecret.notes.billingFullname,
          email: clientSecret.notes.email,
        },
        notes: {
          address: clientSecret.notes.billingAddress,
        },
        theme: {
          color: "#3399cc",
        },
      };
      const razorpayPaymentObject = new window.Razorpay(options);
      razorpayPaymentObject.open();
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setPaymentInProgress(false);
    }
  };

  return (
    <div className="billingWrapper">
      <LogoHeader />
      <div className="billingCard">
        <div className="razorpay-page">
          <h3
            style={{ paddingBottom: "7px", borderBottom: "1px solid #cdcdcd" }}
          >
            Payment Details
          </h3>
          <div className="payment-details">
            <Row className="mt-4">
              <Col md="6">
                <h4
                  style={{
                    paddingBottom: "7px",
                    borderBottom: "1px solid #cdcdcd",
                  }}
                >
                  Name : {clientSecret?.notes?.billingFullname}
                </h4>
              </Col>
              <Col md="6">
                <h4
                  style={{
                    paddingBottom: "7px",
                    borderBottom: "1px solid #cdcdcd",
                  }}
                >
                  Category : {clientSecret?.notes?.paymentSection}
                </h4>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col md="6">
                <h4
                  style={{
                    paddingBottom: "7px",
                    borderBottom: "1px solid #cdcdcd",
                  }}
                >
                  Email : {clientSecret?.notes?.email}
                </h4>
              </Col>
              <Col md="6">
                <h4
                  style={{
                    paddingBottom: "7px",
                    borderBottom: "1px solid #cdcdcd",
                  }}
                >
                  Sub Category : {clientSecret?.notes?.description}
                </h4>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col md="6">
                <h2>Payable Amount</h2>
              </Col>
              <Col md="6">
                <h2 style={{ color: "#e541ed" }}>
                  ${(clientSecret?.amount / 100).toFixed(2)}
                </h2>
              </Col>
            </Row>
          </div>
        </div>
        <div className="text-center mt-4">
          <Button
            className="button button-linear-gradient-primary"
            onClick={handlePayment}
            disabled={paymentInProgress}
          >
            {paymentInProgress ? "Payment in process" : "Pay Now"}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Razorpay;
