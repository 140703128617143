import React from "react";
import { Card, Col, Container, Row } from "reactstrap";
import PriceTagIcon from "./../../../Assets/icons/pricetag.svg";
import { ImageLink } from "../../../constants";

const AssignmentForm0 = ({ rest, payload, error, isError, setIsError }) => {
  return (
    <Container className="assignment-selection-container">
      <h1 className="step-header text-center pb-5 mt-5">
        Types of assignment you need.
      </h1>
      <Row className="row-gap-5 justify-content-evenly">
        {rest.categories?.map((e) => {
          return (
            <>
              <Col md={4} xs={6}>
                <Card
                  className={`fs-3 ${payload.subCategory === e._id ? "border border-primary" : ""
                    }`}
                  onClick={() => {
                    rest.setPayload({
                      ...payload,
                      subCategory: e._id,
                      categoryId: e.categoryId,
                    });
                    setIsError(false);
                  }}
                >
                  <span className="assignment-selection-icon">
                    <img src={ImageLink(e.image)} alt="" />
                  </span>
                  <span className="assignment-subcategory-text">{e.name}</span>
                </Card>
              </Col>
            </>
          );
        })}
      </Row>
      {isError && error?.selectedAssignment && (
        <div className="text-danger text-center mt-3">
          {error.selectedAssignment}
        </div>
      )}
    </Container>
  );
};

export default AssignmentForm0;
