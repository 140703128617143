import axiosInstance from "../axiosInterceptor";

export async function studentReferralList(pageNo, perPage) {
    const response = await axiosInstance.post(`student/referralList`, {
        pageNo: pageNo,
        perPage: perPage,
    });
    const data = response?.data;
    return data;
}
