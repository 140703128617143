// ** React Imports ** //
import React, { useMemo, useState } from "react";

// ** Icons Imports ** //
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

// ** Utils Imports ** //
import { monthNamesWithShortNames } from "../../../utils/js/constraints";

const range = (start, end) => {
  return new Array(end - start).fill().map((d, i) => i + start);
};

const MonthSubPopup = ({
  currentMonth,
  navigateToPrevMonth,
  navigateToNextMonth,
  setCurrentMonth,
  currentYear,
  setCurrentYear,
  toggleSubPopupHandler,
}) => {
  const [yearInputStart, setYearInputStart] = useState(false);
  const year = useMemo(
    () => range(currentYear - 3, currentYear + 9),
    [yearInputStart]
  );

  const handleMonthClick = (item) => {
    setCurrentMonth(item - 1);
  };

  const handleYearClick = (item) => {
    setCurrentYear(item);
    toggleSubPopupHandler();
  };

  return (
    <div className="container-fluid subPopup">
      <div className="row">
        <div className="col-6 border-end">
          <div className="w-100">
            <div className="d-flex align-items-center justify-content-center">
              <div className="d-flex align-items-center">
                <IoIosArrowBack
                  size="1.2rem"
                  className="text-primary me-3 cursor-pointer"
                  onClick={navigateToPrevMonth}
                />
              </div>
              <h4 className="m-0">
                {monthNamesWithShortNames[currentMonth]?.fullMonth}
              </h4>
              <div className="d-flex align-items-center">
                <IoIosArrowForward
                  size="1.2rem"
                  className="text-primary ms-3 cursor-pointer"
                  onClick={navigateToNextMonth}
                />
              </div>
            </div>

            <div className="pt-3 date-sub-popup__grid">
              {monthNamesWithShortNames?.map((item, uniqueKey = 1) => {
                return (
                  <button
                    type="button"
                    key={uniqueKey + 1}
                    onClick={() => handleMonthClick(uniqueKey + 1)}
                    className={`text-center m-1 rounded-2 border-0 cursor-pointer sub-popup-item ${
                      item.fullMonth ===
                        monthNamesWithShortNames[currentMonth].fullMonth &&
                      "bg-primary text-light"
                    } `}
                  >
                    <small>{item.shortMonth}</small>
                  </button>
                );
              })}
            </div>
          </div>
        </div>

        <div className="col-6">
          <div className="w-100">
            <div className="d-flex align-items-center justify-content-center">
              <div className="d-flex align-items-center">
                <IoIosArrowBack
                  size="1.2rem"
                  className="text-primary me-3 cursor-pointer"
                  onClick={() => setCurrentYear(currentYear - 1)}
                />
              </div>
              <div role="presentation" onClick={() => setYearInputStart(true)}>
                {yearInputStart ? (
                  <input
                    type="number"
                    value={currentYear}
                    onChange={(e) => {
                      if (e.target.value.length <= 4) {
                        setCurrentYear(e.target.value);
                      }
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter" && currentYear.length <= 4) {
                        setCurrentYear(Number(currentYear));
                        setYearInputStart(false);
                      }
                    }}
                    className="yearInput"
                  />
                ) : (
                  <h4 className="m-0">{currentYear}</h4>
                )}
              </div>
              <div className="d-flex align-items-center">
                <IoIosArrowForward
                  size="1.2rem"
                  className="text-primary ms-3 cursor-pointer"
                  onClick={() => setCurrentYear(currentYear + 1)}
                />
              </div>
            </div>

            <div className="pt-3 date-sub-popup__grid">
              {year?.map((item, uniqueKey = 1) => {
                return (
                  <button
                    type="button"
                    key={uniqueKey + 1}
                    onClick={() => handleYearClick(item)}
                    className={`text-center m-1 rounded-2 border-0 cursor-pointer sub-popup-item ${
                      item === currentYear && "bg-primary text-light"
                    }`}
                  >
                    <small>{item}</small>
                  </button>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MonthSubPopup;
