import React, { useEffect, useState } from "react";
import "./Referralprogram.scss";
import { studentReferralList } from "../../services/referralList";
import { toast } from "react-toastify";
import { redeemReward } from "../../services/referralReward";
import referral from "../../Assets/images/New/referral-program.png"
import { Table } from "reactstrap";
import { Helmet } from "react-helmet";

const PaginationComponent = ({ currentPage, totalPages, onPageChange }) => {
  const pages = [];
  for (let i = 1; i <= totalPages; i++) {
    pages.push(i);
  }

  return (
    <nav>
      <ul className="pagination">
        <li className={`page-item ${currentPage === 1 && "disabled"}`}>
          <button
            className="page-link"
            onClick={() => onPageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            &lt;
          </button>
        </li>
        {pages.map((page) => (
          <li
            key={page}
            className={`page-item ${page === currentPage && "active"}`}
          >
            <button className="page-link" onClick={() => onPageChange(page)}>
              {page}
            </button>
          </li>
        ))}
        <li className={`page-item ${currentPage === totalPages && "disabled"}`}>
          <button
            className="page-link"
            onClick={() => onPageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            &gt;
          </button>
        </li>
      </ul>
    </nav>
  );
};

const Referralprogram = () => {
  const [activeTab, setActiveTab] = useState("tab1");

  const openTab = (tabName) => {
    setActiveTab(tabName);
  };

  const [pageData, setPageData] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [loadingStates, setLoadingStates] = useState({});

  const [referralCode, setReferralCode] = useState("");
  const [referralLink, setReferralLink] = useState("");
  const [buttonTextLink, setButtonTextLink] = useState("Copy");

  useEffect(() => {
    const code = localStorage.getItem("referralCode");
    if (code) {
      setReferralCode(code);
      setReferralLink(`https://www.doubtq.com/register/${code}`);
    }
  }, []);

  const copyToClipboard = (text, setButtonText) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setButtonText("Copied!");
        setTimeout(() => {
          setButtonText("Copy");
        }, 2000);
      })
      .catch((err) => {
        // Handle error if needed
      });
  };

  const fetchData = async (pageNo) => {
    try {
      const data = await studentReferralList(pageNo, 10);
      setPageData(data.data);
      setTotalPages(data.totalPages);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage]);

  const handlePageChange = (pageNo) => {
    setCurrentPage(pageNo);
  };

  const handleRedeem = async (childId, pendingAmount) => {
    try {
      setLoadingStates((prevState) => ({ ...prevState, [childId]: true }));
      const response = await redeemReward(childId, pendingAmount);
      fetchData(currentPage);
      if (response.isSuccess) {
        toast.success(response.message);
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      toast.error("Failed to redeem reward");
    } finally {
      setLoadingStates((prevState) => ({ ...prevState, [childId]: false }));
    }
  };

  return (
    <div>
      <Helmet>
        <title>Referral Program | Get Personalized Academic Success with DoubtQ | Online Question Help</title>
        <meta name="description" content="Welcome to the Referral Program of DoubtQ" />
        <meta property="og:title" content="Referral Program - DoubtQ" />
        <meta property="og:description" content="Explore personalized academic help on DoubtQ's Referral Program" />
      </Helmet>
      <div className="dashboard-persional-setting">
        <h2 className="text-start referral-program">Referral Program</h2>
        <div className="tab-container mt-4">
          <div className="border-bottom">
            <div className="tab-buttons">
              <button
                className={`tab-button ${activeTab === "tab1" ? "active" : ""}`}
                onClick={() => openTab("tab1")}
              >
                How it Works
              </button>
              <button
                className={`tab-button ${activeTab === "tab2" ? "active" : ""}`}
                onClick={() => openTab("tab2")}
              >
                Transaction history
              </button>
            </div>
          </div>
          <div className="tab-content mt-4 mb-4">
            {activeTab === "tab1" && (
              <div id="tab1" className="tab px-4">
                <div className="row">
                  <div className='col-md-6'>
                    <div class="tab-image">
                      <img src={referral} alt='referral' className="referral-in-image" />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="tab-bg">
                      <img src="" alt="" />
                      <p className="refer-title">Refer A Friend</p>
                      <p className="main-text">
                        Get <span className="highlight">FLAT 10%</span> of your
                        friend's 1st payment amount in your{" "}
                        <span className="highlight">BANK ACCOUNT</span>
                      </p>
                      <p className="sub-text">
                        Your friend gets 50% OFF on the first order & you will
                        get 10% for lifetime, everytime your friend makes a
                        payment.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {activeTab === "tab2" && (
              <div id="tab2" className="tab">
                <div className="mt-4">
                  {pageData.length > 0 ? (
                    <>
                      <Table bordered responsive className="invite-table">
                        <thead>
                          <tr>
                            <th scope="col" className="text-start py-3">
                              Email
                            </th>
                            <th scope="col" className="py-3">
                              Country
                            </th>
                            <th scope="col" className="text-start py-3">
                              Referral Name
                            </th>
                            <th scope="col" className="py-3">
                              Referral Amount
                            </th>
                            <th scope="col" className="py-3">
                              Paid Amount
                            </th>
                            <th scope="col" className="py-3">
                              Pending Amount
                            </th>
                            <th scope="col" className="py-3">
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {pageData.map((item) => (
                            <tr key={item._id}>
                              <td className="text-start">{item.email}</td>
                              <td>{item.countryId.countryName}</td>
                              <td className="text-start">
                                {item.referralCode.email}
                              </td>
                              <td>
                                ${item.referralAmount?.toFixed(2) || "N/A"}
                              </td>
                              <td>${item.paidAmount?.toFixed(2)}</td>
                              <td>${item.pendingAmount?.toFixed(2)}</td>
                              <td>
                                <button
                                  className="inviteview"
                                  onClick={() =>
                                    handleRedeem(item?._id, item?.pendingAmount)
                                  }
                                  disabled={
                                    loadingStates[item._id] ||
                                    item.pendingAmount === 0
                                  }
                                >
                                  {loadingStates[item._id]
                                    ? "Redeeming..."
                                    : "Redeem"}
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                      <div className="float-end">
                        <PaginationComponent
                          currentPage={currentPage}
                          totalPages={totalPages}
                          onPageChange={handlePageChange}
                        />
                      </div>
                    </>
                  ) : (
                    <h3 className="text-center py-3 border-bottom">
                      No data found
                    </h3>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="copy-container pt-4">
          <div className="row mt-4 mb-4 px-4">
            <div className="col-md-6 text-start">
              <label className="form-label">Your referral code</label>
              <div className="mb-3 input-group">
                <input
                  placeholder="Enter referral code"
                  name="referral-code"
                  type="text"
                  className="fs-3 p-2 form-control"
                  value={referralCode}
                  readOnly
                />
              </div>
            </div>
            <div className="col-md-6 text-start">
              <label className="form-label">Your referral Link</label>
              <div className="mb-3 input-group">
                <input
                  placeholder="Enter referral code"
                  name="referral-code"
                  type="text"
                  className="fs-3 p-2 form-control"
                  value={referralLink}
                  readOnly
                />
                <button
                  className="btn btn-outline-secondary"
                  type="button"
                  id="button-addon2"
                  onClick={() =>
                    copyToClipboard(referralLink, setButtonTextLink)
                  }
                >
                  {buttonTextLink}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="referral-container mb-4">
          <h2 className="text-start mb-4">Steps To Follow</h2>
          <div className="tab-step tab-step1">
            <h2>Sign Up As a Student</h2>
            <p>
              You have to register at DoubtQ and then sign in with your Login
              Credentials to access the Student Panel where you can track your
              earnings on Student Dashboard and Withdraw it.
            </p>
          </div>
          <div className="arrow-ref arrow1">&#x2193;</div>
          <div className="tab-step tab-step2">
            <h2>Refer DoubtQ To Your Friends</h2>
            <p>
              Refer our service DoubtQ which offers Assignment Help & Live
              Session to help with students in their job & education. This will
              make time for your assignments and question solving.
            </p>
          </div>
          <div className="arrow-ref arrow2">&#x2193;</div>
          <div className="tab-step tab-step3">
            <h2>Share Your Referral Link/Code</h2>
            <p>
              If your friend clicks the referral link or enters your referral
              code during the sign up they will register with your Referral name
              by filling all the requirements. Once done, it will show in the
              list of referral programme in your student Dashboard.
            </p>
          </div>
          <div className="arrow-ref arrow3">&#x2193;</div>
          <div className="tab-step tab-step4">
            <h2>Your Friends Will Get 50% Off</h2>
            <p>
              Now, your friend will get 50% off in their first order with
              DoubtQ, regards to any service used by your friend. If your friend
              registered and order and paid the amount to DoubtQ then it will
              reflect in the Referral List of your student Dashboard.
            </p>
          </div>
          <div className="arrow-ref arrow4">&#x2193;</div>
          <div className="tab-step tab-step5">
            <h2>You Will Get 10% On Each Order</h2>
            <p>
              If your friend do multiple transactions then it will reflect in
              your Student Dashboard. You will withdraw the amount by requesting
              the payment shown in the Dashboard. You can do this Multiple times
              and enjoy this earning journey with DoubtQ.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Referralprogram;
