import React, { useEffect, useState } from "react";
import "./AssignmentList.scss";
import Button from "./Buttons/Button";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import Download from "../Assets/images/New/Download.png";
import { getLibraryAssignment } from "../services/getLibraryAssignment";
import { Link } from "react-router-dom";
import JSZip from "jszip";
import FileSaver from "file-saver";

const baseURL = process.env.REACT_APP_FILE_BASE_URL;

const AssignmentList = ({ subCategoryId, onClick = () => { } }) => {
  const [modal, setModal] = useState(false);
  const [modalContent, setModalContent] = useState({ title: "", files: [] });
  const [showAll, setShowAll] = useState(false);
  const [showLess, setShowLess] = useState(false);

  const toggle = () => setModal(!modal);

  const [assignmentListData, setAssignmentListData] = useState([]);

  useEffect(() => {
    const fetchAssignments = async () => {
      try {
        const response = await getLibraryAssignment(subCategoryId);
        if (response.isSuccess) {
          setAssignmentListData(response.data);
        } else {
          console.error("Failed to fetch assignments:", response.message);
        }
      } catch (error) {
        console.error("Error fetching assignments:", error);
      }
    };

    fetchAssignments();
  }, [subCategoryId]);

  const handleButtonClick = (title, files) => {
    setModalContent({ title, files });
    toggle();
  };

  const getFileDisplayName = (filePath) => {
    if (!filePath) return "";
    const fileName = filePath.split("/").pop();
    return fileName;
  };

  const handleDownloadFile = (file) => {
    const link = document.createElement("a");
    link.href = `${baseURL}${file}`;
    link.target = "_blank";
    link.download = getFileDisplayName(file);

    link.click();
  };

  const handleDownloadAll = async () => {
    try {
      const zip = new JSZip();
      const promises = modalContent.files.map((file, index) => {
        const fileName = getFileDisplayName(file);
        const url = `${baseURL}${file}`;

        return fetch(url)
          .then((response) => {
            if (!response.ok) {
              throw new Error(`HTTP error! Status: ${response.status}`);
            }
            return response.blob();
          })
          .then((blob) => {
            zip.file(fileName, blob);
          })
          .catch((error) => {
            console.error(`Error fetching ${fileName}:`, error);
          });
      });

      await Promise.all(promises);

      zip.generateAsync({ type: "blob" }).then((content) => {
        FileSaver.saveAs(content, "DoubtQ_Files.zip");
      });
    } catch (error) {
      console.error("Error creating zip file:", error);
    }
  };

  return (
    <>
      {assignmentListData?.length > 0 && (
        <div className="assignmentList-container">
          <div className="assignmentList-steps-container text-center">
            <h2 className="assignmentList-title">
              Recently Completed Assignments
            </h2>
            {assignmentListData?.length > 0 && (
              <div key={0} className="assignmentList-description">
                - {assignmentListData[0]?.subCategoryId?.name}
              </div>
            )}
          </div>
          <div className="assignmentList-table-container">
            <table className="assignmentList-table">
              <thead>
                <tr style={{ fontSize: "1.5rem" }}>
                  <th className="mobile-text">#</th>
                  <th>Questions</th>
                  <th>Solutions</th>
                </tr>
              </thead>
              <tbody>
                {assignmentListData?.map((assignment, index) => (
                  <tr key={index}>
                    <td className="text-center mobile-text">{index + 1}</td>
                    <td>
                      <div className="table-text">
                        <span className="assignment-questiontitle">{assignment.questionTitle}</span>
                        <button
                          className="timeline-button"
                          onClick={() =>
                            handleButtonClick(
                              `Question File`,
                              assignment.questionFile
                            )
                          }
                        >
                          View Question File
                        </button>
                      </div>
                    </td>
                    <td>
                      <div className="table-text">
                        <span className="assignment-questiontitle">{assignment.answerTitle}</span>
                        <button
                          className="timeline-button"
                          onClick={() =>
                            handleButtonClick(
                              `Solution File`,
                              assignment.solutionFile
                            )
                          }
                        >
                          View Solution File
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {!showAll && !showLess && assignmentListData?.length > 5 && (
              <div className="more">
                <p className="timeline-more" onClick={() => setShowAll(true)}>
                  & Many More
                </p>
              </div>
            )}
            {(showAll || (showLess && assignmentListData?.length > 5)) && (
              <div className="more">
                <p
                  className="timeline-more"
                  onClick={() => {
                    setShowAll(false);
                    setShowLess(false);
                  }}
                >
                  & Show Less
                </p>
              </div>
            )}
            {assignmentListData?.length > 0 && (
              <div className="more">
                <Button
                  key={0}
                  text={`Get My ${assignmentListData[0]?.subCategoryId?.name}`}
                  variant="primary"
                  onClick={onClick}
                />
              </div>
            )}
          </div>
          <Modal isOpen={modal} toggle={toggle} className="assignment-modal">
            <ModalHeader toggle={toggle}></ModalHeader>
            <ModalBody>
              <div className="modal-files">
                <div className="modal-header-content d-flex">
                  <div className="modal-circle">1</div>
                  <p className="mx-2">{modalContent.title}</p>
                </div>
                <h5 className="modal-files-title text-center">Title</h5>
                {modalContent.files.map((file, index) => {
                  return (
                    <div className="modal-file-item" key={index}>
                      <div className="modal-file-name">
                        {index + 1} |{" "}
                        <Link to={`${baseURL}${file}`} target="_blank">
                          {getFileDisplayName(file)}
                        </Link>
                      </div>
                      <button
                        className="modal-file-button"
                        onClick={() => handleDownloadFile(file)}
                      >
                        <i>
                          <img src={Download} alt="Download" />
                        </i>
                      </button>
                    </div>
                  );
                })}
              </div>
            </ModalBody>
            <ModalFooter>
              <button
                color="primary"
                className="download-all-button"
                onClick={handleDownloadAll}
              >
                Download All
                <i>
                  <img
                    className="download-all-img"
                    src={Download}
                    alt="Download"
                  />
                </i>
              </button>
            </ModalFooter>
          </Modal>
        </div>
      )}
    </>
  );
};

export default AssignmentList;


// import React, { useEffect, useState } from "react";
// import "./AssignmentList.scss";
// import Button from "./Buttons/Button";
// import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

// import Download from "../Assets/images/New/Download.png";
// import { getLibraryAssignment } from "../services/getLibraryAssignment";
// import { Link } from "react-router-dom";
// import JSZip from "jszip";
// import FileSaver from "file-saver";

// const baseURL = process.env.REACT_APP_FILE_BASE_URL;

// const AssignmentList = ({ subCategoryId, onClick = () => { } }) => {
//   const [modal, setModal] = useState(false);
//   const [modalContent, setModalContent] = useState({ title: "", files: [] });
//   const [showAll, setShowAll] = useState(false);
//   const [showLess, setShowLess] = useState(false);

//   const toggle = () => setModal(!modal);

//   const [assignmentListData, setAssignmentListData] = useState([]);

//   useEffect(() => {
//     const fetchAssignments = async () => {
//       try {
//         const response = await getLibraryAssignment(subCategoryId);
//         if (response.isSuccess) {
//           setAssignmentListData(response.data);
//         } else {
//           console.error("Failed to fetch assignments:", response.message);
//         }
//       } catch (error) {
//         console.error("Error fetching assignments:", error);
//       }
//     };

//     fetchAssignments();
//   }, [subCategoryId]);

//   const handleButtonClick = (title, files) => {
//     setModalContent({ title, files });
//     toggle();
//   };

//   const getFileDisplayName = (filePath) => {
//     if (!filePath) return "";
//     const fileName = filePath.split("/").pop();
//     return fileName;
//   };

//   const handleDownloadFile = (file) => {
//     const link = document.createElement("a");
//     link.href = `${baseURL}${file}`;
//     link.target = "_blank";
//     link.download = getFileDisplayName(file);

//     link.click();
//   };

//   const handleDownloadAll = async () => {
//     try {
//       const zip = new JSZip();
//       const promises = modalContent.files.map((file, index) => {
//         const fileName = getFileDisplayName(file);
//         const url = `${baseURL}${file}`;

//         return fetch(url)
//           .then((response) => {
//             if (!response.ok) {
//               throw new Error(`HTTP error! Status: ${response.status}`);
//             }
//             return response.blob();
//           })
//           .then((blob) => {
//             zip.file(fileName, blob);
//           })
//           .catch((error) => {
//             console.error(`Error fetching ${fileName}:`, error);
//           });
//       });

//       await Promise.all(promises);

//       zip.generateAsync({ type: "blob" }).then((content) => {
//         FileSaver.saveAs(content, "DoubtQ_Files.zip");
//       });
//     } catch (error) {
//       console.error("Error creating zip file:", error);
//     }
//   };

//   return (
//     <>
//       {assignmentListData?.length > 0 && (
//         <div className="assignmentList-container">
//           <div className="assignmentList-steps-container text-center">
//             <h2 className="assignmentList-title">
//               Recently Completed Assignments
//             </h2>
//             {assignmentListData?.length > 0 && (
//               <div key={0} className="assignmentList-description">
//                 - {assignmentListData[0]?.subCategoryId?.name}
//               </div>
//             )}

//           </div>
//           <div className="timeline-container">
//             <div className="row timeline">
//               <div className="timeline-column">
//                 {assignmentListData?.map((assignment, index) => (
//                   <React.Fragment key={index}>
//                     <div className="timeline-card" key={`question-${index}`}>
//                       <div className="timeline-card-title">
//                         {assignment.questionTitle}
//                       </div>
//                       <button
//                         className="timeline-button"
//                         onClick={() =>
//                           handleButtonClick(
//                             `Question File`,
//                             assignment.questionFile
//                           )
//                         }
//                       >
//                         View Question File
//                       </button>
//                     </div>
//                   </React.Fragment>
//                 ))}
//               </div>
//               <div className="timeline-middle">
//                 {assignmentListData?.map((assignment, index) => (
//                   <div key={`circle-group-${index}`}>
//                     <div className="timeline-circle" key={`circle-${index}`}>
//                       {index + 1}
//                     </div>
//                   </div>
//                 ))}
//               </div>
//               <div className="timeline-column">
//                 {assignmentListData?.map((assignment, index) => (
//                   <React.Fragment key={index}>
//                     <div className="timeline-card" key={`solution-${index}`}>
//                       <div className="timeline-card-title">
//                         {assignment.answerTitle}
//                       </div>
//                       <button
//                         className="timeline-button"
//                         onClick={() =>
//                           handleButtonClick(
//                             `Solution File`,
//                             assignment.solutionFile
//                           )
//                         }
//                       >
//                         View Solution File
//                       </button>
//                     </div>
//                   </React.Fragment>
//                 ))}
//               </div>
//             </div>
//             {!showAll && !showLess && assignmentListData?.length > 5 && (
//               <div className="more">
//                 <p className="timeline-more" onClick={() => setShowAll(true)}>
//                   & Many More
//                 </p>
//               </div>
//             )}
//             {(showAll || (showLess && assignmentListData?.length > 5)) && (
//               <div className="more">
//                 <p
//                   className="timeline-more"
//                   onClick={() => {
//                     setShowAll(false);
//                     setShowLess(false);
//                   }}
//                 >
//                   & Show Less
//                 </p>
//               </div>
//             )}
//             {assignmentListData?.length > 0 && (
//               <div className="more">
//                 <Button
//                   key={0}
//                   text={`Get My ${assignmentListData[0]?.subCategoryId?.name}`}
//                   variant="primary"
//                   onClick={onClick}
//                 />
//               </div>
//             )}

//           </div>
//           <Modal isOpen={modal} toggle={toggle} className="assignment-modal">
//             <ModalHeader toggle={toggle}></ModalHeader>
//             <ModalBody>
//               <div className="modal-files">
//                 <div className="modal-header-content d-flex">
//                   <div className="modal-circle">1</div>
//                   <p className="mx-2">{modalContent.title}</p>
//                 </div>
//                 <h5 className="modal-files-title text-center">Title</h5>
//                 {modalContent.files.map((file, index) => {
//                   return (
//                     <div className="modal-file-item" key={index}>
//                       <div className="modal-file-name">
//                         {index + 1} |{" "}
//                         <Link to={`${baseURL}${file}`} target="_blank">
//                           {getFileDisplayName(file)}
//                         </Link>
//                       </div>
//                       <button
//                         className="modal-file-button"
//                         onClick={() => handleDownloadFile(file)}
//                       >
//                         <i>
//                           <img src={Download} alt="Download" />
//                         </i>
//                       </button>
//                     </div>
//                   );
//                 })}
//               </div>
//             </ModalBody>
//             <ModalFooter>
//               <button
//                 color="primary"
//                 className="download-all-button"
//                 onClick={handleDownloadAll}
//               >
//                 Download All
//                 <i>
//                   <img
//                     className="download-all-img"
//                     src={Download}
//                     alt="Download"
//                   />
//                 </i>
//               </button>
//             </ModalFooter>
//           </Modal>
//         </div>
//       )}
//     </>
//   );
// };

// export default AssignmentList;
