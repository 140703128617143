import axiosInstance from "../axiosInterceptor";

export async function getBenefitBySubcategoryId(body) {
  const response = await axiosInstance.post(
    `admin/benefits/getBenefitBySubcategoryId`,
    body
  );
  const data = response?.data;
  return data;
}
