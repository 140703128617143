import React, { useEffect } from "react";
// import TrustpilotLogo from "../../Assets/images/New/trustpilot.svg";
import { Link } from "react-router-dom";

const TrustpilotRating = () => {
  useEffect(() => {
    const timer = setTimeout(() => {
      if (window.Trustpilot) {
        window.Trustpilot.loadFromElement(
          document.getElementsByClassName("trustpilot-widget")[0],
          true
        );
      }
    }, 0);
    return () => clearTimeout(timer);
  }, []);
  //   const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  //   useEffect(() => {
  //     const handleResize = () => setIsMobile(window.innerWidth <= 768);
  //     window.addEventListener("resize", handleResize);
  //     return () => window.removeEventListener("resize", handleResize);
  //   }, []);

  //   const containerStyles = {
  //     display: "flex",
  //     alignItems: "center",
  //     backgroundColor: "#e0e9f0",
  //     padding: "10px 20px",
  //     borderRadius: "8px",
  //     minWidth: isMobile ? "300px" : "200px",
  //     justifyContent: "center",
  //     boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
  //   };

  //   const ratingStyles = {
  //     fontSize: isMobile ? "20px" : "24px",
  //     fontWeight: "bold",
  //     color: "#333",
  //   };
  //   const logoImageStyles = {
  //     width: isMobile ? "180px" : "120px",
  //   };

  return (
    <>
      {/* <div style={containerStyles}>
            <span style={styles.logo}>
                <img src={TrustpilotLogo} alt="Trustpilot Logo" style={logoImageStyles} />
            </span>
            <span style={ratingStyles}>{rating}</span>
        </div> */}
      {/* TrustBox widget - Review Collector */}
      <div
        className="trustpilot-widget"
        data-locale="en-US"
        data-template-id="56278e9abfbbba0bdcd568bc"
        data-businessunit-id="6655bdca0385994b6813eea1"
        data-style-height="52px"
        data-style-width="100%"
      >
        <Link
          to="https://trustpilot.com/review/doubtq.com"
          target="_blank"
          rel="noopener"
        >
          Trustpilot
        </Link>
      </div>
    </>
  );
};

// const styles = {
//   logo: {
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "center",
//     marginRight: "8px",
//   },
// };

export default TrustpilotRating;
