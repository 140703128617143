import axiosInstance from "../axiosInterceptor";

export async function updateHomeWorkRemarks(id, body) {
  const response = await axiosInstance.put(
    `student/homeworkHelp/updateRemark/${id}`,
    body
  );
  const data = response?.data;
  return data;
}

export async function updateAssignmentRemarks(id, body) {
  const response = await axiosInstance.put(
    `student/assignmentHelp/updateRemark/${id}`,
    body
  );
  const data = response?.data;
  return data;
}

export async function updateLiveRemarks(id, body) {
  const response = await axiosInstance.put(
    `student/liveSessionHelp/updateRemark/${id}`,
    body
  );
  const data = response?.data;
  return data;
}
