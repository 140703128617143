function formatDate(date) {
    let month = date.getMonth() + 1;
    let day = date.getDate();
    const year = date.getFullYear();

    if (month.length < 2) month = date.getMonth() + 1;
    if (day.length < 2) day = date.getDate();

    return [year, month, day].join('-');
}

export default formatDate;
