import React from 'react';
import book from '../Assets/images/New/book.png';
import border from '../Assets/images/New/border.png';
import icon1 from '../Assets/images/New/icon1.png';
import icon2 from '../Assets/images/New/icon2.png';
import icon3 from '../Assets/images/New/icon3.png';
import icon4 from '../Assets/images/New/icon4.png';
import icon5 from '../Assets/images/New/icon5.png';
import icon6 from '../Assets/images/New/icon6.png';
import Button from './Buttons/Button';
import './Quessol.scss';
import { Link } from 'react-router-dom';

const Quessol = ({ onClick = () => { } }) => {
  return (
    <div>
      <section className="main-section">
        <div className="container">
          <h3 className="title">Your Gateway to Questions &amp; Solutions</h3>
          <div className="description">All solutions in Just one click!</div>
          <div className="row my-5">
            <div className="col-md-6">
              <div className="image-container">
                <div className="image-wrapper">
                  <img
                    src={book}
                    alt="Book Image"
                    className="main-image img-fluid"
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="icon-container">
                <div className="row">
                  <div className="col-6 col-sm-4">
                    <div className="icon-wrapper text-center">
                      <img src={border} className="icon-border" />
                      <img src={icon1} className="icon" />
                    </div>
                  </div>
                  <div className="col-6 col-sm-4">
                    <div className="icon-wrapper text-center">
                      <img src={border} className="icon-border" />
                      <img src={icon2} className="icon" />
                    </div>
                  </div>
                  <div className="col-6 col-sm-4">
                    <div className="icon-wrapper text-center">
                      <img src={border} className="icon-border" />
                      <img src={icon3} className="icon" />
                    </div>
                  </div>
                  <div className="col-6 col-sm-4">
                    <div className="icon-wrapper text-center">
                      <img src={border} className="icon-border" />
                      <img src={icon4} className="icon" />
                    </div>
                  </div>
                  <div className="col-6 col-sm-4">
                    <div className="icon-wrapper text-center">
                      <img src={border} className="icon-border" />
                      <img src={icon5} className="icon" />
                    </div>
                  </div>
                  <div className="col-6 col-sm-4">
                    <div className="icon-wrapper text-center">
                      <img src={border} className="icon-border" />
                      <img src={icon6} className="icon" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Link to="/library">
          <Button text={"& Many More"} varient="primary" onClick={onClick} />
        </Link>
      </section>
    </div>
  );
};

export default Quessol;

