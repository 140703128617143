import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Footer from "../../Components/Footer";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { Container } from "reactstrap";
import axiosInstance from "../../axiosInterceptor";
import ScrollTop from "../../Components/Buttons/ScrollTop";
import Navbar from "../../Components/Navbar";
import Nav from "../../Components/Nav";
import { Helmet } from "react-helmet";

const Refundpolicy = () => {
  const [cmsData, setCmsData] = useState([]);
  const location = useLocation();
  const fetchCMSData = async () => {
    try {
      const response = await axiosInstance.get(`/admin/cms/getCMS${location.pathname}`);
      document.title = `DoubtQ - ${response.data.data.title}`;
      setCmsData(response.data.data);
    } catch (error) {
      console.error("Error fetching CMS data:", error);
    }
  };

  useEffect(() => {
    fetchCMSData();
  }, [location]);

  return (
    <>
      {/* {cmsData?.title &&
        <Helmet>
          <title>{`${cmsData?.title} | Get Personalized Academic Success with DoubtQ | Online Question Help`}</title>
          <meta name="description" content={`Welcome to the ${cmsData?.title} of DoubtQ`} />
          <meta property="og:title" content={`${cmsData?.title} - DoubtQ`} />
          <meta property="og:description" content={`Explore personalized academic help on DoubtQ's ${cmsData?.title}`} />
        </Helmet>
      } */}

      <Nav />
      <Navbar />
      <div className="row mt-4 mb-4 px-4 m-0 breadcrumb-border">
        <div className="col-lg-12">
          <div className="breadcrumb-inner text-start">
            <ul className="page-list">
              <li className="rbt-breadcrumb-item">
                <Link to="/">Home</Link>
              </li>
              <li>
                <div className="icon-right">
                  <MdOutlineKeyboardArrowRight />
                </div>
              </li>
              <li className="rbt-breadcrumb-item active">{cmsData?.title}</li>
            </ul>
          </div>
        </div>
      </div>
      <Container fluid>
        <main className="rbt-main-wrapper questionAnswerjustify">
          <div className="rbt-breadcrumb-default ptb--40 ptb_md--50 ptb_sm--30 bg-gradient-1 ptb-inner">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="breadcrumb-inner text-center">
                    <h2 className="title fw-bold mt-4">{cmsData?.title}</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="rbt-accordion-area accordion-style-1 bg-color-white rbt-section-gap">
            <div dangerouslySetInnerHTML={{ __html: cmsData?.description }} />
          </div>
        </main>
      </Container>
      <Footer />
      <ScrollTop />
    </>
  );
};

export default Refundpolicy;
