import React, { useEffect } from "react";
import bannerimage from "../../Assets/images/background/DQ6.jpg";
import bannermobile from "../../Assets/images/background/mobile-banner2.jpg";
import "./Static.scss";
import TrustpilotRating from "./TrustpilotRating";
import SitejabberRating from "./SitejabberRating";
import { Link } from "react-router-dom";
import EduRating from "./Edu";

const Reviewabanner = () => {
  useEffect(() => {
    const timer = setTimeout(() => {
      if (window.Trustpilot) {
        window.Trustpilot.loadFromElement(
          document.getElementsByClassName("trustpilot-widget")[0],
          true
        );
      }
    }, 0);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const loadSiteJabberWidget = () => {
      const script = document.createElement("script");
      script.id = "sj-widget";
      script.src = "https://www.sitejabber.com/js/v2/6655c7560f411/widgets.js";
      script.async = true;
      script.onload = script.onreadystatechange = function () {
        if (!r && (!this.readyState || this.readyState[0] == "c")) {
          r = 1;
        }
      };
      document.body.appendChild(script);
    };

    let r = 0;
    loadSiteJabberWidget();

    return () => {
      const scriptElement = document.getElementById("sj-widget");
      if (scriptElement) {
        scriptElement.remove();
      }
    };
  }, []);

  return (
    <>
      <div className="reviewa-banner-container">
        <div className="banner">
          <img className="reviewa-banner des" src={bannerimage} alt="Banner" />
          <img className="reviewa-banner mob" src={bannermobile} alt="Banner" />
          <div className="review-widgets-container">
            <TrustpilotRating />
            <Link
              to="https://edureviewer.com/services/doubtq-review/"
              target="_blank"
              className="text-decoration-none"
            >
              <EduRating rating="4.4" />
            </Link>
            <Link
              to="https://www.sitejabber.com/reviews/doubtq.com"
              target="_blank"
              className="text-decoration-none"
            >
              <SitejabberRating rating="4.2" />
            </Link>
            {/* End TrustBox widget */}
          </div>
          {/* <div className="review-widgets-container">
            <Link
              to="https://edureviewer.com/services/doubtq-review/"
              target="_blank"
              className="text-decoration-none"
            >
              <EduRating rating="4.4" />
            </Link>
            <Link
              to="https://www.trustpilot.com/review/doubtq.com"
              target="_blank"
              className="text-decoration-none"
            >
              <TrustpilotRating rating="4.4" />
            </Link>
            <Link
              to="https://www.sitejabber.com/reviews/doubtq.com"
              target="_blank"
              className="text-decoration-none"
            >
              <SitejabberRating rating="4.2" />
            </Link>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default Reviewabanner;
