import React from "react";

export const Renderer = (props) => {
  return (
    <div
      className={props.className}
      dangerouslySetInnerHTML={{ __html: props.content }}
    />
  );
};

export default Renderer;
