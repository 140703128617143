export const dateFormat = (dateTimeString) => {
    const dateTime = new Date(dateTimeString);

    const day = dateTime.getDate().toString().padStart(2, '0');
    const month = (dateTime.getMonth() + 1).toString().padStart(2, '0');
    const year = dateTime.getFullYear();

    const hours = dateTime.getHours().toString().padStart(2, '0');
    const minutes = dateTime.getMinutes().toString().padStart(2, '0');
    const amPM = hours >= 12 ? 'PM' : 'AM';

    const hours12 = (hours % 12) || 12;

    const formattedDate = `${day}-${month}-${year} | ${hours12}:${minutes} ${amPM}`;

    return formattedDate;
}