import React, { useState } from 'react';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import { Col, Row, Button } from 'reactstrap';
import upvote from '../../Assets/images/thumbs-up.png'
import downvote from '../../Assets/images/negative-vote.png'
import axiosInstance from '../../axiosInterceptor';


const DashboardAnswer = () => {
    const location = useLocation();
    const data = location.state
    const navigate = useNavigate();
    const [voted, setVoted] = useState(false);

    const handleVote = async (voteType) => {
        try {
            const response = await axiosInstance.post('/student/updateRating', {
                questionId: data?._id,
                paymentSection: 'HomeworkHelp',
                rating: voteType
            });
            console.log('Vote submitted successfully:', response.data);
            setVoted(true);
            navigate('/dashboard/questions');
        } catch (error) {
            console.error('Error submitting vote:', error);
        }
    };


    return (
        <div className='mt-3 text-start'>
            <div className='ques-subjects'>
                <div className='mt-2'>
                    <span>Subject: <strong>{data?.subjectId?.questionSubject}</strong></span>
                </div>
                <div className='mt-2'>
                    <span>Question Type: <strong>{data?.subCategoryId?.name}</strong></span>
                </div>
            </div>
            <h5 className='question-title'>Student Question</h5>
            <div className='answer-list'>
                <div className='mx-4'>
                    <Row className='student-question-list'>
                        <Col md="12">
                            <div className='d-flex qa-wrapper'>
                                <span>Que.</span>
                                <p className='d-flex ' dangerouslySetInnerHTML={{ __html: `${data?.question}` }} />
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
            <h5 className='question-title'>Tutor Answer</h5>
            <div className='answer-list'>
                <div className='mx-4'>
                    <Row className='student-question-list'>
                        <Col md="12">
                            <div className='d-flex qa-wrapper'>
                                <span> Ans.</span>
                                <p className='d-flex' dangerouslySetInnerHTML={{ __html: `${data?.answer || ''}` }} />
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
            <Row>
                <Col md="6">
                    {data?.questionStatus === 'Solved' && (
                        <div className='student-vote'>
                            <div className='d-flex'>
                                {data.rating !== 'Downvote' && (
                                    <div className='s-upvote vote-icon' onClick={() => handleVote('Upvote')}>
                                        <img src={upvote} alt='upvote' width={30} className={data.rating !== 'Downvote' ? 'not-allowed' : ''} />
                                    </div>
                                )}
                                {data.rating !== 'Upvote' && (
                                    <div className='s-downvote mx-4 vote-icon' onClick={() => handleVote('Downvote')}>
                                        <img src={downvote} alt='downvote' width={30} className={data.rating !== 'Upvote' ? 'not-allowed' : ''} />
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </Col>
                <Col md="6">
                    <Link to="/dashboard/questions" className='mt-4'>
                        <Button className='answer-btn float-end'>Back</Button>
                    </Link>
                </Col>
            </Row>

        </div>
    )
}

export default DashboardAnswer