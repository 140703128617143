import React, { useEffect, useState } from 'react'
import "./Getpop.scss";
import QA from "../../Assets/images/Q&A Icon2.png"
import CommonForm from '../HomePage/Form/CommonForm';

const GetPopup = () => {

    const [isModelOpen, setIsModelOpen] = useState(false);
    const [serviceType, setServiceType] = useState("");
    const [isVisible, setIsVisible] = useState(false);

    const toggle = () => {
        setIsModelOpen(!isModelOpen);
    };

    const handlePopup = (serviceType, type, totalSteps) => {
        setIsModelOpen(true);
        setServiceType({ serviceType, type, totalSteps });
        localStorage.setItem("serviceType", serviceType);
    };

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 100) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <>
            <div className={`whatsapp-form ${isVisible ? 'visible' : ''}`}>
                <button
                    className="whatsapp-button btn-sm p-0 mb-0 mr-0 text-center w-100"
                    onClick={() => handlePopup("HomeworkHelp", "mcqHelp", 3)}
                >
                    <img src={QA} alt='QA' width={50} className="whatsapp-icon" />
                    <div className="whatsapp-text">
                        <h2 className={`whatsapp-heading ${isVisible ? 'visible' : ''}`}>
                            Get Solutions Within 10 Minutes
                        </h2>
                    </div>
                </button>
            </div>
            {isModelOpen ? (
                <CommonForm
                    isOpen={isModelOpen}
                    toggle={toggle}
                    typeData={serviceType}
                />
            ) : (
                <></>
            )}
        </>
    )
}

export default GetPopup