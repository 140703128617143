import React, { useState } from "react";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "reactstrap";
import Button from "../Buttons/Button";

const TutorEngagement = ({ disable = false }) => {
  const [open, setOpen] = useState("1");
  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };

  return (
    <Accordion
      open={open}
      toggle={toggle}
      className={`accordian-Wrapper content-card`}
    >
      <AccordionItem className={disable ? "disabled" : ""}>
        <AccordionHeader targetId="1" className="heading">
          Tutor Engagment
        </AccordionHeader>
        {!disable ? (
          <AccordionBody accordionId="1">
            <div className="d-flex  justify-content-center align-items-center">
              <span className="pe-3 greyTitle w-25 text-start">Tutor</span>
              <span className="lineSeprator"></span>
              <span className="ps-3 subTitle w-50 text-end">
                <Button
                  text="Not Working"
                  disabled
                  style={{ backgroundColor: "#FF0000" }}
                />
              </span>
            </div>
          </AccordionBody>
        ) : (
          <></>
        )}
      </AccordionItem>
    </Accordion>
  );
};

export default TutorEngagement;
