import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Col,
  FormGroup,
  Input,
  Row,
} from "reactstrap";
import Button from "../Buttons/Button";
import {
  updateAssignmentRemarks,
  updateHomeWorkRemarks,
  updateLiveRemarks,
} from "../../services/updateRemarks";

const Remarks = ({ questionData }) => {
  const [open, setOpen] = useState("1");
  const [remark, setRemark] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);

  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };

  const handleChange = (e) => {
    setRemark(e.target.value);
  };

  const handleAdditional = async () => {
    try {
      if (questionData.paymentSection === "HomeworkHelp") {
        await updateHomeWorkRemarks(questionData._id, { remark: remark });
      } else if (questionData.paymentSection === "AssignmentHelp") {
        await updateAssignmentRemarks(questionData._id, { remark: remark });
      } else if (questionData.paymentSection === "LiveSessionHelp") {
        await updateLiveRemarks(questionData._id, { remark });
      }
      setIsSubmitted(true);
    } catch (error) {
      console.error("Error submitting remark:", error);
    }
  };

  useEffect(() => {
    setRemark(questionData.remark ?? "");
  }, []);

  return (
    <Accordion
      open={open}
      toggle={toggle}
      className="accordian-Wrapper content-card"
    >
      <AccordionItem>
        <AccordionHeader targetId="1" className="heading">
          Remarks
        </AccordionHeader>
        <AccordionBody accordionId="1">
          <div className="d-flex">
            <span className="greyTitle text-start">Your Estimated Price</span>
          </div>
          <div>
            <Row className="d-flex justify-content-center align-items-center">
              <Col xs={12} md={8} className="pt-3">
                <FormGroup noMargin>
                  <Input
                    type="textarea"
                    name="question"
                    style={{ height: "100%" }}
                    value={remark}
                    onChange={handleChange}
                    disabled={isSubmitted}
                  />
                </FormGroup>
              </Col>
              <Col xs={12} md={4} className="pt-3">
                <Button
                  text={isSubmitted ? "Submitted" : "Submit"}
                  varient={isSubmitted ? "disabled" : "outlined"}
                  style={{ padding: "0.5rem 5.2rem" }}
                  onClick={handleAdditional}
                ></Button>
              </Col>
            </Row>
          </div>
        </AccordionBody>
      </AccordionItem>
    </Accordion>
  );
};

export default Remarks;
