import React, { useState, useEffect } from "react";
import EduLogo from "../../Assets/images/New/edu-review.svg";

const EduRating = ({ rating }) => {

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth <= 768);
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const containerStyles = {
        display: "flex",
        alignItems: "center",
        backgroundColor: "#e0e9f0",
        padding: "10px 20px",
        borderRadius: "8px",
        minWidth: isMobile ? "300px" : "200px",
        justifyContent: "center",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
    };

    const ratingStyles = {
        fontSize: isMobile ? "20px" : "24px",
        fontWeight: "bold",
        color: "#333",
    }
    const logoImageStyles = {
        width: isMobile ? "180px" : "120px",
    };


    return (
        <div style={containerStyles}>
            <span style={styles.logo}>
                <img src={EduLogo} alt="Trustpilot Logo" style={logoImageStyles} />
            </span>
            <span style={ratingStyles}>{rating}</span>
        </div>
    );
};

const styles = {
    logo: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginRight: "8px",
    },
};

export default EduRating;
