import React, { useEffect, useState } from "react";
import {
  Card,
  Col,
  Container,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
} from "reactstrap";
import { getAllSubject } from "../../services/getAllSubject";
import library from "../../Assets/icons/library.svg";
import "./Library.scss";
import noques from "../../Assets/images/EFEFEFW 1.png";
import Smile_Icon from "../../Assets/images/Smile_Icon.svg";
import Button from "../../Components/Buttons/Button";
import { useNavigate } from "react-router-dom";
import CommonForm from "../HomePage/Form/CommonForm";
import sealCheck from "../../Assets/images/sealCheck.svg";
import { Helmet } from "react-helmet";

const baseURL = process.env.REACT_APP_FILE_BASE_URL;

const Library = ({
  heading,
  pageData,
  currentPage,
  setCurrentPage,
  totalPages,
  activeSubject,
  setActiveSubject,
  isDataFetching,
}) => {
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [serviceType, setServiceType] = useState("");
  const [formData, setFormData] = useState([]);
  const [showAll, setShowAll] = useState(false);
  const [subjectId, setSubjectId] = useState("");
  const rowsToShow = showAll ? formData.length : 11;
  const navigate = useNavigate();

  const fetchSubjectData = async () => {
    try {
      const response = await getAllSubject();
      setFormData(response.data);
    } catch (error) {
      console.log("Error fetching subject data:", error);
    }
  };

  const handlePaginationClick = (pageNo) => {
    if (pageNo === "prev" && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    } else if (pageNo === "next" && currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    } else if (typeof pageNo === "number") {
      setCurrentPage(pageNo);
    }
  };

  const handleViewAnswer = (text = "") => {
    navigate(`/dashboard/library/question-and-answer/${text}`);
  };

  useEffect(() => {
    fetchSubjectData();
  }, []);

  const toggle = () => {
    setIsModelOpen(!isModelOpen);
  };

  const handlePopup = (serviceType, type, totalSteps) => {
    setIsModelOpen(true);
    setServiceType({ serviceType, type, totalSteps });
    localStorage.setItem("serviceType", serviceType);
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div>
      <Helmet>
        <title>Library | Get Personalized Academic Success with DoubtQ | Online Question Help</title>
        <meta name="description" content="Welcome to the Library of DoubtQ" />
        <meta property="og:title" content="Library - DoubtQ" />
        <meta property="og:description" content="Explore personalized academic help on DoubtQ's Library" />
      </Helmet>
      <>
        <div>
          <h2 className="text-start search-que mt-4">Recently asked questions by our students:</h2>
          {pageData && pageData.length > 0 ? (
            pageData.map((item, index) => (
              <div key={index} className="search-que">
                <div
                  className={`library-card d-grid card-${index % 2 === 0 ? "even" : "odd"
                    }`}
                >
                  <div className="que">
                    <div className="text-start d-flex">
                      <span>Q:</span>
                      <p className="mx-2 text-start">
                        {item?.transcriptText && item.transcriptText.slice(0, 100) + (item.transcriptText.length > 100 ? '...' : '')}
                      </p>
                    </div>
                  </div>
                  <div className="que mt-4">
                    <div className="text-start d-flex">
                      <span>A:</span>
                      <div
                        className="mx-2 text-gray text-start"
                        dangerouslySetInnerHTML={{
                          __html: `${item?.answer.slice(0, 20)}${item?.answer.length > 20 ? "..." : ""}`,
                        }}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <Button
                        text="View answer"
                        varient="search"
                        className="view-button mt-4 p-0 mx-4 d-block"
                        onClick={() => handleViewAnswer(item?.transcriptUrl)}
                      />
                    </div>
                    <div className="col-md-6">
                      <div className="verified-solutions inside">
                        <img src={sealCheck} alt="sealCheck" className="mb-1" />
                        Verified Solutions
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : isDataFetching ? (
            <div className="fs-3"></div>
          ) : (
            <div>
              <div className="no-que mt-4">
                <img src={noques} alt="no" />
              </div>
              <h2 className="no-data-message mt-4">NO SOLUTION FOUND</h2>
              <div
                style={{
                  backgroundColor: "#f0f0f0",
                  width: "90%",
                  padding: "10px",
                  margin: "50px auto 0",
                  textAlign: "center",
                  borderRadius: "10px",
                }}
              >
                <div className="row align-items-center justify-content-between">
                  <div className="col-md-2 mb-2">
                    <img
                      src={Smile_Icon}
                      alt="smile"
                      style={{ width: "80px" }}
                    />
                  </div>
                  <div
                    className="col-md-3  d-flex align-items-center"
                    style={{
                      position: "relative",
                      textAlign: "left",
                      height: "100%",
                    }}
                  >
                    <h1 style={{ margin: 0, fontWeight: "800" }}>
                      <span style={{ color: "black" }}>Don't</span>{" "}
                      <span style={{ color: "blue" }}>worry</span>
                    </h1>
                    <div
                      style={{
                        position: "absolute",
                        top: "-10px",
                        bottom: "-10px",
                        right: "-10px",
                        borderRight: "2px solid #CCCCCC",
                      }}
                    ></div>
                  </div>
                  <div className="col-md-3 mb-3 px-3">
                    <h1
                      style={{
                        fontSize: "15px",
                        color: "#000000",
                        textAlign: "start",
                      }}
                    >
                      Ask an expert.
                    </h1>
                    <div
                      style={{
                        fontSize: "12px",
                        color: "black",
                        fontWeight: "bold",
                        textAlign: "start",
                      }}
                    >
                      Get a step-by-step solution in as little as 15 minutes!*
                    </div>
                  </div>
                  <div className="col-md-3 mb-3 px-3">
                    <button
                      className="do-my-ass"
                      style={{
                        background:
                          "linear-gradient(90deg, #5956e9 5.64%, #e541ed 96.39%)",
                        boxShadow: "0px 10px 20px rgba(74, 74, 112, 0.1)",
                        border: "2px solid #FFFFFF",
                        fontSize: "14px",
                        color: "#FFF",
                        borderRadius: "9px",
                        padding: "1rem",
                      }}
                      onClick={() => handlePopup("HomeworkHelp", "mcqHelp", 3)}
                    >
                      Ask a Question
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </>
      <Container className="py-5 w-85 dashboard">
        <Row className="text-start pt-2">
          <Col lg={8} sm={12}>
            <h1 className="pt-4 pb-4">{heading}</h1>
          </Col>
          <Col lg={4} sm={12}>
            <div className="Paginationbox">
              <Pagination className="mt-4">
                <PaginationItem disabled={currentPage === 1}>
                  <PaginationLink
                    previous
                    onClick={() => handlePaginationClick("prev")}
                  />
                </PaginationItem>
                {totalPages <= 7 ? (
                  Array.from({ length: totalPages || 0 }, (_, i) => (
                    <PaginationItem key={i + 1} active={i + 1 === currentPage}>
                      <PaginationLink onClick={() => handlePaginationClick(i + 1)}>
                        {i + 1}
                      </PaginationLink>
                    </PaginationItem>
                  ))
                ) : (
                  <>
                    <PaginationItem active={1 === currentPage}>
                      <PaginationLink onClick={() => handlePaginationClick(1)}>
                        1
                      </PaginationLink>
                    </PaginationItem>
                    {currentPage > 4 && (
                      <PaginationItem disabled>
                        <PaginationLink>...</PaginationLink>
                      </PaginationItem>
                    )}
                    {currentPage > 3 && (
                      <PaginationItem>
                        <PaginationLink onClick={() => handlePaginationClick(currentPage - 2)}>
                          {currentPage - 2}
                        </PaginationLink>
                      </PaginationItem>
                    )}
                    {currentPage > 2 && (
                      <PaginationItem>
                        <PaginationLink onClick={() => handlePaginationClick(currentPage - 1)}>
                          {currentPage - 1}
                        </PaginationLink>
                      </PaginationItem>
                    )}
                    {currentPage !== 1 && currentPage !== totalPages && (
                      <PaginationItem active>
                        <PaginationLink>{currentPage}</PaginationLink>
                      </PaginationItem>
                    )}
                    {currentPage < totalPages - 1 && (
                      <PaginationItem>
                        <PaginationLink onClick={() => handlePaginationClick(currentPage + 1)}>
                          {currentPage + 1}
                        </PaginationLink>
                      </PaginationItem>
                    )}
                    {currentPage < totalPages - 2 && (
                      <PaginationItem>
                        <PaginationLink onClick={() => handlePaginationClick(currentPage + 2)}>
                          {currentPage + 2}
                        </PaginationLink>
                      </PaginationItem>
                    )}
                    {currentPage < totalPages - 3 && (
                      <PaginationItem disabled>
                        <PaginationLink>...</PaginationLink>
                      </PaginationItem>
                    )}
                    <PaginationItem active={totalPages === currentPage}>
                      <PaginationLink onClick={() => handlePaginationClick(totalPages)}>
                        {totalPages}
                      </PaginationLink>
                    </PaginationItem>
                  </>
                )}
                <PaginationItem disabled={currentPage === totalPages}>
                  <PaginationLink
                    next
                    onClick={() => handlePaginationClick("next")}
                  />
                </PaginationItem>
              </Pagination>
            </div>
          </Col>
        </Row>
        <Row>
          <Col
            xl={3}
            lg={6}
            md={12}
            sm={12}
            className="mb-4 col-6"
            onClick={() => setActiveSubject("")}
          >
            <Card
              className={`library-top-card ${activeSubject === "" ? "active-Card" : ""
                }`}
            >
              <img
                src={library}
                alt=""
                style={{ width: "50px", height: "50px" }}
              />
              <h4 className="m-0 pt-3">All Subject</h4>
            </Card>
          </Col>
          {formData?.slice(0, rowsToShow).map((subject, index) => (
            <Col xl={3} lg={6} md={6} xs={6} className="mb-3" key={index}>
              <Card
                className={`library-top-card ${subject._id === subjectId ? "subject-active" : ""
                  }`}
                onClick={(e) => {
                  setSubjectId(subject._id);
                  scrollToTop();
                }}
              >
                <img
                  src={`${baseURL}${subject.image}`}
                  alt=""
                  style={{ width: "70px", height: "70px" }}
                />
                <h4 className="m-0 pt-3">{subject.questionSubject}</h4>
              </Card>
            </Col>
          ))}
          <div className="view-all-btn mt-4 mb-4">
            {!showAll && formData?.length > 11 && (
              <Button
                varient="primary"
                text="View All"
                onClick={() => setShowAll(true)}
              />
            )}
            {showAll && (
              <Button
                varient="primary"
                text="View Less"
                onClick={() => setShowAll(false)}
              />
            )}
          </div>
        </Row>
      </Container>
      {isModelOpen ? (
        <CommonForm
          isOpen={isModelOpen}
          toggle={toggle}
          typeData={serviceType}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default Library;
