import React from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { monthNames } from "../../../utils/js/constraints";

const DateInputControl = ({
  currentMonth,
  currentYear,
  navigateToPrevMonth,
  navigateToNextMonth,
  toggleSubPopupHandler,
}) => {
  return (
    <div className="d-flex justify-content-between align-items-center py-2 pe-2">
      <div className="d-flex align-items-center">
        <h4 className="m-0">{`${monthNames[currentMonth]} ${currentYear}`}</h4>
      </div>

      <div className="d-flex align-items-center">
        <IoIosArrowBack
          onClick={
            new Date().getMonth() < currentMonth
              ? navigateToPrevMonth
              : () => {}
          }
          size="1.2rem"
          className={`me-2 cursor-pointer ${
            new Date().getMonth() < currentMonth ? "text-primary" : "disabled"
          }`}
        />
        <IoIosArrowForward
          onClick={navigateToNextMonth}
          size="1.2rem"
          className="text-primary cursor-pointer"
        />
      </div>
    </div>
  );
};

export default DateInputControl;
