import React, { useState, useEffect } from "react";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Button,
  Row,
  Col,
} from "reactstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { default as CustomButton } from "../../Components/Buttons/Button";
import ExportFile from "../../Components/OrderStatusAndBilling/Helper";
import { getReferenceStyle } from "../../services/getReferenceStyle";
import { getAllLineSpacing } from "../../services/getAllLineSpacing";
import whatsapp from "../../Assets/images/whatsapp.png";
import axiosInstance from "../../axiosInterceptor";
import { Rating } from "react-simple-star-rating";
import { getAssignmentQuestionById } from "../../services/getAssignmentQuestionById";
import "../../Components/OrderStatusAndBilling/OrderHistory.scss";
// import TrustpilotRatings from "./TrustpilotRatings";
import SitejabberRatings from "./SitejabberRatings";
import { Helmet } from "react-helmet";
import TrustpilotRating from "../Staticpages/TrustpilotRating";

const Dashboardview = () => {
  const [openAccordion, setOpenAccordion] = useState(["1", "2", "3", "4"]);
  const [ratingValue, setRatingValue] = useState(0);
  const [data, setData] = useState({});
  const { questionId } = useParams();
  const [setting, setSetting] = useState([]);
  const [refrenceStyle, setRefrenceStyle] = useState([]);
  const [lineSpacing, setLineSpacing] = useState([]);
  const [isRatingEditable, setIsRatingEditable] = useState(false);

  const navigate = useNavigate();

  const toggleAccordion = (id) => {
    setOpenAccordion((prevState) =>
      prevState.includes(id)
        ? prevState.filter((itemId) => itemId !== id)
        : [...prevState, id]
    );
  };

  const fetchSettingData = async () => {
    try {
      const res = await axiosInstance.get(`/admin/setting/getSetting`);
      setSetting(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchData = async () => {
    if (questionId) {
      const response = await getAssignmentQuestionById(questionId);
      if (response.isSuccess) {
        setData(response.question);
        if (response.question.rating === "0") {
          setIsRatingEditable(true);
        } else {
          setIsRatingEditable(false);
        }
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, [questionId]);

  const referenceStyleId = data?.selectReferenceStyle;
  const lineSpacingId = data?.selectLineSpacing;

  const refrencefillter = refrenceStyle.filter(
    (item) => item._id === referenceStyleId
  );
  const lineSpacingfillter = lineSpacing.filter(
    (item) => item._id === lineSpacingId
  );

  const getReference = async () => {
    const response = await getReferenceStyle();
    setRefrenceStyle(response.data);
  };
  const getLineSpacing = async () => {
    const response = await getAllLineSpacing();
    setLineSpacing(response.data);
  };

  useEffect(() => {
    fetchSettingData();
    getReference();
    getLineSpacing();
  }, []);

  const handleRatingChange = (value) => {
    setRatingValue(value);
  };

  const submitRating = async () => {
    try {
      await axiosInstance.post("student/updateRating", {
        questionId: data?._id,
        paymentSection: data?.paymentSection,
        rating: ratingValue.toString(),
      });
      navigate("/dashboard/home");
    } catch (error) {
      console.error("Error submitting rating:", error);
    }
  };

  return (
    <>
      <Helmet>
        <title>
          Assignment Order | Get Personalized Academic Success with DoubtQ |
          Online Question Help
        </title>
        <meta
          name="description"
          content="Welcome to the Contact Us of DoubtQ"
        />
        <meta property="og:title" content="Contact Us - DoubtQ" />
        <meta
          property="og:description"
          content="Explore personalized academic help on DoubtQ's Contact Us"
        />
      </Helmet>
      <div className="wrapper">
        {data.displayId && (
          <div className="pt-5 content-wrapper">
            <h1 className="fw-bold">Order Number #{data?.displayId}</h1>
            <h1 className="pt-2 pb-5">
              Assignment Help ({data?.subCategoryId?.name})
            </h1>
            <Accordion
              open={openAccordion}
              toggle={toggleAccordion}
              className="accordian-Wrapper content-card"
            >
              <AccordionItem>
                <AccordionHeader targetId="1" className="heading">
                  Requirement
                </AccordionHeader>
                <AccordionBody accordionId="1">
                  <div className="d-flex">
                    <span className="pe-3 greyTitle w-25 text-start">
                      Subject
                    </span>
                    <span className="lineSeprator"></span>
                    <span className="ps-3 subTitle  text-end">
                      {data?.subjectId?.questionSubject}
                    </span>
                  </div>
                  <div className="d-flex">
                    <span className="pe-3 greyTitle w-25 text-start">
                      Words
                    </span>
                    <span className="lineSeprator"></span>
                    <span className="ps-3 subTitle fw-bold text-end">
                      {data?.numerOfWords}
                    </span>
                  </div>
                  {refrencefillter[0]?.refStyle && (
                    <div className="d-flex">
                      <span className="pe-3 greyTitle w-25 text-start">
                        Reference Style
                      </span>
                      <span className="lineSeprator"></span>
                      <span className="ps-3 subTitle text-end">
                        {refrencefillter[0]?.refStyle}
                      </span>
                    </div>
                  )}

                  {lineSpacingfillter[0]?.lineSpacing && (
                    <div className="d-flex">
                      <span className="pe-3 greyTitle w-25 text-start">
                        Spacing
                      </span>
                      <span className="lineSeprator"></span>
                      <span className="ps-3 subTitle text-end">
                        {lineSpacingfillter[0]?.lineSpacing}
                      </span>
                    </div>
                  )}

                  <div className="d-flex">
                    <span className="pe-3 greyTitle w-25 text-start">
                      Deadline
                    </span>
                    <span className="lineSeprator"></span>
                    <span className="ps-3 subTitle text-end">
                      {data?.deadlineHours} Hours
                    </span>
                  </div>
                </AccordionBody>
              </AccordionItem>
            </Accordion>
            <div className="bg-white py-4 rounded-4 text-start ps-3 mb-5 content-card">
              <div className="row">
                <div className="col-md-6">
                  <h1 className="whatsapp-text">Whatsapp Chat</h1>
                </div>
                <div className="col-md-6 com-whatsapp">
                  <Link
                    to={`https:///send?phone=${setting.contactNumber}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={whatsapp} alt="whatsapp" width={50} />
                  </Link>
                </div>
              </div>
            </div>

            <Accordion
              open={openAccordion}
              toggle={toggleAccordion}
              className="accordian-Wrapper content-card"
            >
              <AccordionItem>
                <AccordionHeader targetId="2" className="heading">
                  Tutor Engagement
                </AccordionHeader>
                <AccordionBody accordionId="2">
                  <div className="d-flex justify-content-center align-items-center">
                    <span className="pe-3 greyTitle w-25 text-start">
                      Tutor
                    </span>
                    <span className="lineSeprator"></span>
                    <span className="ps-3 subTitle w-50 text-end">
                      {data?.questionStatus === "Unsolved" ? (
                        <CustomButton
                          text="Not Working"
                          disabled
                          style={{ backgroundColor: "#FF0000" }}
                        />
                      ) : data?.questionStatus === "Solved" ? (
                        <CustomButton
                          text="Solved"
                          disabled
                          style={{ backgroundColor: "#25a53b" }}
                        />
                      ) : (
                        <CustomButton
                          text="Working"
                          disabled
                          style={{ backgroundColor: "#25a53b" }}
                        />
                      )}
                    </span>
                  </div>
                </AccordionBody>
              </AccordionItem>
            </Accordion>

            <Accordion
              open={openAccordion}
              toggle={toggleAccordion}
              className="accordian-Wrapper content-card"
            >
              <AccordionItem>
                <AccordionHeader targetId="3" className="heading">
                  Solution
                </AccordionHeader>
                <AccordionBody accordionId="3">
                  {data?.answerPDF && data.answerPDF.length > 0 ? (
                    <div className="d-flex">
                      <div className="col-sm-6">
                        <div className="d-flex align-items-center mt-4">
                          {ExportFile(data.answerPDF)}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <h3 className="text-start mx-2">
                      You will get your solution soon.
                    </h3>
                  )}
                </AccordionBody>
              </AccordionItem>
            </Accordion>
            {data?.answerPDF && data.answerPDF.length > 0 && (
              <div className="mt-5">
                <Accordion
                  open={openAccordion}
                  toggle={toggleAccordion}
                  className="accordian-Wrapper content-card"
                >
                  <AccordionItem>
                    <AccordionHeader targetId="4" className="heading">
                      Give us feedback
                    </AccordionHeader>
                    <AccordionBody accordionId="4">
                      <Row>
                        <Col md="6" className="text-start">
                          <span className="pe-3 greyTitle w-25">
                            <span className="pt-1">Rating&nbsp;</span>
                            <Rating
                              onClick={handleRatingChange}
                              initialValue={
                                data?.rating ? parseInt(data.rating) : 0
                              }
                              readonly={!isRatingEditable}
                            />
                          </span>
                        </Col>
                        <Col md="6" className="text-end">
                          <span className="ps-3 subTitle fw-bold ">
                            <Button
                              className="fs-3 button button-primary"
                              disabled={!isRatingEditable}
                              onClick={submitRating}
                            >
                              {isRatingEditable ? "Submit" : "Submitted"}
                            </Button>
                          </span>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <h1 className="text-start mt-2">Write a review</h1>
                          <div className="review-dashboard">
                            {/* <Link to="https://www.trustpilot.com/review/doubtq.com" target="_blank" className="text-decoration-none">
                              <TrustpilotRatings rating="4.4" />
                            </Link> */}
                            <TrustpilotRating />
                            <Link
                              to="https://www.sitejabber.com/reviews/doubtq.com"
                              target="_blank"
                              className="text-decoration-none"
                            >
                              <SitejabberRatings rating="4.2" />
                            </Link>
                          </div>
                        </Col>
                      </Row>
                    </AccordionBody>
                  </AccordionItem>
                </Accordion>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default Dashboardview;
