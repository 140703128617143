import axiosInstance from "../axiosInterceptor";

export async function redeemReward(childId, pendingAmount) {
    const response = await axiosInstance.post(`student/referralReward`, {
        childId: childId,
        amount: pendingAmount,
    });
    const data = response?.data;
    return data;
}
