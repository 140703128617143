import React, { useState } from "react";
import {
  Col,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Row,
} from "reactstrap";
import { Link } from "react-router-dom";
import Button from "../../Components/Buttons/Button";
import { sendOTP } from "../../services/sendOtp";
import { verifyOTP } from "../../services/verifyOtp";
import { emailRegister } from "../../services/emailRegister";

const SignUp = ({ countries, setPageToShow }) => {
  const [formData, setFormData] = useState({});
  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleSendOTP = async () => {
    const response = await sendOTP({ email: formData.email });
  };

  const handleVerfiyOtp = async () => {
    const response = await verifyOTP({
      email: formData.email,
      otpCode: formData.otp,
    });
    if (response.isSuccess) {
      setFormData({ ...formData, isOtpVerified: true });
    }
  };

  const handleStudentRegister = async () => {
    const response = await emailRegister({
      email: formData?.email,
      otpCode: Number(formData?.otp),
      countryId: formData?.country,
      referralCode: formData?.referralCode,
    });
    if (response.isSuccess) {
      setPageToShow("login");
    }
  };

  return (
    <Col xs={12} md={8}>
      <Row className="justify-content-center pt-5 text-start">
        <Col xs={8}>
          <Label>
            <b>
              <small className="fs-4 mx-2">Email</small>
            </b>
          </Label>
          <InputGroup className="rounded-end-5 overflow-hidden">
            <Input
              placeholder="Enter Email"
              className="fs-3 p-4 rounded-start-4"
              name="email"
              type="email"
              disabled={formData.isOtpVerified}
              onChange={handleChange}
            />
            <InputGroupText className="authentication-form-input-group p-0 overflow-hidden">
              <Button
                varient="linear-gradient-primary"
                text={"OTP"}
                onClick={handleSendOTP}
                disabled={!formData?.email?.length || formData.isOtpVerified}
                className="rounded-0 h-100 px-5 py-4"
              />
            </InputGroupText>
          </InputGroup>
          <Label className="pt-5">
            <b>
              <small className="fs-4 mx-2">OTP</small>
            </b>
          </Label>
          <InputGroup className="rounded-end-5 overflow-hidden">
            <Input
              placeholder="Enter OTP"
              className="fs-3 p-4 rounded-start-4"
              name="otp"
              type="number"
              disabled={formData.isOtpVerified}
              onChange={handleChange}
            />
            <InputGroupText className="authentication-form-input-group p-0 overflow-hidden">
              <Button
                varient="linear-gradient-primary"
                text={"Verify"}
                disabled={!formData.otp?.length || formData.isOtpVerified}
                onClick={handleVerfiyOtp}
                className="rounded-0 h-100 px-5 py-4"
              />
            </InputGroupText>
          </InputGroup>
        </Col>
      </Row>
      <Row className="justify-content-center py-5">
        <Col xs={8}>
          <Row>
            <Col xs={12} className="text-start">
              <Label>
                <b>
                  <small className="fs-4 mx-2 text-Country"> Country</small>
                </b>
              </Label>
              <InputGroup>
                <Input
                  type="select"
                  placeholder="Select Country"
                  name="country"
                  onChange={handleChange}
                >
                  <option disabled selected>
                    Select Country
                  </option>
                  {countries?.map((country) => {
                    return (
                      <option key={country.countryCode} value={country._id}>
                        {country.countryName}
                      </option>
                    );
                  })}
                </Input>
              </InputGroup>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="justify-content-center pb-5">
        <Col xs={8}>
          <Row>
            <Col xs={12}>
              <Button
                text={"Sign Up"}
                varient="linear-gradient-primary"
                className="px-5 py-4 h-100 w-100"
                onClick={handleStudentRegister}
                disabled={!formData.isOtpVerified || !formData.country}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col xs={8}>
          <Row className="justify-content-center">
            <span className="fs-2 text-center">
              Already have an account? {""}
              <Link
                className="text-decoration-none"
                style={{ color: "#5956E9" }}
                onClick={() => setPageToShow("login")}
              >
                Login
              </Link>
            </span>
          </Row>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col xs={8}>
          <Row className="justify-content-center pt-5">
            <span className="fs-3 text-center">
              By Clicking Sign up, You agree our{" "}
              <Link to="/terms-and-conditions" style={{ color: "black" }}>
                Terms & Conditions
              </Link>
              ,{" "}
              <Link to="/refund-policy" style={{ color: "black" }}>
                Refund Policy
              </Link>{" "}
              &{" "}
              <Link to="/privacy-policy" style={{ color: "black" }}>
                Privacy Policy
              </Link>
            </span>
          </Row>
        </Col>
      </Row>
    </Col>
  );
};

export default SignUp;
