import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./ChatHelpExperts.scss";
import ProfileIMG from "../Assets/images/background/ChatHelpExpertProfile.svg";

const ChatHelpExperts = () => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 767, min: 464 },
      items: 1,
    },
  };
  const arr = [
    {
      image: ProfileIMG,
      name: "Prakul Vyas",
      qualification: "M-Com (Business Studies)",
      departments: [
        "English",
        "Business organisation ",
        "Essay writing",
        "Presentation writing",
      ],
      ratings: 4.97,
      order: 246,
    },
    {
      image: ProfileIMG,
      name: "Vivek Sharma",
      qualification: "B-Tech (Chemical Engineering)",
      departments: [
        "Chemistry",
        "Chemical engineering",
        "Lab Report Writing",
        "Problem solving",
      ],
      ratings: 4.9,
      order: 141,
    },
    {
      image: ProfileIMG,
      name: "Anirudh kharwe",
      qualification: "B-Tech (Mechanical Engineering)",
      departments: [
        "Physics",
        "Mechanical engineering",
        "Lab Report Writing",
        "Problem solving",
      ],
      ratings: 4.6,
      order: 112,
    },
    {
      image: ProfileIMG,
      name: "Rahul Jain",
      qualification: "Diploma in Nursing",
      departments: [
        "Biomedical",
        "Nursing",
        "Problem solving",
        "Research and Dissertation writing",
      ],
      ratings: 4.8,
      order: 167,
    },
  ];
  return (
    <div className="chat-help-experts-container">
      <div className="chat-help-experts-section">
        <div className="chat-help-header">
          <h2 className="title">Meet Our Online 1-on-1 chat Help Experts!</h2>
        </div>
        <div className="carousel-container">
          <Carousel
            responsive={responsive}
            autoPlay={true}
            arrows={false}
            swipeable={true}
            draggable={true}
            showDots={true}
            infinite={true}
          >
            {arr.map((e, index) => {
              return (
                <div key={index} className="carousel">
                  <div className="carousel-header">
                    <img src={e.image} className="carousel-img" />
                    <div className="carousel-expert-name">{e.name}</div>
                    <div className="carousel-expert-qualification">
                      {e.qualification}
                    </div>
                  </div>
                  <hr />
                  <div className="carousel-departments">
                    {e.departments.map((department, index) => {
                      return <div key={index}>{department}</div>;
                    })}
                  </div>
                  <div className="carousel-rating-section">
                    <span>
                      <span className="rating-section">
                        <span className="rating">Ratings(4.96)</span>
                        <span className="rating-star">
                          {Array.from(
                            { length: 5 },
                            (_, index) => index < e.ratings
                          ).map((isFull, index) => (
                            <div
                              key={index}
                              className={`star ${isFull ? "" : "empty"}`}
                            ></div>
                          ))}
                        </span>
                      </span>
                      <span className="order-section">
                        <span className="order-text">Order</span>
                        <span className="total-orders">{e.order}</span>
                      </span>
                    </span>
                  </div>
                </div>
              );
            })}
          </Carousel>
        </div>
      </div>
    </div>
  );
};

export default ChatHelpExperts;
