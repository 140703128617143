import React from "react";
import { useSearchParams } from "react-router-dom";

import oops from "../../Assets/images/oops.png";

import "./OrderHistory.scss";
import Button from "../Buttons/Button";

const OopsError = () => {
  return (
    <>
      <div className="wrapper">
        <div>
          <img src={oops} alt="" style={{ height: "250px" }} />
        </div>
        <div className="pt-5 content-wrapper">
          <h1 className="fw-bold">OOPS Error</h1>
          <div className="bg-white py-5 rounded-4 text-start ps-5 mb-5 text-center border-2 border">
            <Button text="Try Again" varient="linear-gradient-primary" />
          </div>
        </div>
      </div>
    </>
  );
};

export default OopsError;
