import React, { useState, useEffect } from "react";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Button,
  Row,
  Col,
} from "reactstrap";

import "../../Components/OrderStatusAndBilling/OrderHistory.scss";
import { Link, useParams } from "react-router-dom";
import whatsapp from "../../Assets/images/whatsapp.png";
import zoom from "../../Assets/images/zoom.png";
import axiosInstance from "../../axiosInterceptor";
import { Rating } from "react-simple-star-rating";
import { getLiveSessionQuestionById } from "../../services/getLiveSessionQuestionById";
import { Helmet } from "react-helmet";

const Dashboardviewlive = () => {
  const [openAccordion, setOpenAccordion] = useState(["1", "2", "3", "4"]);
  const [ratingValue, setRatingValue] = useState(0);
  const [data, setData] = useState({});
  const { questionId } = useParams();
  const [isRatingSubmitted, setIsRatingSubmitted] = useState(false);
  const [setting, setSetting] = useState([]);
  const [sessionFlag, setSessionFlag] = useState(true);
  const [durationFlag, setDurationFlag] = useState(false);
  const [countdown, setCountdown] = useState({
    hours: 0,
    minutes: 0,
    seconds: 0
  });
  const [countdownDur, setCountdownDur] = useState({
    hours: 0,
    minutes: 0,
    seconds: 0
  });
  const toggleAccordion = (id) => {
    setOpenAccordion((prevState) =>
      prevState.includes(id)
        ? prevState.filter((itemId) => itemId !== id)
        : [...prevState, id]
    );
  };

  const convertMinutesToHoursMinutesSeconds = (totalMinutes) => {
    const hours = Math.floor(totalMinutes / 60);
    const remainingMinutes = totalMinutes % 60;
    const seconds = remainingMinutes * 60;
    return {
      hours,
      minutes: remainingMinutes,
      seconds
    };
  };

  const fetchData = async () => {
    if (questionId) {
      const response = await getLiveSessionQuestionById(questionId);
      if (response.isSuccess) {
        setData(response.question);
        const minTime = convertMinutesToHoursMinutesSeconds(response.question.durationMintues);
        setCountdownDur(minTime)
        if (response.question && response.question.date && response.question.time) {
          const dateTime1 = getCurrentDateTime();
          const dateTime2 = formatDateAndTime(response.question.date, response.question.time);
          if (dateTime1 && dateTime2) {
            const remainingTime = calculateDateTimeDifference(
              dateTime1, dateTime2
            );
            setCountdown(remainingTime)
            const intervalId = setInterval(() => {
              const updatedCountdown = calculateDateTimeDifference(
                getCurrentDateTime(),
                dateTime2
              );
              setCountdown(updatedCountdown);
              if (updatedCountdown.hours === 0 && updatedCountdown.minutes === 0 && updatedCountdown.seconds === 0) {
                setSessionFlag(false);
                setDurationFlag(true);
                clearInterval(intervalId);
              }
            }, 1000);
          }
        }
        if (response.question.rating) {
          setIsRatingSubmitted(true);
        }
        setRatingValue(
          response.question.rating ? parseInt(response.question.rating) : 0
        );
      }
    }
  };

  const dateTime3 = data.deadlineDate
  useEffect(() => {
    if (durationFlag) {
      const intervalId1 = setInterval(() => {
        const updatedCountdownDur = calculateDateTimeDifference(
          getCurrentDateTime(),
          dateTime3
        );
        setCountdownDur(updatedCountdownDur);
        if (updatedCountdownDur.hours === 0 && updatedCountdownDur.minutes === 0 && updatedCountdownDur.seconds === 0) {
          setSessionFlag(false);
          setDurationFlag(false);
          clearInterval(intervalId1);
        }
      }, 1000);
    }
  }, [durationFlag]);

  useEffect(() => {
    fetchData();
  }, [questionId]);

  const fetchSettingData = async () => {
    try {
      const res = await axiosInstance.get(`/admin/setting/getSetting`);
      setSetting(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchSettingData();
  }, []);

  const handleRatingChange = (value) => {
    setRatingValue(value);
  };

  const submitRating = async () => {
    try {
      const res = await axiosInstance.post("student/updateRating", {
        questionId: data?._id,
        paymentSection: data?.paymentSection,
        rating: ratingValue.toString(),
      });
      setIsRatingSubmitted(true);
      fetchData();
    } catch (error) {
      console.error("Error submitting rating:", error);
    }
  };

  const formatDate = (dateString) => {
    const options = { day: "numeric", month: "long", year: "numeric" };
    return new Date(dateString).toLocaleDateString("en-US", options);
  };

  const getCurrentDateTime = () => {
    const now = new Date();

    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');

    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');

    const formattedDateTime = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;

    return formattedDateTime;
  };

  const formatDateAndTime = (date, time) => {
    const [year, month, day] = date?.split("-");
    const formattedMonth = month.length === 1 ? `0${month}` : month;
    const formattedDay = day.length === 1 ? `0${day}` : day;
    const formattedDate = `${year}-${formattedMonth}-${formattedDay}`;
    const formattedTime = `${time}:00`;
    const dateTime = `${formattedDate}T${formattedTime}`;
    return dateTime;
  };

  const calculateDateTimeDifference = (dateTime1, dateTime2) => {
    const date1 = new Date(dateTime1);
    const date2 = new Date(dateTime2);
    const difference = Math.abs(date2 - date1);
    const hours = Math.floor(difference / (1000 * 60 * 60));
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((difference % (1000 * 60)) / 1000);
    return {
      hours,
      minutes,
      seconds
    };
  };

  return (
    <>
      <Helmet>
        <title>Live Session Order | Get Personalized Academic Success with DoubtQ | Online Question Help</title>
        <meta name="description" content="Welcome to the Contact Us of DoubtQ" />
        <meta property="og:title" content="Contact Us - DoubtQ" />
        <meta property="og:description" content="Explore personalized academic help on DoubtQ's Contact Us" />
      </Helmet>
      <div className="wrapper">
        {data.displayId && (
          <div className="pt-5 content-wrapper">
            <h1 className="fw-bold">Order Number #{data?.displayId}</h1>
            <h1 className="pt-2 pb-5">
              Live Session Help ({data?.subCategoryId?.name})
            </h1>
            <Accordion
              open={openAccordion}
              toggle={toggleAccordion}
              className="accordian-Wrapper content-card"
            >
              <AccordionItem>
                <AccordionHeader targetId="1" className="heading">
                  Requirement
                </AccordionHeader>
                <AccordionBody accordionId="1">
                  <div className="d-flex">
                    <span className="pe-3 greyTitle w-25 text-start">
                      Subject
                    </span>
                    <span className="lineSeprator"></span>
                    <span className="ps-3 subTitle  text-end">
                      {data?.subjectId?.questionSubject}
                    </span>
                  </div>

                  <div className="d-flex">
                    <span className="pe-3 greyTitle w-25 text-start">
                      Start Date & Time
                    </span>
                    <span className="lineSeprator"></span>
                    <span className="ps-3 subTitle text-end">
                      {formatDate(data?.date)} | {data?.time}
                    </span>
                  </div>
                  <div className="d-flex">
                    <span className="pe-3 greyTitle w-25 text-start">
                      Duration
                    </span>
                    <span className="lineSeprator"></span>
                    <span className="ps-3 subTitle text-end">
                      {data?.durationMintues} Min
                    </span>
                  </div>
                </AccordionBody>
              </AccordionItem>
            </Accordion>
            <div className="bg-white py-4 rounded-4 text-start ps-3 mb-5 content-card">
              <div className="row">
                <div className="col-md-6">
                  <h1>Whatsapp Chat</h1>
                </div>
                <div className="col-md-6 com-whatsapp">
                  <Link
                    to={`https://api.whatsapp.com/send?phone=${setting.contactNumber}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={whatsapp} alt="whatsapp" width={50} />
                  </Link>
                </div>
              </div>
            </div>
            <div className="session">
              {sessionFlag && (
                <Row>
                  <Col md="12" className="p-2">
                    <div className="d-md-flex mx-4">
                      <h3 className="mt-4">Session Starts in :</h3>
                      <div className="d-flex justify-content-around">
                        <div className="border-time">
                          <p className="pt-2">{countdown.hours}</p>
                          <p className="timer-live">HRS</p>
                        </div>
                        <div className="border-time">
                          <p className="pt-2">{countdown.minutes}</p>
                          <p className="timer-live">MIN</p>
                        </div>
                        <div className="border-time">
                          <p className="pt-2">{countdown.seconds}</p>
                          <p className="timer-live">SEC</p>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              )}

              <div className="content-card-live">
                <div className="mt-4">
                  <div
                    className="card-hed w-100 d-xl-flex justify-content-between rounded-top-5 px-4 py-3"
                    style={{ backgroundColor: "#D5D9F9" }}
                  >
                    <div className="text-start">
                      <h4>Order Id #{data?.displayId} </h4>
                    </div>
                  </div>
                </div>
                <div className="mt-4 mb-4 p-4 text-start">
                  {!sessionFlag && !durationFlag && (
                    <Row className="text-center">
                      <Col md="12" className="p-2">
                        <Button className="live-session">SESSION OVER</Button>
                      </Col>
                    </Row>
                  )}
                  {countdownDur && (
                    <div className="border-buttom-live">
                      <Row>
                        <Col md="4">
                          <div>
                            <h3 className="mt-4">Duration Timer</h3>
                          </div>
                        </Col>
                        <Col md="8">
                          <div className="d-flex">
                            <div className="border-time">
                              <p className="pt-2 text-center">{countdownDur.hours}</p>
                              <p className="timer-live">HRS</p>
                            </div>
                            <div className="border-time">
                              <p className="pt-2 text-center">{countdownDur.minutes}</p>
                              <p className="timer-live">MIN</p>
                            </div>
                            <div className="border-time">
                              <p className="pt-2 text-center">{countdownDur.seconds}</p>
                              <p className="timer-live">SEC</p>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  )}
                  {data?.whatsappNo && (
                    <div className="border-buttom-live">
                      <Row>
                        <Col md="4">
                          <div>
                            <h3 className="mt-4">Whatsapp No :</h3>
                          </div>
                        </Col>
                        <Col md="8">
                          <div className="d-flex">
                            <img
                              className="zoom-whatsapp"
                              src={whatsapp}
                              alt="Whatsapp "
                              width={35}
                              height={35}
                            />
                            <h3 className="mt-4 mx-2">+91 {data?.whatsappNo}</h3>
                          </div>
                        </Col>
                        <h4 className="mt-4 mx-2">
                          Please send your Doubts & Question on above whatsapp
                          Number or by clicking on whatsapp icon
                        </h4>
                      </Row>
                    </div>
                  )}
                  {data?.zoomLink && (
                    <div>
                      <Row>
                        <Col md="4">
                          <div>
                            <h3 className="mt-4">Meet Link:</h3>
                          </div>
                        </Col>
                        <Col md="8">
                          <div className="d-flex">
                            <img
                              className="zoom-whatsapp"
                              src={zoom}
                              alt="Whatsapp"
                              width={35}
                              height={35}
                            />
                            <Link to={data?.zoomLink} target="_blank" rel="noopener noreferrer" className="text-decoration-none">
                              <h3 className="mt-4 mx-2 zoommyclass">
                                {data?.zoomLink}
                              </h3>
                            </Link>

                          </div>
                        </Col>
                        <h4 className="mt-4">
                          Please click on above link to join the discussion room{" "}
                        </h4>
                      </Row>
                    </div>
                  )}

                </div>
              </div>
            </div>

            <div className="mt-5">
              <Accordion
                open={openAccordion}
                toggle={toggleAccordion}
                className="accordian-Wrapper content-card"
              >
                <AccordionItem>
                  <AccordionHeader targetId="4" className="heading">
                    Give us a feedback
                  </AccordionHeader>
                  <AccordionBody accordionId="4">
                    <Row className="disabled-element">
                      <Col md="6" className="text-start">
                        <span className="pe-3 greyTitle w-25">
                          <span className="pt-1">Rating&nbsp;</span>
                          <Rating
                            onClick={handleRatingChange}
                            initialValue={ratingValue}
                            readonly={isRatingSubmitted}
                          />
                        </span>
                      </Col>
                      <Col md="6" className="text-end mt-2">
                        <span className="ps-3 subTitle fw-bold ">
                          {isRatingSubmitted ? (
                            <Button
                              className="fs-3 button button-primary"
                              disabled
                            >
                              Submitted
                            </Button>
                          ) : (
                            <Button
                              className="fs-3 button button-primary"
                              onClick={submitRating}
                            >
                              Submit
                            </Button>
                          )}
                        </span>
                      </Col>
                    </Row>
                  </AccordionBody>
                </AccordionItem>
              </Accordion>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Dashboardviewlive;
