import React from "react";
import LogoHeader from "./LogoHeader";
import "./CheckOut.scss";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import { useLocation } from "react-router-dom";
const CheckOut = (props) => {
  const { stripePromise } = props;
  const { state } = useLocation();
  const { clientSecret = "" } = state ?? {};

  const appearance = {
    theme: "stripe",
  };
  return (
    <div className="checkOutWrapper">
      <LogoHeader />
      {clientSecret && stripePromise && (
        <Elements stripe={stripePromise} options={{ clientSecret, appearance }}>
          <CheckoutForm />
        </Elements>
      )}
    </div>
  );
};

export default CheckOut;
