import axiosInstance from "../axiosInterceptor";

export async function orderHistoryForAssignmentHelp(search, pageNo, perPage, studentPaymentStatus) {
    const response = await axiosInstance.post(
        `student/orderHistory`,
        {
            search,
            pageNo,
            perPage,
            categoryType: "AssignmentHelp",
            studentPaymentStatus
        }
    );
    const data = response?.data;
    return data;
}

export async function orderHistoryForLiveSessionHelp(search, pageNo, perPage, studentPaymentStatus) {
    const response = await axiosInstance.post(
        `student/orderHistory`,
        {
            search,
            pageNo,
            perPage,
            categoryType: "LiveSessionHelp",
            studentPaymentStatus
        }
    );
    const data = response?.data;
    return data;
}

