import React, { useEffect } from "react";
import "./Button.scss";
import { useState } from "react";
const Button = ({
  text,
  className = "",
  varient = "",
  disabled = false,
  onClick,
  ...rest
}) => {
  const [isLoaderVisible, setIsLoaderVisible] = useState(false);
  const startLoader = () => {
    setIsLoaderVisible(true);
    setTimeout(() => {
      setIsLoaderVisible(false);
    }, 500);
  };

  useEffect(() => {
    return () => {
      setIsLoaderVisible(false);
    };
  }, []);

  return (
    <button
      className={`button button-${varient} ${className}  ${disabled || isLoaderVisible ? `button-${varient}-disabled` : ""
        }`}
      onClick={() => {
        typeof onClick === "function" && onClick();
        startLoader();
      }}
      {...rest}
      disabled={disabled}
    >
      <span>
        {text}
        {varient !== "search" && isLoaderVisible ? (
          <span className="loader mx-2"></span>
        ) : (
          <></>
        )}
      </span>
    </button>
  );
};

export default Button;
