import React, { useState, useEffect } from "react";
import { Button, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import axiosInstance from "../../axiosInterceptor";
import { useNavigate } from "react-router-dom";
import { getAllCountries } from "../../services/getAllCountries";
import { Helmet } from "react-helmet";

const PersonalSetting = () => {
  const [countries, setCountries] = useState([]);
  const [isSaving, setSaving] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    countryId: "",
    mobileNo: "",
    countryCode: "",
    universityName: "",
    name: "",
    state: "",
    city: "",
    postalCode: "",
    address: "",
  });
  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        const response = await axiosInstance.get("student/getProfile");
        const profileData = response.data.profile;
        setFormData({
          email: profileData.email,
          countryId: profileData.countryId,
          name: profileData.name,
          mobileNo: profileData.mobileNo,
          countryCode: profileData.countryCode,
          universityName: profileData.universityName,
          state: profileData.state,
          city: profileData.city,
          postalCode: profileData.postalCode,
          address: profileData.address,
        });
      } catch (error) {
        console.error("Error fetching profile data:", error);
      }
    };
    fetchProfileData();
    const fetchCountries = async () => {
      try {
        const res = await getAllCountries();
        setCountries(res.data);
      } catch (error) {
        console.error("Error fetching countries:", error);
      }
    };
    fetchCountries();
  }, []);

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setSaving(true);
      const response = await axiosInstance.put(
        "student/updateProfile",
        formData
      );
      navigate("/dashboard/home");
    } catch (error) {
      console.error("Error updating profile:", error);
    } finally {
      setSaving(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>Personal Information | Get Personalized Academic Success with DoubtQ | Online Question Help</title>
        <meta name="description" content="Welcome to the Personal Information of DoubtQ" />
        <meta property="og:title" content="Personal Information - DoubtQ" />
        <meta property="og:description" content="Explore personalized academic help on DoubtQ's Personal Information" />
      </Helmet>
      <div className="dashboard-persional-setting">
        <Row>
          <Col md="6">
            <h2 className="fw-bold text-start mt-4 p-4">Personal Info</h2>
          </Col>
        </Row>
        <Form className="p-4" onSubmit={handleSubmit}>
          <Row>
            <Col md="6" className="text-start">
              <FormGroup>
                <Label for="exampleEmail">Email Id</Label>
                <Input
                  className="p-4 font-size"
                  id="exampleEmail"
                  name="email"
                  placeholder="Please enter your email"
                  type="email"
                  value={formData.email}
                  onChange={handleChange}
                />
              </FormGroup>
            </Col>
            <Col md="6" className="text-start">
              <FormGroup>
                <Label for="exampleName">Name</Label>
                <Input
                  className="p-4 font-size"
                  id="exampleName"
                  name="name"
                  placeholder="Enter your name"
                  type="text"
                  value={formData.name}
                  onChange={handleChange}
                />
              </FormGroup>
            </Col>
          </Row>
          {/* Additional fields */}
          <Row>
            <Col md="6" className="text-start">
              <FormGroup>
                <Row>
                  <Col md="4">
                    <Label for="exampleState">Country Code</Label>
                    <Input
                      className="p-4 font-size"
                      id="countryCode"
                      name="countryCode"
                      placeholder="+91"
                      value={formData?.countryCode}
                      onChange={handleChange}
                    />
                  </Col>
                  <Col md="8">
                    <Label for="exampleState">Phone Number</Label>
                    <Input
                      className="p-4 font-size"
                      id="mobileNo"
                      name="mobileNo"
                      placeholder="Enter your phone number"
                      type="number"
                      value={formData?.mobileNo}
                      onChange={handleChange}
                    />
                  </Col>
                </Row>
              </FormGroup>
            </Col>
            <Col md="6" className="text-start">
              <FormGroup>
                <Label for="exampleName">University/College</Label>
                <Input
                  className="p-4 font-size"
                  id="universityName"
                  name="universityName"
                  placeholder="Enter your university/college"
                  type="text"
                  value={formData?.universityName}
                  onChange={handleChange}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="6" className="text-start">
              <FormGroup>
                <Label for="exampleEmail">Country</Label>
                <Input
                  className="p-4"
                  type="select"
                  placeholder="Select Country"
                  name="countryId"
                  value={formData.countryId}
                  onChange={handleChange}
                >
                  <option disabled selected value="">
                    Select Country
                  </option>
                  {countries?.map((country) => {
                    return (
                      <option key={country.countryCode} value={country._id}>
                        {country.countryName}
                      </option>
                    );
                  })}
                </Input>
              </FormGroup>
            </Col>
            <Col md="6" className="text-start">
              <FormGroup>
                <Label for="exampleCity">City</Label>
                <Input
                  className="p-4 font-size"
                  id="exampleCity"
                  name="city"
                  placeholder="Enter your city"
                  type="text"
                  value={formData.city}
                  onChange={handleChange}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="6" className="text-start">
              <FormGroup>
                <Label for="exampleState">State</Label>
                <Input
                  className="p-4 font-size"
                  id="exampleState"
                  name="state"
                  placeholder="Enter your state"
                  type="text"
                  value={formData.state}
                  onChange={handleChange}
                />
              </FormGroup>
            </Col>
            <Col md="6" className="text-start">
              <FormGroup>
                <Label for="examplePostalCode">Postal Code</Label>
                <Input
                  className="p-4 font-size"
                  id="examplePostalCode"
                  name="postalCode"
                  placeholder="Enter your postal code"
                  type="text"
                  value={formData.postalCode}
                  onChange={handleChange}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="12" className="text-start">
              <FormGroup>
                <Label for="exampleAddress">Address</Label>
                <Input
                  className="p-4 font-size"
                  id="exampleAddress pay"
                  name="address"
                  placeholder="Enter your address"
                  type="textarea"
                  value={formData.address}
                  onChange={handleChange}
                />
              </FormGroup>
            </Col>
          </Row>
          <div className="Personal-Settings-button p-6" align="right">
            <Button className="border-edit-btn" size="lg" type="submit">
              {isSaving ? "Saving..." : "Save"}
            </Button>
          </div>
        </Form>
      </div>
    </>
  );
};

export default PersonalSetting;
