import React from 'react';
import reward1 from "../Assets/images/New/reward1.png"
import reward2 from "../Assets/images/New/reward2.png"
import reward3 from "../Assets/images/New/reward3.png"
import reward4 from "../Assets/images/New/reward4.png"
import reward5 from "../Assets/images/New/reward5.png"
import reward6 from "../Assets/images/New/reward6.png"
import reward7 from "../Assets/images/New/reward7.png"
import "./Milestonerewards.scss";

const Milestonerewards = ({ categoryId }) => {

    let titleText;

    switch (categoryId) {
        case '65524e633c005ef3b51907c9':
            titleText = 'Assignment Help';
            break;
        case '65524e353c005ef3b51907c2':
            titleText = 'Live Session Help';
            break;
        case '65524e5e3c005ef3b51907c6':
            titleText = 'Homework Help';
            break;
    }

    return (
        <div>
            <section className="first-section-ref">
                <div className="container">
                    <div>
                        <h1 className="heading-content text-center mb-5">
                            Milestone Rewards - {titleText}
                        </h1>
                    </div>
                    <div className="row">
                        <div className="col-12 col-lg-4 col-md-6 mb-4">
                            <div className="card-box-re border-0 rounded-0">
                                <div className="card-area">
                                    <h5 className="card-title-rw">3 Assignment</h5>
                                    <hr className="w-50 mx-auto" />
                                    <div className="d-flex flex-column align-items-end">
                                        <img
                                            src={reward1}
                                            className="assignment-image-row-1"
                                            alt="Reward 1"
                                        />
                                    </div>
                                    <div className='box-dis'>
                                        <span className="text-1">30% On</span>
                                        <br />
                                        <span className="text-2">Next Order</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-4 col-md-6 mb-4">
                            <div className="card-box-re border-0 rounded-0">
                                <div className="card-area">
                                    <h5 className="card-title-rw">5 Assignment</h5>
                                    <hr className="w-50 mx-auto" />
                                    <div className="d-flex flex-column align-items-end">
                                        <img
                                            src={reward2}
                                            className="assignment-image-row-1"
                                            alt="Reward 1"
                                        />
                                    </div>
                                    <div className='box-dis'>
                                        <span className="text-1">2 Movie</span>
                                        <br />
                                        <span className="text-2">Tickets</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-4 col-md-6 mb-4">
                            <div className="card-box-re border-0 rounded-0">
                                <div className="card-area">
                                    <h5 className="card-title-rw">10 Assignment</h5>
                                    <hr className="w-50 mx-auto" />
                                    <div className="d-flex flex-column align-items-end">
                                        <img
                                            src={reward3}
                                            className="assignment-image-row-1"
                                            alt="Reward 1"
                                        />
                                    </div>
                                    <div className='box-dis'>
                                        <span className="text-1">Next 1</span>
                                        <br />
                                        <span className="text-2">Assignment Free</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-lg-6 col-md-6 mb-4">
                            <div className="card-box-re border-0 rounded-0">
                                <div className="card-area">
                                    <h5 className="card-title-rw">20 Assignment</h5>
                                    <hr className="w-50 mx-auto" />
                                    <div className="d-flex flex-column align-items-end">
                                        <img
                                            src={reward4}
                                            className="assignment-image-row-2-3"
                                            alt="Reward 1"
                                        />
                                    </div>
                                    <div className='box-dis'>
                                        <span className="text-1">40% On</span>
                                        <br />
                                        <span className="text-2">Next 3 Order</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6 col-md-6 mb-4">
                            <div className="card-box-re border-0 rounded-0">
                                <div className="card-area">
                                    <h5 className="card-title-rw">40 Assignment</h5>
                                    <hr className="w-50 mx-auto" />
                                    <div className="d-flex flex-column align-items-end">
                                        <img
                                            src={reward5}
                                            className="assignment-image-row-2-3"
                                            alt="Reward 1"
                                        />
                                    </div>
                                    <div className='box-dis'>
                                        <span className="text-1">Extra Cash</span>
                                        <br />
                                        <span className="text-2">Bonus of $100</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-lg-6 col-md-6 mb-4">
                            <div className="card-box-re border-0 rounded-0">
                                <div className="card-area">
                                    <h5 className="card-title-rw">50 Assignment</h5>
                                    <hr className="w-50 mx-auto" />
                                    <div className="d-flex flex-column align-items-end">
                                        <img
                                            src={reward6}
                                            className="assignment-image-row-2-3"
                                            alt="Reward 1"
                                        />
                                    </div>
                                    <div className='box-dis'>
                                        <span className="text-1">Extra Cash</span>
                                        <br />
                                        <span className="text-2">Bonus of $100</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6 col-md-6 mb-4">
                            <div className="card-box-re border-0 rounded-0">
                                <div className="card-area">
                                    <h5 className="card-title-rw">100 Assignment</h5>
                                    <hr className="w-50 mx-auto" />
                                    <div className="d-flex flex-column align-items-end">
                                        <img
                                            src={reward7}
                                            className="assignment-image-row-2-3"
                                            alt="Reward 1"
                                        />
                                    </div>
                                    <div className='box-dis'>
                                        <span className="text-1">5 Tickets Free To</span>
                                        <br />
                                        <span className="text-2">Nearby Adventure Park</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container mt-4">
                        <ul className="line-space">
                            <h3 className='title'>Terms &amp; Conditions</h3>
                            <li>
                                Minimum Amount of order of each assignment will be valued at - $40
                            </li>
                            <li>For only Assignments asked and when payment received then only</li>
                            <li>For Assignments which fulfill the criteria of DoubtQ</li>
                            <li>
                                This Redeemable criteria can be updated or change by DoubtQ anytime
                                without any consent
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Milestonerewards