import axiosInstance from "../axiosInterceptor";

export async function getPaymentSessionByQuestion(body) {
  try {
    const type = localStorage.getItem("serviceType");
    const payload = {
      paymentMethod: body.paymentMethod,
      paymentSection: type,
      questionId: body.questionId,
    };
    const response = await axiosInstance.post(
      `student/payment/getSessionDataByStudent`,
      payload
    );
    const data = response?.data;
    return data;
  } catch (err) {
    console.log(err);
  }
}
