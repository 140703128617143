import React, { useEffect, useState } from "react";
import "./WorldWide.scss";
import WorldWideMap from "../Assets/images/background/WorldWideMap.svg";
import { getSubcategoryWiseContent } from "../services/getSubcategoryWiseContent";

const ComWorldWide = ({ id = "" }) => {
  const [pageData, setPageData] = useState([]);

  const fetchData = async () => {
    try {
      const data = await getSubcategoryWiseContent(id);
      setPageData(data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  const shortDescription = pageData?.subCategoryData?.ShortDescription || "";

  return (
    shortDescription?.length > 0 && (
      <div className="world-wide-container-bg">
        <div className="world-wide-container">
          <div className="world-wide-img">
            <img src={WorldWideMap} alt="" />
          </div>
          <p
            dangerouslySetInnerHTML={{
              __html: shortDescription,
            }}
          />
        </div>
      </div>
    )
  );
};

export default ComWorldWide;
