import React, { useEffect, useState } from "react";
import "./ReadMoreSection.scss";
import { getSubcategoryWiseContent } from "../services/getSubcategoryWiseContent";

const ComReadMoreSection = ({ id = "" }) => {
  const [pageData, setPageData] = useState({});
  const [isExpanded, setIsExpanded] = useState(false);
  const [textToShow, setTextToShow] = useState("");

  const fetchData = async () => {
    try {
      const data = await getSubcategoryWiseContent(id);
      setPageData(data);
      setTextToShow(truncateText(data?.subCategoryData?.LongDescription, 100));
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  const truncateText = (text, wordLimit) => {
    if (!text) return "";
    const words = text.split(" ");
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(" ") + "...";
    }
    return text;
  };

  const handleReadMoreToggle = () => {
    setIsExpanded(!isExpanded);
    if (isExpanded) {
      setTextToShow(
        truncateText(pageData?.subCategoryData?.LongDescription, 100)
      );
    } else {
      setTextToShow(pageData?.subCategoryData?.LongDescription || "");
    }
  };

  const isTextLong = (text) => {
    return text?.split(" ").length > 100;
  };

  return (
    pageData?.subCategoryData?.LongDescription?.length > 0 && (
      <div className="read-more-section-container">
        <div className="read-more-section-description">
          <p dangerouslySetInnerHTML={{ __html: textToShow }} />
          {isTextLong(pageData?.subCategoryData?.LongDescription) && (
            <button
              onClick={handleReadMoreToggle}
              className="rbt-btn btn-gradient btn-sm mt-4"
            >
              {isExpanded ? "Read Less" : "Read More"}
            </button>
          )}
        </div>
      </div>
    )
  );
};

export default ComReadMoreSection;
