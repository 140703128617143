import React, { useEffect, useState } from "react";
import "./HelpSection.scss";
import Button from "../Components/Buttons/Button";
import CategoryCard from "./Card/CategoryCard";
import { getCategoryWiseSubcategory } from "../services/getCategoryWiseSubcategory";

const HelpSection = ({ setIsModelOpen, setTypeData }) => {
  const [pageData, setPageData] = useState([]);

  const fetchData = async () => {
    await getCategoryWiseSubcategory("65524e5e3c005ef3b51907c6")
      .then((data) => {
        setPageData(data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="background-shadow helpsection">
      <div className="container">
        <div className="title">Homework Help</div>
        <div className="description">
          Variety of problems call for variety of Question solutions.
        </div>
        <div className="card-wrapper">
          {pageData?.subCategory?.map((item, index) => {
            return <CategoryCard key={index} details={item} />;
          })}
        </div>
        <Button
          text="Place Your  Homework Help"
          varient="primary"
          onClick={() => {
            setTypeData({
              serviceType: "HomeworkHelp",
              type: "mcqHelp",
              totalSteps: 3,
            });
            setIsModelOpen(true);
          }}
        ></Button>
      </div>
    </div>
  );
};

export default HelpSection;
