import React from "react";
import { Outlet } from "react-router-dom";

import DashboardHeader from "./DashboardHeader";
import DashboardSidebarLeft from "./DashboardSidebarLeft";
import DashboardSidebarRight from "./DashboardSidebarRight";

import "./dashboard.scss";

const Dashboard = () => {
  return (
    <>
      <DashboardHeader />
      <div style={{ display: "flex" }}>
        <div
          className="sidebar-left"
          style={{ position: "sticky", top: 80, height: "100vh" }}
        >
          <DashboardSidebarLeft />
        </div>
        <div
          className="w-100 max-vh-100 min-vh-100"
          style={{
            backgroundColor: "#F7F7F7",
            padding: "23px",
          }}
        >
          <Outlet />
        </div>
        <div
          className="sidebar-right"
          style={{ position: "sticky", top: 76, height: "100vh" }}
        >
          <DashboardSidebarRight />
        </div>
      </div>
    </>
  );
};

export default Dashboard;
