import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import whatsapp from '../Assets/images/whatsapp.png'
import axiosInstance from '../axiosInterceptor';

const Nav = () => {

    const [setting, setSetting] = useState([]);
    const contactNumber = setting?.contactNumber;

    const formatPhoneNumber = (phoneNumber) => {
        if (phoneNumber) {
            const phoneStr = phoneNumber.toString();
            return `+91 ${phoneStr.slice(0, 5)} ${phoneStr.slice(5)}`;
        }
        return "";
    };

    const fetchSettingData = async () => {
        try {
            const res = await axiosInstance.get(`/admin/setting/getSetting`);
            setSetting(res.data.data);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchSettingData();
    }, []);

    return (
        <div>
            <div className="bg-color-darker pt--10 pb--10 pt-3 pb-3 header-space-betwween">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12 col-12">
                            <div className="color-white d-flex align-items-center justify-content-center nav-new">
                                <div className="d-none d-sm-block d-sm-none d-md-block mr--10">
                                    <Link className="color-white text-decoration-none"
                                        to={`https://wa.me/91${contactNumber}`}
                                        target='_blank'
                                    >
                                        <img src={whatsapp} alt='whatsapp' height={25} width={25} className='mb-2' />
                                    </Link>
                                    <span className='mx-2 mt-2'>WhatsApp for any query :</span>
                                </div>{" "}
                                <Link className="color-white text-decoration-none"
                                    to={`https://wa.me/91${contactNumber}`}
                                    target='_blank'
                                >
                                    <i className="fab fa-whatsapp" />  {formatPhoneNumber(contactNumber)}
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Nav