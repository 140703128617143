import React, { useState } from "react";
import {
    Accordion,
    AccordionBody,
    AccordionHeader,
    AccordionItem,
    Button,
    Row,
    Col,
} from "reactstrap";

import rating from '../../Assets/icons/rating.svg'

const Feedback = ({ questionData }) => {
    const [open, setOpen] = useState("1");
    const toggle = (id) => {
        if (open === id) {
            setOpen();
        } else {
            setOpen(id);
        }
    };

    return (
        <Accordion
            open={open}
            toggle={toggle}
            className="accordian-Wrapper content-card"
        >
            <AccordionItem>
                <AccordionHeader targetId="1" className="heading">
                    Give us a feedback
                </AccordionHeader>
                <AccordionBody accordionId="1">
                    <div>
                        <Row>
                            <Col md="6" className="text-start">
                                <span className="pe-3 greyTitle w-25 ">
                                    <span className="pt-1">Rating&nbsp;</span>
                                    <img src={rating} alt="" />
                                </span>
                            </Col>
                            <Col md="6" className="text-end mt-2">
                                <span className="ps-3 subTitle fw-bold ">
                                    <Button className="button button-primary">Submit</Button>
                                </span>
                            </Col>
                        </Row>
                    </div>
                </AccordionBody>
            </AccordionItem>
        </Accordion>
    );
};

export default Feedback;
