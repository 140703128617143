import React, { useEffect, useState } from "react";
import "./Authentication.scss";
import { getAllCountries } from "../../services/getAllCountries";
import Login from "./Login";
import Register from "./Register";

const Authentication = () => {
  const [pageToShow, setPageToShow] = useState("signin");
  const [countries, setCountries] = useState([]);
  useEffect(() => {
    (async function () {
      const res = await getAllCountries();
      setCountries(res.data);
    })();
  }, []);

  return (
    <>
      {pageToShow == "signup" ? (
        <Register setPageToShow={setPageToShow} />
      ) : (
        <Login setPageToShow={setPageToShow} />
      )}
    </>

  );
};

export default Authentication;
