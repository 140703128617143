import React, { useContext, useState } from "react";
import MainBanner from "../../Components/MainBanner";
import AskSection from "../../Components/AskSection";
import HelpSection from "../../Components/HelpSection";
import AssignmentSection from "../../Components/AssignmentSection";
import LiveHelpSection from "../../Components/LiveHelpSection";
import BonusOffersSection from "../../Components/BonusOffersSection";
import HelpSteps from "../../Components/HelpSteps";
import BenefitsSection from "../../Components/BenefitsSection";
import BonusSection from "../../Components/BonusSection";
import WorldWide from "../../Components/WorldWide";
import ReadMoreSection from "../../Components/ReadMoreSection";
import Footer from "../../Components/Footer";
import { CategoryContext } from "../../categoryContext";
import CommonForm from "./Form/CommonForm";
import ScrollTop from "../../Components/Buttons/ScrollTop";
import Navbar from "../../Components/Navbar";
import Nav from "../../Components/Nav";
import GetPopup from "../Staticpages/GetPopup";
import ReferralProgram from "../../Components/ReferralProgram";
import Universities from "../../Components/Universities";
import Quessol from "../../Components/Quessol";
import { Helmet } from 'react-helmet';

const HomePage = () => {
  const { categoryId } = useContext(CategoryContext);
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [typeData, setTypeData] = useState("");
  const toggle = () => {
    setIsModelOpen(!isModelOpen);
  };

  return (
    <>
      <Helmet>
        <title>Home | Get Personalized Academic Success with DoubtQ | Online Question Help</title>
        <meta name="description" content="Welcome to the Home Page of DoubtQ" />
        <meta property="og:title" content="Home Page - DoubtQ" />
        <meta property="og:description" content="Explore personalized academic help on DoubtQ's Home Page" />
      </Helmet>
      <Nav />
      <Navbar />
      <MainBanner id={categoryId} setIsModelOpen={setIsModelOpen} />
      <AskSection />
      <HelpSection setIsModelOpen={setIsModelOpen} setTypeData={setTypeData} />
      <AssignmentSection
        setIsModelOpen={setIsModelOpen}
        setTypeData={setTypeData}
      />
      <LiveHelpSection
        setIsModelOpen={setIsModelOpen}
        setTypeData={setTypeData}
      />
      <BonusOffersSection />
      <ReferralProgram />
      <HelpSteps subCategoryId={categoryId} />
      <BonusSection subCategoryId={categoryId} />
      <Quessol />
      <BenefitsSection subCategoryId={categoryId} />
      <Universities />
      <WorldWide />
      <ReadMoreSection />
      <Footer />
      {isModelOpen ? (
        <CommonForm isOpen={isModelOpen} toggle={toggle} typeData={typeData} />
      ) : (
        <></>
      )}
      <ScrollTop />
      <GetPopup />
    </>
  );
};

export default HomePage;
