import axiosInstance from "../axiosInterceptor";

export async function getLibraryTranscriptText(text) {
  try {
    const response = await axiosInstance.get(
      `/student/getLibraryQuestionDetailByTranscript?question=${text}`
    );
    const data = response?.data;
    return data;
  } catch (error) {
    console.log(error);
  }
}
