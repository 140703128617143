import axiosInstance from "../axiosInterceptor";

export async function getHoursByWords(body) {
  const response = await axiosInstance.post(
    `/admin/assignmentPrice/getHoursByWords`,
    body
  );
  const data = response?.data;
  return data;
}
